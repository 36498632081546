import React from 'react';
import { SolarCertificationContainer, SolarCertificationImage } from './styles';
import { Container } from 'react-bootstrap';

const SolarCertification = () => {
   return (
      <>
         <SolarCertificationContainer>
            <Container>
               <SolarCertificationImage>
                  <img src="images/img/solar/certification.png" alt="" />
               </SolarCertificationImage>
            </Container>
         </SolarCertificationContainer>
      </>
   );
};

export default SolarCertification;
