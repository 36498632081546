import { styled } from 'styled-components';

export const HeaderTop = styled.div`
   background-color: ${(props) => props.theme.primary};
   padding: 11px 0px;

   @media only screen and (max-width: 568px) {
      display: none;
   }
`;

export const HeaderContainer = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
`;

export const HeaderInfoIcon = styled.div`
   img {
      width: 17px;
      height: 17px;
   }
`;

export const HeaderInfo = styled.div`
   display: flex;
   gap: 1.5rem;
   align-items: center;
`;

export const HeaderInfoContent = styled.div`
   font-weight: 300;
   color: ${(props) => props.theme.white};
   font-size: 15px;
   gap: 0.5rem;
   display: flex;
   align-items: center;

   a {
      color: ${(props) => props.theme.white};

      &:hover {
         color: ${(props) => props.theme.secondary};
      }
   }
`;

export const HeaderLinks = styled.div`
   display: flex;
   align-items: center;
   gap: 1rem;

   @media only screen and (max-width: 568px) {
      margin-bottom: 30px;
   }
`;

export const HeaderLinkItem = styled.div`
   a {
      height: 19px;
      width: 19px;
      display: block;
      svg {
         margin-top: -8px;
         height: 15.4px;
         width: 19px;
         fill: #777;
      }

      i {
         color: rgba(255, 255, 255, 0.8);
         font-size: 19px;
      }
   }
`;
