import React from 'react';

const WindIcon = () => {
      return (
            <div>
                  <svg
                        version="1.1"
                        id="fi_959711"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 368 368"
                        style={{ enableBackground: 'new 0 0 368 368;' }}
                        xmlSpace="preserve"
                  >
                        <g>
                              <g>
                                    <path
                                          d="M296,48c-39.704,0-72,32.304-72,72c0,4.416,3.576,8,8,8s8-3.584,8-8c0-30.88,25.128-56,56-56s56,25.12,56,56
			s-25.128,56-56,56H8c-4.416,0-8,3.584-8,8s3.584,8,8,8h288c39.704,0,72-32.304,72-72S335.704,48,296,48z"
                                    ></path>
                              </g>
                        </g>
                        <g>
                              <g>
                                    <path
                                          d="M144,32c-30.88,0-56,25.12-56,56c0,4.416,3.584,8,8,8s8-3.584,8-8c0-22.056,17.944-40,40-40c22.056,0,40,17.944,40,40
			c0,22.056-17.944,40-40,40H8c-4.416,0-8,3.584-8,8s3.584,8,8,8h136c30.88,0,56-25.12,56-56S174.88,32,144,32z"
                                    ></path>
                              </g>
                        </g>
                        <g>
                              <g>
                                    <path
                                          d="M280,224H8c-4.416,0-8,3.584-8,8c0,4.416,3.584,8,8,8h272c22.056,0,40,17.944,40,40c0,22.056-17.944,40-40,40
			c-22.056,0-40-17.944-40-40c0-4.416-3.576-8-8-8s-8,3.584-8,8c0,30.88,25.128,56,56,56s56-25.12,56-56S310.872,224,280,224z"
                                    ></path>
                              </g>
                        </g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                  </svg>
            </div>
      );
};

export default WindIcon;
