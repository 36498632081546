import React from 'react';
import {
      PoolCoverAccessoriesImage,
      PoolCoverAdvantage,
      PoolCoverAdvantageIcon,
      PoolCoverContainer,
      PoolCoverContent,
      PoolCoverHeading,
      PoolCoverSection,
      PoolCoverTitle,
      PoolSpaHeader,
      PoolSpaHeading,
      PoolTopImage
} from './styles';
import { Col, Container, Row } from 'react-bootstrap';
import SunIcon from '../../icons/poolcover/SunIcon';
import ThermometerIcon from '../../icons/poolcover/ThermometerIcon';
import EvaporationIcon from '../../icons/poolcover/EvaporationIcon';
import BirdIcon from '../../icons/poolcover/BirdIcon';
import PoolSpaAccordion from './PoolSpaAccodion';

const PoolSpaCover = () => {
      return (
            <div>
                  <PoolSpaHeader className="w-100 mb-5">
                        <img
                              src="/images/img/poolcover/HEADER.jpeg"
                              alt=""
                              className="img-fluid w-100"
                        />
                        <PoolSpaHeading>
                              <h1>Pool & Spa Cover</h1>
                        </PoolSpaHeading>
                  </PoolSpaHeader>
                  {/* <PoolTopImage className="mb-4">
                        <img
                              src="/images/img/poolcover/thermotech-covers.jpg"
                              alt=""
                        />
                  </PoolTopImage> */}
                  <PoolCoverContainer>
                        <Container>
                              <Row>
                                    <Col lg={12}>
                                          <PoolCoverHeading className="mb-3">
                                                <h2>INNMOTEK POOL COVER</h2>
                                          </PoolCoverHeading>
                                          <PoolCoverContent>
                                                <p>
                                                      A swimming pool cover,
                                                      also known as solar
                                                      blanket bubble wrap
                                                      floating on the water's
                                                      surface. These sheets are
                                                      designed to trap heat from
                                                      the sun, transmit the heat
                                                      to the pool, and keep the
                                                      temperature of water in
                                                      the pool warm. Innmotek
                                                      Pool Cover helps to extend
                                                      the life of swimming pool
                                                      It also traps heat and
                                                      prevents it from escaping
                                                      into the cool night air.
                                                      They also prevent leaves
                                                      and other debris from
                                                      falling into the water,
                                                      thereby assisting in pool
                                                      maintenance.
                                                </p>

                                                <p className="mb-5">
                                                      Our purpose is to provide
                                                      world-class products to
                                                      our customers supported by
                                                      best-in-class in quality
                                                      and services. We back our
                                                      product with research and
                                                      development to deliver
                                                      globally leading, high
                                                      performing products. that
                                                      last longer - at
                                                      competitive prices. Our
                                                      efficient production,
                                                      dispatch, delivery and
                                                      sales representation
                                                      continue to be our
                                                      defining attributes.
                                                </p>
                                          </PoolCoverContent>
                                          <PoolCoverTitle className="mb-4">
                                                <h3>
                                                      FEATURES &nbsp;
                                                      <span>
                                                            INNMOTEK POOL COVER
                                                      </span>
                                                </h3>
                                          </PoolCoverTitle>
                                          <PoolCoverContent className="mb-5">
                                                <img
                                                      src="/images/img/poolcover/poolfeatures.png"
                                                      alt=""
                                                      className="img-fluid"
                                                />
                                          </PoolCoverContent>
                                          <PoolCoverTitle className="mb-4">
                                                <h3>
                                                      ADVANTAGES OF&nbsp;
                                                      <span>
                                                            INNMOTEK POOL COVER
                                                      </span>
                                                </h3>
                                          </PoolCoverTitle>
                                          <PoolCoverContent className="mb-5">
                                                <Row>
                                                      <Col lg={3}>
                                                            <PoolCoverAdvantage className="d-flex gap-2 align-items-center justify-content-center">
                                                                  <PoolCoverAdvantageIcon>
                                                                        <SunIcon />
                                                                  </PoolCoverAdvantageIcon>

                                                                  <h5>
                                                                        Heat
                                                                        your
                                                                        water
                                                                        with the
                                                                        sun
                                                                  </h5>
                                                            </PoolCoverAdvantage>
                                                      </Col>
                                                      <Col lg={3}>
                                                            <PoolCoverAdvantage className="d-flex gap-2 align-items-center justify-content-center align-items-center justify-content-center">
                                                                  <PoolCoverAdvantageIcon>
                                                                        <ThermometerIcon />
                                                                  </PoolCoverAdvantageIcon>

                                                                  <h5>
                                                                        Insulate
                                                                        and save
                                                                        heat
                                                                        loss
                                                                  </h5>
                                                            </PoolCoverAdvantage>
                                                      </Col>
                                                      <Col lg={3}>
                                                            <PoolCoverAdvantage className="d-flex gap-2 align-items-center justify-content-center">
                                                                  <PoolCoverAdvantageIcon>
                                                                        <EvaporationIcon />
                                                                  </PoolCoverAdvantageIcon>

                                                                  <h5>
                                                                        Stop
                                                                        Evaporation
                                                                  </h5>
                                                            </PoolCoverAdvantage>
                                                      </Col>
                                                      <Col lg={3}>
                                                            <PoolCoverAdvantage className="d-flex gap-2 align-items-center justify-content-center">
                                                                  <PoolCoverAdvantageIcon>
                                                                        <BirdIcon />
                                                                  </PoolCoverAdvantageIcon>

                                                                  <h5>
                                                                        No Bird
                                                                        Dropping
                                                                  </h5>
                                                            </PoolCoverAdvantage>
                                                      </Col>
                                                </Row>
                                          </PoolCoverContent>
                                          <PoolCoverTitle className="mb-4">
                                                <h3>
                                                      WHY PREFER INNMOTEK POOL
                                                      COVERS OVER&nbsp;
                                                      <span>
                                                            NON - COVERED
                                                            SWIMMING POOL?
                                                      </span>
                                                </h3>
                                          </PoolCoverTitle>
                                          <PoolCoverContent>
                                                <img
                                                      src="/images/img/poolcover/pooladvantage.png"
                                                      alt=""
                                                      className="img-fluid"
                                                />
                                          </PoolCoverContent>
                                    </Col>
                              </Row>
                              <Row className="my-4 g-5">
                                    <Col lg={6}>
                                          <img
                                                src="/images/img/poolcover/beforeafter.png"
                                                alt=""
                                                className="img-fluid"
                                          />
                                    </Col>
                                    <Col lg={6}>
                                          <PoolCoverTitle className="mb-3">
                                                <h3>
                                                      BEFORE AND AFTER OF &nbsp;
                                                      <span>POOL COVER</span>
                                                </h3>
                                          </PoolCoverTitle>
                                          <PoolCoverContent>
                                                <ul className="">
                                                      <li>
                                                            Protection from uv
                                                            radiations
                                                      </li>
                                                      <li>
                                                            Protect from leaves
                                                            and debris
                                                      </li>
                                                      <li>
                                                            Protect from Bird
                                                            Dropping
                                                      </li>
                                                      <li>
                                                            Saves energy time
                                                            and money on
                                                            cleaning
                                                      </li>
                                                      <li>
                                                            Keeps the pool
                                                            warmer in winter and
                                                            cold in summer
                                                      </li>
                                                      <li>
                                                            Ultimate protection
                                                            for all weather
                                                            conditions
                                                      </li>
                                                </ul>
                                          </PoolCoverContent>
                                          <PoolCoverTitle className="mb-3 mt-5">
                                                <h3>
                                                      DISADVANTAGES OF POOL
                                                      &nbsp;
                                                      <span>WITHOUT COVER</span>
                                                </h3>
                                          </PoolCoverTitle>
                                          <PoolCoverContent>
                                                <ul className="">
                                                      <li>
                                                            More frequent
                                                            reduction of the
                                                            water level
                                                      </li>
                                                      <li>High Cost</li>
                                                      <li>More Chemical Use</li>
                                                      <li>More Maintenance</li>
                                                      <li>
                                                            More dust and debris
                                                            in the Pool.
                                                      </li>
                                                      <li>
                                                            Bird Dropping
                                                            Entering into Pool
                                                      </li>
                                                </ul>
                                          </PoolCoverContent>
                                    </Col>
                              </Row>
                              <PoolCoverTitle className="mb-4 mt-5">
                                    <h3>
                                          POOL COVER &nbsp;
                                          <span>ACCESSORIES</span>
                                    </h3>
                              </PoolCoverTitle>
                              <Row className="g-5 mb-5">
                                    <Col lg={3}>
                                          <PoolCoverAccessoriesImage>
                                                <img
                                                      src="/images/img/poolcover/1.jpeg"
                                                      alt=""
                                                      className="img-fluid"
                                                />
                                          </PoolCoverAccessoriesImage>
                                    </Col>
                                    <Col lg={3}>
                                          <PoolCoverAccessoriesImage>
                                                <img
                                                      src="/images/img/poolcover/2.jpeg"
                                                      alt=""
                                                      className="img-fluid"
                                                />
                                          </PoolCoverAccessoriesImage>
                                    </Col>
                                    <Col lg={3}>
                                          <PoolCoverAccessoriesImage>
                                                <img
                                                      src="/images/img/poolcover/3.jpeg"
                                                      alt=""
                                                      className="img-fluid"
                                                />
                                          </PoolCoverAccessoriesImage>
                                    </Col>
                                    <Col lg={3}>
                                          <PoolCoverAccessoriesImage>
                                                <img
                                                      src="/images/img/poolcover/4.jpeg"
                                                      alt=""
                                                      className="img-fluid"
                                                />
                                          </PoolCoverAccessoriesImage>
                                    </Col>
                              </Row>
                        </Container>
                        <PoolCoverSection className="py-3">
                              <Container>
                                    <PoolCoverTitle className="mb-4 mt-5">
                                          <h3>
                                                <span>
                                                      {' '}
                                                      Maintenance and Care
                                                      &nbsp;
                                                </span>
                                          </h3>
                                    </PoolCoverTitle>
                                    <PoolCoverContent>
                                          <ul className="ps-0">
                                                <li>
                                                      <p>
                                                            <span>
                                                                  1. Protect the
                                                                  Cover While
                                                                  Swimming –
                                                            </span>
                                                            The Innmotek Pool
                                                            cover MUST be
                                                            protected from the
                                                            sunlight when it is
                                                            removed from the
                                                            pool. The best way
                                                            to protect your
                                                            Innmotek Pool Cover
                                                            is to place it in
                                                            the shade or cover
                                                            it with a blanket
                                                            when the Innmotek
                                                            Pool Cover is not in
                                                            use. DELAMINATION
                                                            DUE TO IMPROPER
                                                            EXPOSURE OF THE
                                                            INNMOTEK POOL COVER
                                                            TO DIRECT SUNLIGHT
                                                            (WHEN OFF THE POOL)
                                                            WILL RESULT IN
                                                            VOIDING THE ENCLOSED
                                                            WARRANTY!
                                                      </p>
                                                </li>
                                                <li>
                                                      <p>
                                                            <span>
                                                                  2. Care in
                                                                  Handling –
                                                            </span>
                                                            To prevent damage to
                                                            the Innmotek Pool
                                                            Cover, it should be
                                                            carefully lifted
                                                            over abrasive pool
                                                            decks, coping or any
                                                            sharp ragged
                                                            surface.
                                                      </p>
                                                </li>
                                                <li>
                                                      <p>
                                                            <span>
                                                                  3. Cleaning –
                                                            </span>{' '}
                                                            To clean Innmotek
                                                            Pool Cover is very
                                                            Easy Must be Cleaned
                                                            Gantly from Tap
                                                            Water
                                                      </p>
                                                </li>
                                                <li>
                                                      <p>
                                                            <span>
                                                                  4. Storage –
                                                            </span>
                                                            Clean your Innmotek
                                                            Pool Cover and allow
                                                            it to dry thoroughly
                                                            to prevent mildew.
                                                            Roll it on the
                                                            Rollor Dom one end.
                                                            Place the Cover in a
                                                            clean, dry,
                                                            rodent-free area.
                                                      </p>
                                                </li>
                                                <li>
                                                      <p>
                                                            <span>
                                                                  {' '}
                                                                  5. Chemicals –
                                                            </span>
                                                            Your Innmotek Pool
                                                            Cover should be
                                                            removed periodically
                                                            in order to ensure
                                                            that there is no
                                                            damage caused by
                                                            chemical usage. The
                                                            level of chorine
                                                            should be monitored
                                                            on Regular basis If
                                                            you must raise t he
                                                            chlorine level then
                                                            remove. Your Pool
                                                            Cover from the pool
                                                            to extend the useful
                                                            life of the Cover.
                                                            Before you Super
                                                            Chlorinate your
                                                            pool, the cover must
                                                            be removed and kept
                                                            off for an extended
                                                            period of time.
                                                      </p>
                                                </li>
                                          </ul>
                                    </PoolCoverContent>
                              </Container>
                        </PoolCoverSection>
                        <Container>
                              <Col lg={12}>
                                    {' '}
                                    <PoolSpaAccordion title="Pool Cover FAQ"></PoolSpaAccordion>
                              </Col>
                        </Container>
                  </PoolCoverContainer>
            </div>
      );
};

export default PoolSpaCover;
