import React, { useEffect, useState } from 'react';
import {
   TestimonialContent,
   TestimonialHeading,
   TestimonialImage,
   TestimonialSection,
   TestimonialSlider,
   TestimonialThumb,
   TestimonialTitle
} from '../../../styles/frontend/home';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import { Col, Container, Row } from 'react-bootstrap';

import { SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import axios from 'axios';
import toast from 'react-hot-toast';

const TestimonialBox = () => {
   const [thumbsSwiper, setThumbsSwiper] = useState(null);

   const [loading, setLoading] = useState(false);
   const [testimonials, setTestimonials] = useState([]);

   const loadData = async () => {
      setLoading(true);
      await axios
         .get(`${process.env.REACT_APP_SECRET_KEY}/api/testimonials`)
         .then((response) => {
            if (response.data.result === 'success') {
               setTestimonials(response.data.testimonials);
            }
         })
         .catch((error) => {
            toast.error(error.message);
         });
      setLoading(false);
   };
   useEffect(() => {
      loadData();
   }, []);

      return (
            <>
                  <TestimonialSection>
                        <Container>
                              <Row>
                                    <Col lg={5}>
                                          <TestimonialHeading>
                                                <h2>
                                                      Our Testimonial{' '}
                                                      <span class="ms-2"></span>
                                                </h2>
                                                <h3>
                                                      Hear What Our Client Says.
                                                </h3>
                                          </TestimonialHeading>
                                          {!loading
                                                ? testimonials?.length > 0 && (
                                                        <TestimonialSlider
                                                              style={{
                                                                    '--swiper-navigation-color':
                                                                          '#fff',
                                                                    '--swiper-pagination-color':
                                                                          '#fff'
                                                              }}
                                                              spaceBetween={10}
                                                              navigation={true}
                                                              thumbs={{
                                                                    swiper: thumbsSwiper
                                                              }}
                                                              modules={[
                                                                    FreeMode,
                                                                    Navigation,
                                                                    Thumbs
                                                              ]}
                                                        >
                                                              {testimonials?.map(
                                                                    (
                                                                          testimonial,
                                                                          index
                                                                    ) => (
                                                                          <SwiperSlide
                                                                                key={
                                                                                      index
                                                                                }
                                                                          >
                                                                                <TestimonialContent>
                                                                                      <TestimonialTitle>
                                                                                            <p>
                                                                                                  {
                                                                                                        testimonial.message
                                                                                                  }
                                                                                            </p>
                                                                                      </TestimonialTitle>
                                                                                      <h4>
                                                                                            {
                                                                                                  testimonial.name
                                                                                            }
                                                                                      </h4>
                                                                                      <span>
                                                                                            {`${testimonial.position} of ${testimonial.company}`}
                                                                                      </span>
                                                                                </TestimonialContent>
                                                                          </SwiperSlide>
                                                                    )
                                                              )}
                                                        </TestimonialSlider>
                                                  )
                                                : null}
                                    </Col>
                                    <Col lg={7}>
                                          {!loading
                                                ? testimonials?.length > 0 && (
                                                        <TestimonialThumb
                                                              onSwiper={
                                                                    setThumbsSwiper
                                                              }
                                                              spaceBetween={40}
                                                              slidesPerView={2}
                                                              freeMode={true}
                                                              watchSlidesProgress={
                                                                    true
                                                              }
                                                              modules={[
                                                                    FreeMode,
                                                                    Navigation,
                                                                    Thumbs
                                                              ]}
                                                              breakpoints={{
                                                                    640: {
                                                                          slidesPerView: 2,
                                                                          spaceBetween: 20
                                                                    },
                                                                    768: {
                                                                          slidesPerView: 3,
                                                                          spaceBetween: 20
                                                                    },
                                                                    1200: {
                                                                          slidesPerView: 3,
                                                                          spaceBetween: 40
                                                                    }
                                                              }}
                                                        >
                                                              {testimonials?.map(
                                                                    (
                                                                          testimonial,
                                                                          index
                                                                    ) => (
                                                                          <SwiperSlide
                                                                                key={
                                                                                      index
                                                                                }
                                                                          >
                                                                                <TestimonialImage>
                                                                                      <img
                                                                                            src={
                                                                                                  testimonial.image
                                                                                            }
                                                                                            alt={
                                                                                                  testimonial.name
                                                                                            }
                                                                                            width="100%"
                                                                                            height="100%"
                                                                                      />
                                                                                </TestimonialImage>
                                                                          </SwiperSlide>
                                                                    )
                                                              )}
                                                        </TestimonialThumb>
                                                  )
                                                : null}
                                    </Col>
                              </Row>
                        </Container>
                  </TestimonialSection>
            </>
      );
};

export default TestimonialBox;
