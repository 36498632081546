import React from 'react';

const EnergyIcon = () => {
      return (
            <>
                  <svg
                        height="487pt"
                        viewBox="-29 0 487 487.71802"
                        width="487pt"
                        xmlns="http://www.w3.org/2000/svg"
                        id="fi_1611261"
                  >
                        <path
                              d="m308.949219 398.578125 70.199219-33.15625c15.476562-7.816406 34.363281-2.085937 42.890624 13.007813l-142.691406 92.300781c-19.128906 8.828125-40.597656 11.257812-61.21875 6.929687l-98.410156-27.621094c-9.058594-2.539062-18.746094-1.480468-27.039062 2.960938l-20.582032 11.011719 2.769532-100.371094.820312.019531c29.410156-20.894531 68.03125-23.597656 100.0625-7l5.890625 3.050782c9.207031 4.769531 19.378906 7.398437 29.75 7.679687l57.847656 1.597656c18.15625.496094 34.046875 12.335938 39.710938 29.589844zm0 0"
                              fill="#f7caa5"
                        ></path>
                        <path
                              d="m328.210938 222.5c0 59.652344-48.359376 108.011719-108.011719 108.011719-59.652344 0-108.011719-48.359375-108.011719-108.011719s48.359375-108.011719 108.011719-108.011719c59.652343 0 108.011719 48.359375 108.011719 108.011719zm0 0"
                              fill="#fedb41"
                        ></path>
                        <path
                              d="m72.097656 464.011719-.308594 11.296875-63.429687-1.738282 3.828125-139.210937 63.441406 1.742187-.761718 27.539063zm0 0"
                              fill="#00efd1"
                        ></path>
                        <g fill="#083863">
                              <path d="m220.863281 266.175781c-.894531-.195312-1.816406-.230469-2.726562-.101562-9.1875-1.003907-16.132813-8.785157-16.097657-18.027344 0-4.417969-3.582031-8-8-8-4.417968 0-8 3.582031-8 8-.054687 15.421875 10.273438 28.945313 25.160157 32.960937v7.992188c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-7.515625c17.132812-3.585937 28.78125-19.546875 26.976562-36.957031-1.800781-17.410156-16.472656-30.644532-33.976562-30.648438-10.03125 0-18.160157-8.128906-18.160157-18.160156 0-10.027344 8.128907-18.160156 18.160157-18.160156s18.160156 8.132812 18.160156 18.160156c0 4.417969 3.582031 8 8 8s8-3.582031 8-8c-.023437-16.160156-11.347656-30.101562-27.160156-33.433594v-7.285156c0-4.417969-3.582031-8-8-8s-8 3.582031-8 8v7.773438c-16.507813 4.503906-27.132813 20.53125-24.859375 37.488281 2.277344 16.960937 16.75 29.621093 33.859375 29.617187 9.953125-.078125 18.117187 7.871094 18.296875 17.820313.183594 9.953125-7.679688 18.195312-17.632813 18.480469zm0 0"></path>
                              <path d="m104.1875 222.5c0 64.070312 51.941406 116.011719 116.011719 116.011719 64.070312 0 116.011719-51.941407 116.011719-116.011719s-51.941407-116.011719-116.011719-116.011719c-64.039063.074219-115.9375 51.972657-116.011719 116.011719zm116.011719-100.011719c55.234375 0 100.011719 44.777344 100.011719 100.011719s-44.777344 100.011719-100.011719 100.011719-100.011719-44.777344-100.011719-100.011719c.0625-55.207031 44.804688-99.949219 100.011719-100.011719zm0 0"></path>
                              <path d="m375.644531 358.226562-62.652343 29.59375c-8.679688-16.074218-25.273438-26.296874-43.53125-26.828124l-57.855469-1.597657c-9.160157-.265625-18.144531-2.582031-26.289063-6.789062l-5.886718-3.046875c-30.140626-15.714844-66.0625-15.675782-96.171876.097656l.367188-13.339844c.125-4.414062-3.359375-8.09375-7.777344-8.214844l-63.441406-1.742187c-2.121094-.058594-4.179688.726563-5.71875 2.183594-1.542969 1.460937-2.441406 3.472656-2.496094 5.59375l-3.828125 139.210937c-.121093 4.417969 3.359375 8.09375 7.777344 8.214844l63.429687 1.742188h.222657c4.332031-.003907 7.875-3.453126 7.992187-7.78125l.183594-6.652344 16.488281-8.824219c6.464844-3.476563 14.03125-4.304687 21.097657-2.308594l98.414062 27.617188c.167969.050781.339844.089843.515625.128906 7.117187 1.488281 14.371094 2.238281 21.640625 2.234375 15.386719.007812 30.59375-3.308594 44.574219-9.730469.34375-.160156.675781-.34375.992187-.546875l142.691406-92.300781c3.554688-2.296875 4.703126-6.964844 2.621094-10.652344-10.59375-18.796875-34.085937-25.953125-53.359375-16.261719zm-311.445312 105.5625-.140625 3.300782-47.457032-1.300782 3.375-123.214843 47.488282 1.300781zm211.292969-.089843c-17.378907 7.84375-36.785157 10.007812-55.464844 6.183593l-98.148438-27.546874c-11.042968-3.117188-22.867187-1.824219-32.972656 3.609374l-8.429688 4.511719 2.261719-81.929687c26.683594-17.75 60.914063-19.574219 89.332031-4.761719l5.890626 3.046875c10.285156 5.3125 21.636718 8.246094 33.207031 8.574219l57.851562 1.601562c14.777344.421875 27.707031 10.058594 32.332031 24.097657.414063 1.265624.757813 2.550781 1.027344 3.855468l-86.179687-2.378906c-4.417969-.121094-8.09375 3.359375-8.21875 7.777344-.121094 4.417968 3.363281 8.09375 7.777343 8.21875l95.089844 2.617187h.222656c4.332032.003907 7.878907-3.445312 8-7.78125.09375-3.476562-.167968-6.953125-.777343-10.378906l64.277343-30.367187c.0625-.027344.125-.058594.1875-.089844 9.117188-4.613282 20.140626-3.070313 27.640626 3.871094zm0 0"></path>
                              <path d="m228.199219 84v-76c0-4.417969-3.582031-8-8-8s-8 3.582031-8 8v76c0 4.417969 3.582031 8 8 8s8-3.582031 8-8zm0 0"></path>
                              <path d="m288.199219 84v-36c0-4.417969-3.582031-8-8-8s-8 3.582031-8 8v36c0 4.417969 3.582031 8 8 8s8-3.582031 8-8zm0 0"></path>
                              <path d="m168.199219 84v-36c0-4.417969-3.582031-8-8-8s-8 3.582031-8 8v36c0 4.417969 3.582031 8 8 8s8-3.582031 8-8zm0 0"></path>
                        </g>
                  </svg>
            </>
      );
};

export default EnergyIcon;
