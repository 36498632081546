import styled from 'styled-components';

export const InformationContainer = styled.div`
      background-image: url(images/img/about/bg.jpg);
      background: 171b2a;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 50px 0px;
      background-position: center center;
      background-attachment: fixed;
`;
export const InformationContent = styled.div``;
export const InformationButton = styled.div`
      padding: 12px 30px;
      background: linear-gradient(to left, #0e95b3 1%, #00145e);
      position: relative;
      display: inline-block;
      a {
            text-decoration: none;
            color: ${(props) => props.theme.white};
            position: relative;
            z-index: 100;
      }
      &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            bottom: 0;
            width: 0px;
            background-color: ${(props) => props.theme.primary};
            transition-delay: 0.1s;
            transition-timing-function: ease-in-out;
            transition-duration: 0.5s;
            transition-property: all;
            opacity: 1;
            ${'' /* z-index: -1; */}
      }
      &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 0px;
            content: '';
            background-color: ${(props) => props.theme.primary};
            transition-delay: 0.1s;
            transition-timing-function: ease-in-out;
            transition-duration: 0.5s;
            transition-property: all;
            ${'' /* z-index: -1; */}
      }
      &:hover {
            cursor: pointer;
            &:before {
                  width: 100%;
            }
            &:after {
                  width: 100%;
                  height: 100%;
                  bottom: 0px;
                  right: 0px;
            }
            a {
                  color: ${(props) => props.theme.white};
                  transition-timing-function: ease-in-out;
                  transition-duration: 0.1s;
                  transition-property: all;
            }
      }
`;
