import React from 'react';
import { Container } from 'react-bootstrap';
import {
      HeaderContainer,
      HeaderInfo,
      HeaderInfoContent,
      HeaderInfoIcon,
      HeaderTop
} from '../../../styles/layouts/header';
import Navigation from '../navigation';
import SocialMedia from '../../../components/common/SocialMedia';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const Header = () => {
      const [isSticky, setIsSticky] = useState(false);

      useEffect(() => {
            const handleScroll = () => {
                  const scrollTop = window.pageYOffset;
                  setIsSticky(scrollTop > 0);
            };
            window.addEventListener('scroll', handleScroll);
            return () => {
                  window.removeEventListener('scroll', handleScroll);
            };
      }, []);

      const [loading, setLoading] = useState(false);
      const [plugins, setPlugins] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/plugins`, {
                        headers: {
                              apikey: process.env.REACT_APP_Api_Key
                        }
                  })
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setPlugins(response.data.plugins);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  {!loading ? (
                        <>
                              <Helmet>
                                    {!loading &&
                                          plugins?.map((plugin) =>
                                                plugin.tag_type ===
                                                'Script Link' ? (
                                                      <script
                                                            async={true}
                                                            src={plugin.code}
                                                      />
                                                ) : null
                                          )}
                                    {plugins?.map((plugin) =>
                                          plugin.tag_type === 'Script' ? (
                                                <script type="text/javascript">
                                                      {plugin.code}
                                                </script>
                                          ) : null
                                    )}
                                    {plugins?.map((plugin) =>
                                          plugin.tag_type === 'Meta' ? (
                                                <meta
                                                      name={plugin.title}
                                                      content={plugin.code}
                                                />
                                          ) : null
                                    )}
                              </Helmet>
                        </>
                  ) : null}
                  <header>
                        <HeaderTop>
                              <Container>
                                    <HeaderContainer>
                                          <HeaderInfo>
                                                <HeaderInfoContent>
                                                      <HeaderInfoIcon>
                                                            <img
                                                                  src="/images/svg/phone.svg"
                                                                  alt="Innmotek phone icon"
                                                                  width="100%"
                                                                  height="100%"
                                                            />
                                                      </HeaderInfoIcon>
                                                      <Link to="tel:+91 98212 13350">
                                                            +91 98212 13350
                                                      </Link>
                                                </HeaderInfoContent>
                                                <HeaderInfoContent>
                                                      <HeaderInfoIcon>
                                                            <img
                                                                  src="/images/svg/mail.svg"
                                                                  alt="Innmotek mail icon"
                                                                  width="100%"
                                                                  height="100%"
                                                            />
                                                      </HeaderInfoIcon>
                                                      <Link to="mailto:info@innmotek.com">
                                                            info@innmotek.com
                                                      </Link>
                                                </HeaderInfoContent>
                                          </HeaderInfo>

                                          <SocialMedia />
                                    </HeaderContainer>
                              </Container>
                        </HeaderTop>

                        <Navigation isSticky={isSticky} />
                  </header>
            </>
      );
};

export default React.memo(Header);
