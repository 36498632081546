import React, { useEffect, useState } from 'react';
import {
      AllCategoryContainer,
      CategoryBackground,
      CategoryContent,
      CategoryItem,
      CategoryItemImage
} from './styles';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import ParentSkeleton from '../../components/skeletons/ParentSkeleton';

const AllCategory = () => {
      const [loading, setLoading] = useState(false);
      const [categories, setCategories] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/categories`)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [useLocation().pathname]);

      return (
            <>
                  {console.log(categories)}
                  <AllCategoryContainer>
                        <BreadcrumbBox title="Our Products" />
                        <CategoryBackground>
                              <Container>
                                    <Row className="g-4">
                                          {!loading ? (
                                                categories?.length > 0 &&
                                                categories?.map(
                                                      (item, index) => (
                                                            <Col
                                                                  lg={4}
                                                                  key={index}
                                                            >
                                                                  <CategoryItem>
                                                                        <CategoryItemImage>
                                                                              {item.image && (
                                                                                    <img
                                                                                          src={
                                                                                                item.image
                                                                                          }
                                                                                          alt={
                                                                                                item.title
                                                                                          }
                                                                                          width="100%"
                                                                                          height="100%"
                                                                                    />
                                                                              )}
                                                                        </CategoryItemImage>
                                                                        <CategoryContent>
                                                                              <h2>
                                                                                    {
                                                                                          item.title
                                                                                    }
                                                                              </h2>
                                                                              {item.slug ===
                                                                              'pool-pumps' ? (
                                                                                    <Link to="/pool-pump">
                                                                                          Find
                                                                                          Out
                                                                                          More
                                                                                    </Link>
                                                                              ) : item.slug ===
                                                                                'dehumidifier' ? (
                                                                                    <Link to="/dehumidifier">
                                                                                          Find
                                                                                          Out
                                                                                          More
                                                                                    </Link>
                                                                              ) : item.slug ===
                                                                                'pool-and-spa-cover' ? (
                                                                                    <Link to="/pool-and-spa-cover">
                                                                                          Find
                                                                                          Out
                                                                                          More
                                                                                    </Link>
                                                                              ) : item.slug ===
                                                                                'hot-water-storage-tank' ? (
                                                                                    <Link to="/hot-water-storage-tank">
                                                                                          Find
                                                                                          Out
                                                                                          More
                                                                                    </Link>
                                                                              ) : item.slug ===
                                                                                'bulk-water-storage-tank' ? (
                                                                                    <Link to="/bulk-water-storage-tank">
                                                                                          Find
                                                                                          Out
                                                                                          More
                                                                                    </Link>
                                                                              ) : item.slug ===
                                                                                'radiator-and-fancoil' ? (
                                                                                    <Link to="/radiator-and-fancoil">
                                                                                          Find
                                                                                          Out
                                                                                          More
                                                                                    </Link>
                                                                              ) : item.slug ===
                                                                                'robotic-pool-cleaner' ? (
                                                                                    <Link to="/robotic-pool-cleaner">
                                                                                          Find
                                                                                          Out
                                                                                          More
                                                                                    </Link>
                                                                              ) : (
                                                                                    <Link
                                                                                          to={
                                                                                                item.slug
                                                                                          }
                                                                                    >
                                                                                          Show
                                                                                          More
                                                                                    </Link>
                                                                              )}
                                                                        </CategoryContent>
                                                                  </CategoryItem>
                                                            </Col>
                                                      )
                                                )
                                          ) : (
                                                <>
                                                      <Col lg={4}>
                                                            <ParentSkeleton />
                                                      </Col>
                                                      <Col lg={4}>
                                                            <ParentSkeleton />
                                                      </Col>
                                                      <Col lg={4}>
                                                            <ParentSkeleton />
                                                      </Col>
                                                </>
                                          )}
                                    </Row>
                              </Container>
                        </CategoryBackground>
                  </AllCategoryContainer>
            </>
      );
};

export default AllCategory;
