import React from 'react';

const OneclickIcon = () => {
      return (
            <div>
                  <svg
                        id="fi_5152218"
                        enableBackground="new 0 0 512 512"
                        height="512"
                        viewBox="0 0 512 512"
                        width="512"
                        xmlns="http://www.w3.org/2000/svg"
                  >
                        <g>
                              <path d="m387.298 221.794c-10.572-25.064-39.978-35.814-64-24-5.422-12.856-16.409-22.808-29.923-26.838 16.568-18.901 25.593-42.793 25.593-67.956 0-56.794-46.206-103-103-103s-103 46.206-103 103c0 40.07 22.865 74.682 56 91.66v90.866c-5.913-7.07-10.548-12.288-14.394-16.133-12.77-12.769-28.697-20.393-42.607-20.393-35.219 0-49.766 18.699-52.324 22.447-3.971 5.818-3.33 13.542 1.366 18.63 2.391 3.992 12.785 23.522 53.215 115.935 9.674 22.113 22.722 37.096 33.205 49.135 12.97 14.893 21.538 24.733 21.538 41.853 0 8.284 6.716 15 15 15h232c8.284 0 15-6.716 15-15 0-35.365 24-54.168 24-105v-128c0-34.856-36.719-57.428-67.669-42.206zm-244.331-118.794c0-40.252 32.748-73 73-73s73 32.748 73 73c0 22.143-9.745 42.354-26 55.938v-54.938c0-25.916-21.084-47-47-47s-47 21.084-47 47v54.938c-16.255-13.584-26-33.795-26-55.938zm282 289c0 39.481-18.252 55.505-22.988 90h-204.501c-7.431-35.949-38.77-49.157-55.768-88.012-11.401-26.059-37.449-85.322-50.361-110.752 4.36-2.192 11.002-4.235 20.619-4.235 4.872 0 13.375 3.589 21.394 11.606 7.27 7.271 19.265 22.028 38.856 46.717 3.966 4.999 10.663 6.942 16.687 4.84 6.026-2.1 10.063-7.783 10.063-14.164v-224c0-9.374 7.626-17 17-17s17 7.626 17 17v176c0 8.284 6.716 15 15 15s15-6.716 15-15v-64c0-9.374 7.626-17 17-17s17 7.626 17 17v64c0 8.284 6.716 15 15 15s15-6.716 15-15v-40c0-9.374 7.626-17 17-17s17 7.626 17 17v40c0 8.284 6.716 15 15 15s15-6.716 15-15v-16c0-9.374 7.626-17 17-17s17 7.626 17 17v128z"></path>
                        </g>
                  </svg>
            </div>
      );
};

export default OneclickIcon;
