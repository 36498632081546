import React, { memo } from 'react';

const ContactIcon = memo(() => {
      return (
            <>
                  <i className="bx bx-phone"></i>
            </>
      );
});

export default ContactIcon;
