import React, { memo } from 'react';

const MessageIcon = memo(() => {
      return (
            <>
                  <i className="bx bx-envelope-open"></i>
            </>
      );
});

export default MessageIcon;
