import styled from 'styled-components';

export const BlogSkeletonContent = styled.div`
      padding: 0.5rem;
`;

export const BlogSkeletonDate = styled.div`
      display: flex;
      align-items: center;
      gap: 1.5rem;
`;

export const BlogSkeletonSummary = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
`;
