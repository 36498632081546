import styled from 'styled-components';
import { PoolPumpIntroImage } from '../PoolPump/styles';

export const GlassLinedTankContainer = styled.div`
      ${PoolPumpIntroImage} {
            img {
                  width: 200px;
            }
      }
      .col-lg-5,
      .col-lg-7 {
            margin-top: 0;
      }
      h3 span {
            color: #1155a6;
      }
`;
