import React from 'react';
import { Link } from 'react-router-dom';

const MenuItem = ({ title, link, children, small }) => {
      return (
            <>
                  <li
                        className={`${children && 'dropdown__container'} ${
                              small ? ' small__menu' : ''
                        }`}
                  >
                        <Link
                              to={link ? link : '#'}
                              className={
                                    children &&
                                    'd-flex align-items-center gap-1'
                              }
                        >
                              {title}
                              {children && <i className="bx bx-plus"></i>}
                        </Link>
                        {children}
                  </li>
            </>
      );
};

export default React.memo(MenuItem);
