import React from 'react';
import { ContactUsContainer, ContactUsContent, ContactUsImage } from './styles';
import { Link } from 'react-router-dom';
import { ProductItemButton } from '../../../styles/frontend/home';

const ContactUsBox = () => {
      return (
            <>
                  <ContactUsContainer className="mt-4">
                        <ContactUsImage>
                              <img
                                    src="/images/img/contact/faq-img-4.png"
                                    alt=" innmotek faq "
                                    className="img-fluid w-100"
                                    width="100%"
                                    height="100%"
                              />
                        </ContactUsImage>
                        <ContactUsContent>
                              <h2 className="text-white">Need Any Consult</h2>
                              <p>
                                    Find answers to frequently asked questions
                                    about Bizipress, contacts and general
                                    customer account information
                              </p>
                              <ProductItemButton
                                    className="mt-4"
                                    customBackground="#EAAA02"
                              >
                                    <Link to="/about-us">
                                          <span>Learn More</span>
                                    </Link>
                              </ProductItemButton>
                        </ContactUsContent>
                  </ContactUsContainer>
            </>
      );
};

export default ContactUsBox;
