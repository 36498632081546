import React from 'react';

const LocationIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">location_on</span>
            </>
      );
};

export default LocationIcon;
