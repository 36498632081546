import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
      CategoryBackground,
      CategoryContent,
      CategoryItem,
      CategoryItemImage
} from './styles';
import ParentSkeleton from '../../components/skeletons/ParentSkeleton';
import { Helmet } from 'react-helmet';

const CategoryPage = () => {
      const { categoryId } = useParams();

      const [loading, setLoading] = useState(false);
      const [category, setCategory] = useState([]);
      const [categories, setCategories] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/category/${categoryId}`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCategory(response.data.category);
                              setCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [useLocation().pathname]);

      return (
            <>
                  {!loading ? (
                        <Helmet>
                              <title>
                                    {category.seo_title
                                          ? category.seo_title
                                          : category.title}
                              </title>
                              {category.seo_description && (
                                    <meta
                                          name="description"
                                          content={category.seo_description}
                                    />
                              )}
                              {category.seo_keyword && (
                                    <meta
                                          name="keyword"
                                          content={category.seo_keyword}
                                    />
                              )}
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                        </Helmet>
                  ) : null}
                  <h1 className="d-none">
                        {category.seo_title
                              ? category.seo_title
                              : category.title}
                  </h1>
                  <CategoryBackground>
                        <Container>
                              <Row className="g-4">
                                    {!loading ? (
                                          categories?.length > 0 &&
                                          categories?.map((item, index) => (
                                                <Col lg={4} key={index}>
                                                      <CategoryItem>
                                                            <CategoryItemImage>
                                                                  {item.image && (
                                                                        <img
                                                                              src={
                                                                                    item.image
                                                                              }
                                                                              alt={
                                                                                    item.title
                                                                              }
                                                                              width="100%"
                                                                              height="100%"
                                                                        />
                                                                  )}
                                                            </CategoryItemImage>
                                                            <CategoryContent>
                                                                  <h2>
                                                                        {
                                                                              item.title
                                                                        }
                                                                  </h2>
                                                                  {item.slug ===
                                                                  'pool-pumps' ? (
                                                                        <Link to="/pool-pump">
                                                                              Find
                                                                              Out
                                                                              More
                                                                        </Link>
                                                                  ) : item.slug ===
                                                                    'dehumidifier' ? (
                                                                        <Link to="/dehumidifier">
                                                                              Find
                                                                              Out
                                                                              More
                                                                        </Link>
                                                                  ) : item.slug ===
                                                                    'pool-and-spa-cover' ? (
                                                                        <Link to="/pool-and-spa-cover">
                                                                              Find
                                                                              Out
                                                                              More
                                                                        </Link>
                                                                  ) : item.slug ===
                                                                    'hot-water-storage-tank' ? (
                                                                        <Link to="/hot-water-storage-tank">
                                                                              Find
                                                                              Out
                                                                              More
                                                                        </Link>
                                                                  ) : item.slug ===
                                                                    'bulk-water-storage-tank' ? (
                                                                        <Link to="/bulk-water-storage-tank">
                                                                              Find
                                                                              Out
                                                                              More
                                                                        </Link>
                                                                  ) : item.slug ===
                                                                    'thermal-solar-collector' ? (
                                                                        <Link to="/thermal-solar-collector">
                                                                              Find
                                                                              Out
                                                                              More
                                                                        </Link>
                                                                  ) : item.slug ===
                                                                    'pressurized-thermosiphon' ? (
                                                                        <Link to="/pressurized-thermosiphon">
                                                                              Find
                                                                              Out
                                                                              More
                                                                        </Link>
                                                                  ) : item.slug ===
                                                                    'modular-panel-tank' ? (
                                                                        <Link to="/modular-panel-tank">
                                                                              Find
                                                                              Out
                                                                              More
                                                                        </Link>
                                                                  ) : item.slug ===
                                                                    'glass-lined-tanks' ? (
                                                                        <Link to="/glass-lined-hot-water-storage-tank">
                                                                              Find
                                                                              Out
                                                                              More
                                                                        </Link>
                                                                  ) : item.slug ===
                                                                    'robotic-pool-cleaner' ? (
                                                                        <Link to="/robotic-pool-cleaner">
                                                                              Find
                                                                              Out
                                                                              More
                                                                        </Link>
                                                                  ) : (
                                                                        <Link
                                                                              to={`${
                                                                                    item.categories ===
                                                                                    0
                                                                                          ? '/products/'
                                                                                          : '/category/'
                                                                              }${
                                                                                    item.slug
                                                                              }${
                                                                                    item.categories ===
                                                                                    0
                                                                                          ? '/category'
                                                                                          : '/' +
                                                                                            item.slug
                                                                              }`}
                                                                        >
                                                                              Find
                                                                              Out
                                                                              More
                                                                        </Link>
                                                                  )}
                                                            </CategoryContent>
                                                      </CategoryItem>
                                                </Col>
                                          ))
                                    ) : (
                                          <>
                                                <Col lg={4}>
                                                      <ParentSkeleton />
                                                </Col>
                                                <Col lg={4}>
                                                      <ParentSkeleton />
                                                </Col>
                                                <Col lg={4}>
                                                      <ParentSkeleton />
                                                </Col>
                                          </>
                                    )}
                              </Row>
                        </Container>
                  </CategoryBackground>
            </>
      );
};

export default CategoryPage;
