import React, { useEffect, useState } from 'react';
import {
      HeaderLogo,
      HeaderMenu,
      NavigationContainer,
      NavigationMenu
} from '../../../styles/layouts/navigation';
import MenuItem from './MenuItem';
import { Link } from 'react-router-dom';
import axios from 'axios';
import MobileMenu from './MobileMenu';

const Navigation = ({ isSticky }) => {
      const [categories, setCategories] = useState([]);
      const [services, setServices] = useState([]);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/categories`)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/services`)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setServices(response.data.services);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <HeaderMenu className={`${isSticky ? 'sticky' : ''}`}>
                        <NavigationContainer>
                              <HeaderLogo>
                                    <Link to="/">
                                          <img
                                                src="/images/logo.png"
                                                alt="Innmotek Official Logo"
                                                width="100%"
                                                height="100%"
                                          />
                                    </Link>
                              </HeaderLogo>
                              <nav>
                                    <NavigationMenu>
                                          <MenuItem
                                                title="Home"
                                                link="/"
                                                small={false}
                                          />
                                          <MenuItem
                                                link="/about-us"
                                                title="About"
                                                children={true}
                                                small={false}
                                          />
                                          <MenuItem
                                                link={'/category'}
                                                title="Products"
                                                children={true}
                                                small={false}
                                          >
                                                {!loading ? (
                                                      categories.length > 0 ? (
                                                            <div className="dropdown__content define">
                                                                  <ul>
                                                                        {categories?.map(
                                                                              (
                                                                                    category,
                                                                                    index
                                                                              ) => (
                                                                                    <li
                                                                                          key={
                                                                                                index
                                                                                          }
                                                                                    >
                                                                                          {category.slug ===
                                                                                          'radiator-and-fancoil' ? (
                                                                                                <Link
                                                                                                      to="/radiator-and-fancoil"
                                                                                                      className="main__category"
                                                                                                >
                                                                                                      {
                                                                                                            category.title
                                                                                                      }
                                                                                                </Link>
                                                                                          ) : (
                                                                                                <Link
                                                                                                      to={`/category/${category.slug}`}
                                                                                                      className="main__category"
                                                                                                >
                                                                                                      {
                                                                                                            category.title
                                                                                                      }
                                                                                                </Link>
                                                                                          )}

                                                                                          {category
                                                                                                ?.subCategory
                                                                                                ?.length >
                                                                                                0 && (
                                                                                                <ul>
                                                                                                      {category?.subCategory?.map(
                                                                                                            (
                                                                                                                  item,
                                                                                                                  key
                                                                                                            ) => (
                                                                                                                  <li
                                                                                                                        key={
                                                                                                                              key
                                                                                                                        }
                                                                                                                  >
                                                                                                                        {item.slug ===
                                                                                                                        'pool-pumps' ? (
                                                                                                                              <Link to="/pool-pump">
                                                                                                                                    <i className="bx bx-chevron-right"></i>
                                                                                                                                    {
                                                                                                                                          item.title
                                                                                                                                    }
                                                                                                                              </Link>
                                                                                                                        ) : item.slug ===
                                                                                                                          'dehumidifier' ? (
                                                                                                                              <Link to="/dehumidifier">
                                                                                                                                    <i className="bx bx-chevron-right"></i>
                                                                                                                                    {
                                                                                                                                          item.title
                                                                                                                                    }
                                                                                                                              </Link>
                                                                                                                        ) : item.slug ===
                                                                                                                          'thermal-solar-collector' ? (
                                                                                                                              <Link to="/thermal-solar-collector">
                                                                                                                                    <i className="bx bx-chevron-right"></i>
                                                                                                                                    {
                                                                                                                                          item.title
                                                                                                                                    }
                                                                                                                              </Link>
                                                                                                                        ) : item.slug ===
                                                                                                                          'pressurized-thermosiphon' ? (
                                                                                                                              <Link to="/pressurized-thermosiphon">
                                                                                                                                    <i className="bx bx-chevron-right"></i>
                                                                                                                                    {
                                                                                                                                          item.title
                                                                                                                                    }
                                                                                                                              </Link>
                                                                                                                        ) : item.slug ===
                                                                                                                          'pool-and-spa-cover' ? (
                                                                                                                              <Link to="/pool-and-spa-cover">
                                                                                                                                    <i className="bx bx-chevron-right"></i>
                                                                                                                                    {
                                                                                                                                          item.title
                                                                                                                                    }
                                                                                                                              </Link>
                                                                                                                        ) : item.slug ===
                                                                                                                          'hot-water-storage-tanks' ? (
                                                                                                                              <Link to="/hot-water-storage-tanks">
                                                                                                                                    <i className="bx bx-chevron-right"></i>
                                                                                                                                    {
                                                                                                                                          item.title
                                                                                                                                    }
                                                                                                                              </Link>
                                                                                                                        ) : item.slug ===
                                                                                                                          'hot-water-storage-tank' ? (
                                                                                                                              <Link to="/hot-water-storage-tank">
                                                                                                                                    <i className="bx bx-chevron-right"></i>
                                                                                                                                    {
                                                                                                                                          item.title
                                                                                                                                    }
                                                                                                                              </Link>
                                                                                                                        ) : item.slug ===
                                                                                                                          'modular-panel-tank' ? (
                                                                                                                              <Link to="/modular-panel-tank">
                                                                                                                                    <i className="bx bx-chevron-right"></i>
                                                                                                                                    {
                                                                                                                                          item.title
                                                                                                                                    }
                                                                                                                              </Link>
                                                                                                                        ) : item.slug ===
                                                                                                                          'glass-lined-tanks' ? (
                                                                                                                              <Link to="/glass-lined-hot-water-storage-tank">
                                                                                                                                    <i className="bx bx-chevron-right"></i>
                                                                                                                                    {
                                                                                                                                          item.title
                                                                                                                                    }
                                                                                                                              </Link>
                                                                                                                        ) : item.slug ===
                                                                                                                          'robotic-pool-cleaner' ? (
                                                                                                                              <Link to="/robotic-pool-cleaner">
                                                                                                                                    <i className="bx bx-chevron-right"></i>
                                                                                                                                    {
                                                                                                                                          item.title
                                                                                                                                    }
                                                                                                                              </Link>
                                                                                                                        ) : (
                                                                                                                              <Link
                                                                                                                                    to={`${
                                                                                                                                          item.categories ===
                                                                                                                                          0
                                                                                                                                                ? '/products/'
                                                                                                                                                : '/category/'
                                                                                                                                    }${
                                                                                                                                          item.slug
                                                                                                                                    }${
                                                                                                                                          item.categories ===
                                                                                                                                          0
                                                                                                                                                ? '/category'
                                                                                                                                                : '/' +
                                                                                                                                                  item.slug
                                                                                                                                    }`}
                                                                                                                              >
                                                                                                                                    <i className="bx bx-chevron-right"></i>
                                                                                                                                    {
                                                                                                                                          item.title
                                                                                                                                    }
                                                                                                                              </Link>
                                                                                                                        )}
                                                                                                                  </li>
                                                                                                            )
                                                                                                      )}
                                                                                                </ul>
                                                                                          )}
                                                                                    </li>
                                                                              )
                                                                        )}
                                                                  </ul>
                                                            </div>
                                                      ) : null
                                                ) : null}
                                          </MenuItem>
                                          {/* <MenuItem
                                                title="Services"
                                                small={true}
                                          >
                                                <div className="dropdown__content">
                                                      <ul>
                                                            {!loading
                                                                  ? services?.length >
                                                                          0 &&
                                                                    services?.map(
                                                                          (
                                                                                item,
                                                                                index
                                                                          ) => (
                                                                                <li
                                                                                      key={
                                                                                            index
                                                                                      }
                                                                                >
                                                                                      <Link
                                                                                            to={`/services/${item.slug}`}
                                                                                      >
                                                                                            {
                                                                                                  item.title
                                                                                            }
                                                                                      </Link>
                                                                                </li>
                                                                          )
                                                                    )
                                                                  : null}
                                                      </ul>
                                                </div>
                                          </MenuItem> */}
                                          <MenuItem
                                                title="Projects"
                                                link="/projects"
                                          />
                                          <MenuItem
                                                link="/contact-us"
                                                title="Contact Us"
                                          />
                                    </NavigationMenu>
                              </nav>
                              <MobileMenu
                                    loading={loading}
                                    categories={categories}
                                    services={services}
                              />
                        </NavigationContainer>
                  </HeaderMenu>
            </>
      );
};

export default Navigation;
