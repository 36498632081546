import React from 'react';
import {
   SolarBenefitContent,
   SolarBenefitImage,
   SolarBenefitItem,
   SolarBenefitItemImage,
   SolarBenefitItems,
   SolarBenefitKeyFeature,
   SolarBenefitTitle,
   SolarBenefitsContainer
} from './styles';
import { Col, Container, Row } from 'react-bootstrap';

const benefits = [
   {
      title: 'High selective PVD absorber coating, for fast and high temperatureproduction'
   },
   {
      title: 'Water frame entirely made of copper.'
   },
   {
      title: 'Tempered Hail-resistant solar low iron glass.'
   },
   {
      title: 'Maximum heat transfer between the absorber sheet and the copper water frame, thanks to optimized laser welding technology.'
   },
   {
      title: 'Incident angle modifier Kθ =0.96 at 50° ensures maximum radiation absorption throughout the day.'
   },
   {
      title: 'High quality, recyclable materials ensure a long service life and high environmental compatibility.'
   }
];

const SolarBenefits = ({ image, title }) => {
   return (
      <>
         <SolarBenefitsContainer>
            <Container>
               <Row>
                  <Col>
                     <SolarBenefitContent>
                        <SolarBenefitTitle>
                           Benefits of {title}
                        </SolarBenefitTitle>
                        <SolarBenefitKeyFeature>
                           Superb efficiency rating collectors “FMAX”
                           <br />
                           (η0,a=83% according to EN12975 Standard) series with:
                        </SolarBenefitKeyFeature>
                        <SolarBenefitItems className="ps-0">
                           {benefits.map((item, index) => (
                              <SolarBenefitItem
                                 className="d-flex align-items-center"
                                 key={index}
                              >
                                 <i class="bx bxs-check-square me-2"></i>
                                 {item.title}
                              </SolarBenefitItem>
                           ))}
                        </SolarBenefitItems>
                        <SolarBenefitItemImage>
                           <img
                              src={'images/img/solar/features.png'}
                              alt=""
                              className="img-fluid w-75"
                           />
                        </SolarBenefitItemImage>
                     </SolarBenefitContent>
                  </Col>
                  <Col lg={4}>
                     <SolarBenefitImage>
                        <img src={image} alt={title} />
                     </SolarBenefitImage>
                  </Col>
               </Row>
            </Container>
         </SolarBenefitsContainer>
      </>
   );
};

export default SolarBenefits;
