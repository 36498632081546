import styled from 'styled-components';

export const BlogItemImage = styled.div`
      overflow: hidden;

      img {
            transition: transform 0.3s linear;
      }
`;

export const BlogItemTitle = styled.div`
      margin: 1rem 0;
      height: ${(props) => props.height && props.height};
      overflow: hidden;

      h3 {
            color: #0b1e33;
            font-size: 22px;
            transition: all 0.3s ease-in;
            font-family: 'Rajdhani', sans-serif;

            &:hover {
                  color: ${(props) => props.theme.secondary};
            }
      }
`;

export const BlogItemButton = styled.div`
      color: ${(props) => props.theme.secondary};
      font-family: 20px;
      font-weight: 500;
      transition: all 0.3s ease-in;

      &:hover {
            color: ${(props) => props.theme.primary};
      }
`;

export const BlogItem = styled.div`
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      transition: transform 0.3s linear;
      height: 100%;

      a {
            display: block;
      }

      :hover > ${BlogItemImage} {
            img {
                  transform: scale(1.2);
                  transition: transform 0.3s linear;
            }
      }
`;

export const BlogItemContent = styled.div`
      padding: 20px 30px 30px;
`;

export const BlogItemDetail = styled.div`
      font-family: 'Nunito Sans', sans-serif;
      font-size: 14px;
      color: #152e3a;
      display: flex;
      align-items: center;
      gap: 1.5rem;
`;

export const BlogItemAuthor = styled.div`
      display: flex;
      align-items: center;
      gap: 0.5rem;

      i {
            color: ${(props) => props.theme.secondary};
      }

      span {
            font-family: 'Nunito Sans', sans-serif;
            display: flex;
            align-items: center;
            gap: 0.5rem;
      }
`;

export const BlogItemInfo = styled.div`
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;

      span {
            color: #152e3a;
      }
`;
