import React from 'react';

const ThermometerIcon = () => {
      return (
            <>
                  <svg
                        class="h-7 w-7 md:h-10 md:w-10 lg:h-12 lg:w-12"
                        alt="temperature"
                        width="64"
                        height="64"
                        viewBox="0 0 64 64"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                  >
                        <path
                              d="M30.5086 42.9663C30.3736 42.9672 30.2398 42.9414 30.1148 42.8904C29.9898 42.8394 29.8761 42.7641 29.7803 42.669C29.6845 42.5738 29.6085 42.4607 29.5566 42.3361C29.5047 42.2114 29.478 42.0777 29.478 41.9427V20.0604C29.478 19.787 29.5866 19.5249 29.7799 19.3316C29.9732 19.1384 30.2353 19.0298 30.5086 19.0298C30.7819 19.0298 31.0441 19.1384 31.2374 19.3316C31.4306 19.5249 31.5392 19.787 31.5392 20.0604V41.9427C31.5373 42.2148 31.4279 42.4752 31.2349 42.6669C31.0418 42.8587 30.7807 42.9663 30.5086 42.9663"
                              fill="currentColor"
                        ></path>{' '}
                        <path
                              d="M45.523 34.5592H40.4971C40.356 34.5709 40.2141 34.5533 40.0801 34.5073C39.9462 34.4614 39.8233 34.3882 39.7191 34.2923C39.615 34.1965 39.5318 34.08 39.4749 33.9504C39.418 33.8208 39.3887 33.6807 39.3887 33.5392C39.3887 33.3976 39.418 33.2576 39.4749 33.1279C39.5318 32.9983 39.615 32.8818 39.7191 32.786C39.8233 32.6901 39.9462 32.6169 40.0801 32.571C40.2141 32.5251 40.356 32.5074 40.4971 32.5192H45.523C45.6641 32.5074 45.8061 32.5251 45.94 32.571C46.0739 32.6169 46.1968 32.6901 46.301 32.786C46.4052 32.8818 46.4883 32.9983 46.5452 33.1279C46.6021 33.2576 46.6315 33.3976 46.6315 33.5392C46.6315 33.6807 46.6021 33.8208 46.5452 33.9504C46.4883 34.08 46.4052 34.1965 46.301 34.2923C46.1968 34.3882 46.0739 34.4614 45.94 34.5073C45.8061 34.5533 45.6641 34.5709 45.523 34.5592"
                              fill="currentColor"
                        ></path>{' '}
                        <path
                              d="M43.0663 28.8485H40.504C40.3629 28.8602 40.2209 28.8426 40.087 28.7966C39.9531 28.7507 39.8301 28.6775 39.726 28.5816C39.6218 28.4858 39.5386 28.3693 39.4818 28.2397C39.4249 28.1101 39.3955 27.97 39.3955 27.8285C39.3955 27.6869 39.4249 27.5469 39.4818 27.4172C39.5386 27.2876 39.6218 27.1712 39.726 27.0753C39.8301 26.9794 39.9531 26.9062 40.087 26.8603C40.2209 26.8144 40.3629 26.7967 40.504 26.8085H43.0663C43.2074 26.7967 43.3494 26.8144 43.4833 26.8603C43.6172 26.9062 43.7401 26.9794 43.8443 27.0753C43.9485 27.1712 44.0317 27.2876 44.0885 27.4172C44.1454 27.5469 44.1748 27.6869 44.1748 27.8285C44.1748 27.97 44.1454 28.1101 44.0885 28.2397C44.0317 28.3693 43.9485 28.4858 43.8443 28.5816C43.7401 28.6775 43.6172 28.7507 43.4833 28.7966C43.3494 28.8426 43.2074 28.8602 43.0663 28.8485"
                              fill="currentColor"
                        ></path>{' '}
                        <path
                              d="M45.523 23.138H40.4971C40.356 23.1498 40.2141 23.1321 40.0801 23.0862C39.9462 23.0403 39.8233 22.967 39.7191 22.8712C39.615 22.7753 39.5318 22.6589 39.4749 22.5293C39.418 22.3996 39.3887 22.2596 39.3887 22.118C39.3887 21.9764 39.418 21.8364 39.4749 21.7068C39.5318 21.5771 39.615 21.4607 39.7191 21.3648C39.8233 21.269 39.9462 21.1958 40.0801 21.1498C40.2141 21.1039 40.356 21.0863 40.4971 21.098H45.523C45.6641 21.0863 45.8061 21.1039 45.94 21.1498C46.0739 21.1958 46.1968 21.269 46.301 21.3648C46.4052 21.4607 46.4883 21.5771 46.5452 21.7068C46.6021 21.8364 46.6315 21.9764 46.6315 22.118C46.6315 22.2596 46.6021 22.3996 46.5452 22.5293C46.4883 22.6589 46.4052 22.7753 46.301 22.8712C46.1968 22.967 46.0739 23.0403 45.94 23.0862C45.8061 23.1321 45.6641 23.1498 45.523 23.138"
                              fill="currentColor"
                        ></path>{' '}
                        <path
                              d="M43.0663 17.4273H40.504C40.3629 17.4391 40.2209 17.4214 40.087 17.3755C39.9531 17.3296 39.8301 17.2564 39.726 17.1605C39.6218 17.0646 39.5386 16.9482 39.4818 16.8186C39.4249 16.6889 39.3955 16.5489 39.3955 16.4073C39.3955 16.2658 39.4249 16.1257 39.4818 15.9961C39.5386 15.8664 39.6218 15.75 39.726 15.6541C39.8301 15.5583 39.9531 15.4851 40.087 15.4391C40.2209 15.3932 40.3629 15.3756 40.504 15.3873H43.0663C43.2074 15.3756 43.3494 15.3932 43.4833 15.4391C43.6172 15.4851 43.7401 15.5583 43.8443 15.6541C43.9485 15.75 44.0317 15.8664 44.0885 15.9961C44.1454 16.1257 44.1748 16.2658 44.1748 16.4073C44.1748 16.5489 44.1454 16.6889 44.0885 16.8186C44.0317 16.9482 43.9485 17.0646 43.8443 17.1605C43.7401 17.2564 43.6172 17.3296 43.4833 17.3755C43.3494 17.4214 43.2074 17.4391 43.0663 17.4273"
                              fill="currentColor"
                        ></path>{' '}
                        <path
                              d="M45.5228 11.7168H40.4969C40.2274 11.7168 39.9688 11.6097 39.7782 11.4191C39.5876 11.2284 39.4805 10.9699 39.4805 10.7003C39.4805 10.4307 39.5876 10.1722 39.7782 9.98156C39.9688 9.79093 40.2274 9.68384 40.4969 9.68384H45.5228C45.7924 9.68384 46.0509 9.79093 46.2416 9.98156C46.4322 10.1722 46.5393 10.4307 46.5393 10.7003C46.5393 10.9699 46.4322 11.2284 46.2416 11.4191C46.0509 11.6097 45.7924 11.7168 45.5228 11.7168"
                              fill="currentColor"
                        ></path>{' '}
                        <path
                              d="M30.4872 61.7214C29.5386 61.7221 28.5925 61.6251 27.6637 61.432C25.1471 60.9131 22.8335 59.6796 21.0001 57.8792C19.1667 56.0789 17.8913 53.7882 17.3266 51.2815C16.762 48.7747 16.932 46.1584 17.8164 43.7458C18.7009 41.3333 20.2621 39.2269 22.3131 37.6791C22.9896 37.1769 23.5392 36.5234 23.9179 35.7708C24.2966 35.0182 24.494 34.1875 24.4943 33.345V8.6391C24.5893 7.11805 25.2583 5.6898 26.3659 4.64304C27.4736 3.59628 28.9373 3.00907 30.4613 3.0001C31.9853 2.99114 33.4559 3.56109 34.5758 4.59475C35.6957 5.62841 36.3814 7.04868 36.4943 8.56851V33.345C36.4921 34.1773 36.682 34.9988 37.0493 35.7457C37.4166 36.4925 37.9514 37.1445 38.6119 37.6509C40.2458 38.8754 41.5785 40.4567 42.5084 42.2744C43.4714 44.1475 43.9701 46.2247 43.9625 48.3309C43.9669 50.3218 43.5254 52.2885 42.6703 54.0865C41.8153 55.8845 40.5684 57.4683 39.0214 58.7214C36.5998 60.6714 33.5821 61.7304 30.4731 61.7214H30.4872ZM30.2896 4.62263C29.2759 4.68385 28.3238 5.13017 27.6284 5.87025C26.9329 6.61034 26.5466 7.58822 26.5484 8.6038V33.3097C26.5408 34.4659 26.2668 35.6049 25.7478 36.6381C25.2287 37.6713 24.4785 38.571 23.5555 39.2673C21.8042 40.5843 20.4721 42.3807 19.7204 44.439C18.9688 46.4973 18.8297 48.7293 19.3202 50.865C19.7928 52.9791 20.8687 54.9107 22.4174 56.4255C23.966 57.9403 25.9208 58.9732 28.0449 59.3991C29.7261 59.7557 31.4656 59.735 33.1379 59.3383C34.8101 58.9416 36.3736 58.1788 37.7155 57.105C39.0559 56.0267 40.1323 54.6564 40.8624 53.0988C41.5926 51.5411 41.9573 49.8372 41.9287 48.1172C41.9001 46.3971 41.479 44.7063 40.6974 43.1738C39.9159 41.6413 38.7945 40.3075 37.419 39.2744C36.5031 38.5806 35.7602 37.6844 35.2483 36.6557C34.7364 35.6271 34.4695 34.494 34.4684 33.345V8.54733C34.4665 8.01074 34.3551 7.48018 34.1411 6.98809C33.9271 6.49601 33.615 6.05274 33.2239 5.68538C32.8328 5.31803 32.3708 5.03431 31.8663 4.85158C31.3617 4.66885 30.8252 4.59094 30.2896 4.62263"
                              fill="currentColor"
                        ></path>{' '}
                        <path
                              d="M30.8616 52.5381C29.7869 52.6002 28.7187 52.3337 27.7992 51.774C26.8797 51.2143 26.1523 50.3879 25.7139 49.4047C25.2755 48.4215 25.1468 47.3282 25.345 46.2701C25.5431 45.2119 26.0587 44.2392 26.8231 43.4813C27.5875 42.7234 28.5646 42.2161 29.6244 42.027C30.6841 41.8379 31.7763 41.9759 32.7557 42.4227C33.7351 42.8695 34.5553 43.6039 35.1072 44.5281C35.659 45.4524 35.9164 46.5228 35.8451 47.5969C35.7521 48.8838 35.1961 50.0935 34.2798 51.0019C33.3636 51.9104 32.1492 52.4561 30.8616 52.5381V52.5381ZM30.8051 43.9969C30.1393 43.9414 29.4725 44.0917 28.8949 44.4275C28.3173 44.7633 27.8568 45.2685 27.5756 45.8746C27.2945 46.4807 27.2063 47.1585 27.323 47.8164C27.4397 48.4742 27.7556 49.0804 28.228 49.5528C28.7005 50.0253 29.3066 50.3412 29.9645 50.4579C30.6224 50.5746 31.3002 50.4864 31.9063 50.2052C32.5124 49.9241 33.0175 49.4636 33.3533 48.886C33.6892 48.3084 33.8395 47.6416 33.7839 46.9757C33.7095 46.2137 33.371 45.5017 32.827 44.9628C32.283 44.424 31.5678 44.0923 30.8051 44.0252"
                              fill="currentColor"
                        ></path>
                  </svg>
            </>
      );
};

export default ThermometerIcon;
