import React from 'react';
import { SolarCollectorContainer } from '../SolarCollector/styles';
import SolarIntroduction from '../../components/common/SolarCommonLayout/SolarIntroduction';
import SolarBenefits from '../../components/common/SolarCommonLayout/SolarBenefits';
import SolarBanner from '../../components/common/SolarCommonLayout/SolarBanner';
import SolarOverview from '../../components/common/SolarCommonLayout/SolarOverview';
import SolarProject from '../../components/common/SolarCommonLayout/SolarProject';
import SolarThermoIntro from '../../components/common/SolarCommonLayout/SolarThermoIntro';
import SolarThermoBenefits from '../../components/common/SolarCommonLayout/SolarThermoBenefits';

const SolarThermosyphon = () => {
   return (
      <>
         <SolarCollectorContainer>
            <SolarThermoIntro />
            <SolarThermoBenefits />
            <SolarBanner />
            <SolarProject />
         </SolarCollectorContainer>
      </>
   );
};

export default SolarThermosyphon;
