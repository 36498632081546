import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BlogDetailContainer = styled(Container)`
      margin-top: 3rem;
      margin-bottom: 3rem;
`;

export const BlogDetailContent = styled.div`
      padding: 30px;
      border: 2px solid #f4f3f8;
      background-color: #f4f3f8;
      margin-bottom: 30px;
`;

export const BlogDetailImage = styled.div`
      position: relative;

      img {
            width: 100%;
            height: 100%;
      }
`;

export const BlogCategory = styled(Link)`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px 20px;
      color: ${(props) => props.theme.white};
      display: block;
      background-color: ${(props) => props.theme.secondary};
      transition: background-color 0.3s ease;

      &:hover {
            background-color: ${(props) => props.theme.primary};
      }
`;

export const BlogExtra = styled.div`
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 10px;
      font-size: 18px;
`;

export const BlogExtraItem = styled.div`
      color: #1d1d1dd9;
      align-items: center;
      display: flex;
      gap: 10px;
      color: #333;
      transition: color 0.3s ease;

      i {
            color: ${(props) => props.theme.secondary};
      }

      div {
            color: ${(props) => props.color && props.theme.secondary};
      }
`;

export const BlogDetailDescription = styled.div`
      margin-top: 10px;
      font-size: 18px;

      img {
            width: 100%;
            height: 100%;
      }

      blockquote {
            font-size: 20px;
            line-height: 1.6;
            font-weight: 400;
            background-color: #fff;
            padding: 60px 50px 60px 60px;
            margin: 30px 0 30px 0px;
            font-style: normal;
            text-align: center;
            border-left: 0;
            position: relative;
            z-index: 1;
      }
`;

export const BlogDetailTitle = styled.h2`
      color: ${(props) => props.theme.primary};
      font-size: 22px;
      font-weight: 500;
`;

export const RelatedBlogContent = styled.div`
      margin-top: 10px;
`;

export const RealtedBlogItem = styled.div`
      display: flex;
      gap: 20px;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid #bbb;

      &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
            padding-top: 20px;
      }

      img {
            width: 100%;
            height: 100%;
            max-width: 100px;
            max-height: 100px;
      }
`;

export const RealtedCategoryItem = styled.div`
      display: flex;
      gap: 20px;
      align-items: center;
      padding-bottom: 10px;
      transition: all 0.5s ease;

      &:hover {
            margin-left: 10px;
      }

      &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
      }

      img {
            width: 100%;
            height: 100%;
            max-width: 100px;
            max-height: 100px;
      }
`;

export const RealtedBlogItemTitle = styled(Link)`
      color: ${(props) => props.theme.primary};

      .category__item {
            font-size: 18px;
      }

      &:hover {
            color: ${(props) => props.theme.secondary};
      }
`;
