import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const ProjectDetailTitle = styled.div`
   padding: 10px 20px;
   border: 1px solid #e5e5e5;
   background-color: ${(props) => props.theme.white};
   display: flex;
   align-items: center;
   justify-content: space-between;

   h2 {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 0;
   }
`;

export const ProjectSwiper = styled(Swiper)`
   border: 1px solid #e5e5e5;
   border-bottom: 0;
   img {
      height: 460px;
      width: 100%;
   }
`;

export const ThumbnailSwiper = styled(Swiper)`
   margin-top: 20px;

   background-color: ${(props) => props.theme.white};

   img {
      height: 195px;
      width: 100%;
      border-radius: 0px;
   }
`;

export const ShareProject = styled.div`
   i {
      font-size: 24px;
   }
`;

export const ProjectDescription = styled.div`
   background-color: ${(props) => props.theme.white};
   border: 1px solid #e5e5e5;
   margin-top: 20px;
   padding: 20px;

   h3 {
      font-weight: 300;
      font-size: 24px;
      display: inline-block;
      border-bottom: 1px solid ${(props) => props.theme.secondary};
   }

   p {
      text-align: justify;
   }

   img {
      width: 100%;
      margin-bottom: 10px;
   }
`;
