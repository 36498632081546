import React from 'react';

const MailIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined ">mail</span>
            </>
      );
};

export default MailIcon;
