export const solarBrands = [
   {
      id: 1,
      title: ''
   }
];
export const solarProjectImages = [
   {
      id: 1,
      title: 'Innmotek Solar Proect Image',
      image: 'images/img/solar/1.jpg'
   },
   {
      id: 2,
      title: 'Innmotek Solar Proect Image',
      image: 'images/img/solar/2.jpg'
   },
   {
      id: 3,
      title: 'Innmotek Solar Proect Image',
      image: 'images/img/solar/3.jpg'
   },
   {
      id: 4,
      title: 'Innmotek Solar Proect Image',
      image: 'images/img/solar/4.jpg'
   },
   {
      id: 5,
      title: 'Innmotek Solar Proect Image',
      image: 'images/img/solar/5.jpg'
   }
];
