import styled from 'styled-components';

export const ContactItemContainer = styled.div`
      border: 2px solid #aeaeae;
      text-align: center;
      padding-bottom: 30px;
      padding-top: 30px;
      background-color: #f7f7fa;
`;

export const ContactItemIcon = styled.div`
      color: ${(props) => props.theme.secondary};
      font-size: 52px;
`;

export const ContactItemTitle = styled.div`
      font-size: 24px;
      font-weight: 500;
`;

export const ContactItemDetail = styled.div`
      margin-bottom: 30px;
      margin-top: 10px;

      p {
            font-weight: 400;

            &:last-child {
                  margin-bottom: 0;
            }
      }
`;
