import React from 'react';

const CalendarIcon = () => {
      return (
            <>
                  <i className="bx bx-calendar"></i>
            </>
      );
};

export default CalendarIcon;
