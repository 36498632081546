import React from 'react';

const FacebookIcon = () => {
      return (
            <>
                  <i className="bx bxl-facebook" aria-hidden="true"></i>
            </>
      );
};

export default FacebookIcon;
