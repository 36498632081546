import React, { useContext } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { BlogDetailContainer } from './BlogDetail/styles';
import { Col, Row } from 'react-bootstrap';
import BlogItemBox from '../../components/common/BlogItemBox';
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import BlogSkeleton from '../../components/skeletons/BlogSkeleton';
import MetaContext from '../../stores/MetaContext';

const BlogPage = () => {
   const [blogs, setBlogs] = useState([]);
   const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/blogs`)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBlogs(response.data.blogs);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, []);

      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('blogs');

      return (
            <>
                  <BreadcrumbBox title={`Blogs`} />

                  <BlogDetailContainer>
                        <Row className="g-4">
                              {!loading ? (
                                    blogs?.length > 0 ? (
                                          blogs?.map((blog, index) => (
                                                <Col lg={4} key={index}>
                                                      <BlogItemBox
                                                            blog={blog}
                                                      />
                                                </Col>
                                          ))
                                    ) : null
                              ) : (
                                    <>
                                          <Col lg={4}>
                                                <BlogSkeleton />
                                          </Col>
                                          <Col lg={4}>
                                                <BlogSkeleton />
                                          </Col>
                                          <Col lg={4}>
                                                <BlogSkeleton />
                                          </Col>
                                          <Col lg={4}>
                                                <BlogSkeleton />
                                          </Col>
                                          <Col lg={4}>
                                                <BlogSkeleton />
                                          </Col>
                                          <Col lg={4}>
                                                <BlogSkeleton />
                                          </Col>
                                    </>
                              )}
                        </Row>
                  </BlogDetailContainer>
            </>
      );
};

export default BlogPage;
