export const applicable = [
 {
  id: '1',
  title: 'Fish Drier',
  image: '/images/img/applicable/applicable1.webp'
 },
 {
  id: '2',
  title: 'Flower and Herbs Drying',
  image: '/images/img/applicable/applicable2.webp'
 },
 {
  id: '3',
  title: 'Wood Drying',
  image: '/images/img/applicable/applicable3.webp'
 },
 {
  id: '4',
  title: 'Meat Dehydrate',
  image: '/images/img/applicable/applicable4.webp'
 },
 {
  id: '5',
  title: 'Pet Food Drying',
  image: '/images/img/applicable/applicable5.webp'
 },
 {
  id: '6',
  title: 'Nuts Dehydrate',
  image: '/images/img/applicable/applicable6.webp'
 },
 {
  id: '7',
  title: 'Pasta&Noodles Dehydrate',
  image: '/images/img/applicable/applicable7.webp'
 },
 {
  id: '8',
  title: 'Spices Dehydrate',
  image: '/images/img/applicable/applicable8.webp'
 },
 {
  id: '9',
  title: 'Vegetable Dehydrate',
  image: '/images/img/applicable/applicable9.webp'
 },
 {
  id: '10',
  title: 'Fruit Drying',
  image: '/images/img/applicable/applicable10.webp'
 }
]