import React from 'react';

const LinkedinIcon = () => {
   return (
      <>
         <i class="bx bxl-linkedin"></i>
      </>
   );
};

export default LinkedinIcon;
