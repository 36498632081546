import React from 'react';
import { HeaderLinks } from '../../../styles/layouts/header';
import SocialMediaLink from './SocialMediaLink';
import {
      FacebookIcon,
      InstagramIcon,
      LinkedinIcon,
      TwitterIcon
} from '../../../icons';

const SocialMedia = () => {
      return (
            <>
                  <HeaderLinks>
                        <SocialMediaLink
                              link="https://www.facebook.com/innmotek"
                              icon={<FacebookIcon />}
                              title="Innmotek Official Facebook page"
                        />
                        <SocialMediaLink
                              link="https://www.instagram.com/innmotek"
                              icon={<InstagramIcon />}
                              title="Innmotek Official Instagram page"
                        />
                        {/* <SocialMediaLink
                              link="www.twitter.com"
                              icon={<TwitterIcon />}
                        /> */}
                        <SocialMediaLink
                              link="https://www.linkedin.com/company/innmotek"
                              icon={<LinkedinIcon />}
                              title="Innmotek Official Linkedin page"
                        />
                  </HeaderLinks>
            </>
      );
};

export default SocialMedia;
