import React, { useEffect, useState } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { Col, Container, Row } from 'react-bootstrap';
import ProductSlider from './components/ProductSlider';
import ProductContent from './components/ProductContent';
import ProductAdditional from './components/ProductAdditional';
import ProductRecommendation from './components/ProductRecommendation';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { CategoryBackground } from '../category/styles';
import { Helmet } from 'react-helmet';

const ProductDetail = () => {
      const { productId } = useParams();
      const [loading, setLoading] = useState(false);
      const [product, setProduct] = useState([]);
      const [products, setProducts] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/products/${productId}/show`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProduct(response.data.product);
                              setProducts(response.data.products);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);
      return (
            <>
                  {!loading ? (
                        <Helmet>
                              <title>
                                    {product.seo_title
                                          ? product.seo_title
                                          : product.title}
                              </title>
                              {product.seo_description && (
                                    <meta
                                          name="description"
                                          content={product.seo_description}
                                    />
                              )}
                              {product.seo_keyword && (
                                    <meta
                                          name="keyword"
                                          content={product.seo_keyword}
                                    />
                              )}
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                        </Helmet>
                  ) : null}
                  <BreadcrumbBox title={product.title} />
                  <CategoryBackground>
                        <Container>
                              <Row className="g-5">
                                    <Col lg={5}>
                                          <ProductSlider
                                                loading={loading}
                                                product={product}
                                          />
                                    </Col>
                                    <Col lg={7}>
                                          <ProductContent
                                                loading={loading}
                                                product={product}
                                          />
                                    </Col>
                              </Row>
                              <ProductAdditional
                                    loading={loading}
                                    product={product}
                              />
                        </Container>
                        <ProductRecommendation
                              loading={loading}
                              products={products}
                        />
                  </CategoryBackground>
            </>
      );
};

export default ProductDetail;
