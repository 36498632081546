import styled from 'styled-components';

export const DehumidifierHeading = styled.div`
      background-image: url(/images/img/dehumidifier/dehumidifier.png);
      padding: 210px;
      background-size: cover;
      background-position: top;
      h4 {
            color: #c7e9ff;
      }
`;

export const DehumidifierContent = styled.div`
      margin-top: -100px;
      h2 {
            color: #D84A27;
      }
`;
