import React from 'react';
import { Link } from 'react-router-dom';
import { CalendarIcon, CategoryIcon, UserIcon } from '../../../icons';
import {
   BlogItem,
   BlogItemAuthor,
   BlogItemButton,
   BlogItemContent,
   BlogItemDetail,
   BlogItemImage,
   BlogItemInfo,
   BlogItemTitle
} from './styles';

const BlogItemBox = ({ blog, height }) => {
   return (
      <>
         <BlogItem>
            <Link to={`/blogs/${blog.slug}`} title={blog.title}>
               <BlogItemImage>
                  <img
                     src={blog.image}
                     alt={blog.title}
                     class="img-fluid rounded-top-2"
                     width="100%"
                     height="100%"
                  />
               </BlogItemImage>
               <BlogItemContent>
                  <BlogItemDetail>
                     {/* <BlogItemAuthor>
                                                <UserIcon />
                                                <span>By Admin</span>
                                          </BlogItemAuthor> */}
                     <BlogItemAuthor>
                        <CategoryIcon />
                        <span>{blog.category}</span>
                     </BlogItemAuthor>
                  </BlogItemDetail>
                  <BlogItemTitle height={height}>
                     <h3>{blog.title}</h3>
                  </BlogItemTitle>
                  <BlogItemInfo>
                     <BlogItemAuthor>
                        <CalendarIcon />
                        <span>{blog.date}</span>
                     </BlogItemAuthor>
                     <BlogItemButton>Read More</BlogItemButton>
                  </BlogItemInfo>
               </BlogItemContent>
            </Link>
         </BlogItem>
      </>
   );
};

export default BlogItemBox;
