import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { ProductSwiper, ProductThumbnail } from './styles';
import { Skeleton } from '@mui/material';

const ProductSlider = ({ loading, product }) => {
      const [thumbsSwiper, setThumbsSwiper] = useState(null);

      return (
            <>
                  {!loading ? (
                        <ProductSwiper
                              style={{
                                    '--swiper-navigation-color': '#fff',
                                    '--swiper-pagination-color': '#fff'
                              }}
                              spaceBetween={10}
                              thumbs={{ swiper: thumbsSwiper }}
                              modules={[FreeMode, Navigation, Thumbs]}
                              className="mySwiper2"
                        >
                              {product?.image && (
                                    <SwiperSlide>
                                          <img
                                                src={product?.image}
                                                alt={product?.title}
                                                className="img-fluid"
                                                width="100%"
                                                height="100%"
                                          />
                                    </SwiperSlide>
                              )}
                              {product?.images?.length > 0 &&
                                    product?.images?.map((item, index) => (
                                          <SwiperSlide key={index}>
                                                <img
                                                      src={item?.image}
                                                      alt={product?.title}
                                                      className="img-fluid"
                                                      width="100%"
                                                      height="100%"
                                                />
                                          </SwiperSlide>
                                    ))}
                        </ProductSwiper>
                  ) : (
                        <>
                              <Skeleton
                                    height={320}
                                    style={{
                                          transform: 'scale(1)'
                                    }}
                              />
                        </>
                  )}
                  {!loading ? (
                        product?.image && (
                              <ProductThumbnail
                                    onSwiper={setThumbsSwiper}
                                    spaceBetween={10}
                                    slidesPerView={4}
                                    freeMode={true}
                                    watchSlidesProgress={true}
                                    modules={[FreeMode, Navigation, Thumbs]}
                              >
                                    {product?.image && (
                                          <SwiperSlide>
                                                <img
                                                      src={product?.image}
                                                      alt={product?.title}
                                                      className="img-fluid"
                                                      width="100%"
                                                      height="100%"
                                                />
                                          </SwiperSlide>
                                    )}
                                    {product?.images?.length > 0 &&
                                          product?.images?.map(
                                                (item, index) => (
                                                      <SwiperSlide key={index}>
                                                            <img
                                                                  src={
                                                                        item?.image
                                                                  }
                                                                  alt={
                                                                        product?.title
                                                                  }
                                                                  className="img-fluid"
                                                                  width="100%"
                                                                  height="100%"
                                                            />
                                                      </SwiperSlide>
                                                )
                                          )}
                              </ProductThumbnail>
                        )
                  ) : (
                        <>
                              <div className="border-1 mt-4">
                                    <Skeleton
                                          height={100}
                                          style={{
                                                transform: 'scale(1)'
                                          }}
                                    />
                              </div>
                        </>
                  )}
            </>
      );
};

export default ProductSlider;
