import React, { useState } from 'react';
import { SolarProjectContainer, SolarProjectWrapper } from './styles';
import ProjectImageSlider from '../../../frontend/projects/ProjectImageSlider';
import { Col, Container, Row } from 'react-bootstrap';
import CategoryContactBox from '../../../../frontend/PoolSpaCover/CategoryContact';
import {
   ProjectSwiper,
   ThumbnailSwiper
} from '../../../../frontend/projectDetail/styles';

import { SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { solarProjectImages } from '../../../../data/solarList';
import { SolarBenefitTitle } from '../SolarBenefits/styles';

const SolarProject = () => {
   const [thumbsSwiper, setThumbsSwiper] = useState(null);
   return (
      <>
         <SolarProjectContainer>
            <Container>
               <SolarBenefitTitle className="mb-3">
                  Solar Projects
               </SolarBenefitTitle>
               <Row>
                  <Col lg={7}>
                     <ProjectSwiper
                        style={{
                           '--swiper-navigation-color': '#fff',
                           '--swiper-pagination-color': '#fff'
                        }}
                        spaceBetween={10}
                        navigation={false}
                        thumbs={{
                           swiper: thumbsSwiper
                        }}
                        modules={[FreeMode, Navigation, Thumbs]}
                        className="mySwiper2"
                     >
                        {solarProjectImages?.length > 0 &&
                           solarProjectImages?.map((item, index) => (
                              <SwiperSlide key={index}>
                                 <img src={item.image} alt={item.title} />
                              </SwiperSlide>
                           ))}
                     </ProjectSwiper>
                  </Col>
                  <Col lg={5}>
                     <CategoryContactBox />
                     <SolarProjectWrapper>
                        <ThumbnailSwiper
                           onSwiper={setThumbsSwiper}
                           spaceBetween={20}
                           slidesPerView={2}
                           freeMode={true}
                           watchSlidesProgress={true}
                           modules={[FreeMode, Navigation, Thumbs]}
                           className="mySwiper"
                        >
                           {solarProjectImages?.length > 0 &&
                              solarProjectImages?.map((item, index) => (
                                 <SwiperSlide key={index}>
                                    <img src={item.image} alt={item.title} />
                                 </SwiperSlide>
                              ))}
                        </ThumbnailSwiper>
                     </SolarProjectWrapper>
                  </Col>
               </Row>
            </Container>
         </SolarProjectContainer>
      </>
   );
};

export default SolarProject;
