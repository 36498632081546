import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ProductAdditionalContainer } from './styles';
import TitleSkeleton from '../../../../components/skeletons/TitleSkeleton';

const ProductAdditional = ({ loading, product }) => {
      return (
            <>
                  <ProductAdditionalContainer>
                        <Row>
                              <Col lg={12}>
                                    <div className="product__additional ">
                                          <ul
                                                class="nav nav-tabs"
                                                id="myTab"
                                                role="tablist"
                                          >
                                                <li
                                                      class="nav-item"
                                                      role="presentation"
                                                >
                                                      <button
                                                            class="nav-link active"
                                                            id="home-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#home-tab-pane"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="home-tab-pane"
                                                            aria-selected="true"
                                                      >
                                                            Description
                                                      </button>
                                                </li>
                                                <li
                                                      class="nav-item"
                                                      role="presentation"
                                                >
                                                      <button
                                                            class="nav-link"
                                                            id="profile-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#profile-tab-pane"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="profile-tab-pane"
                                                            aria-selected="false"
                                                      >
                                                            Specification
                                                      </button>
                                                </li>
                                                <li
                                                      class="nav-item"
                                                      role="presentation"
                                                >
                                                      <button
                                                            class="nav-link"
                                                            id="installation-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#installation-tab-pane"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="installation-tab-pane"
                                                            aria-selected="false"
                                                      >
                                                            Installation
                                                      </button>
                                                </li>
                                                <li
                                                      class="nav-item"
                                                      role="presentation"
                                                >
                                                      <button
                                                            class="nav-link"
                                                            id="warrenty-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#warrenty-tab-pane"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="warrenty-tab-pane"
                                                            aria-selected="false"
                                                      >
                                                            Warrenty
                                                      </button>
                                                </li>
                                                <li
                                                      class="nav-item"
                                                      role="presentation"
                                                >
                                                      <button
                                                            class="nav-link"
                                                            id="download-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#download-tab-pane"
                                                            type="button"
                                                            role="tab"
                                                            aria-controls="download-tab-pane"
                                                            aria-selected="false"
                                                      >
                                                            Download
                                                      </button>
                                                </li>
                                          </ul>
                                          <div
                                                class="tab-content"
                                                id="myTabContent"
                                          >
                                                <div
                                                      class="tab-pane fade show active"
                                                      id="home-tab-pane"
                                                      role="tabpanel"
                                                      aria-labelledby="home-tab"
                                                      tabindex="0"
                                                >
                                                      {!loading ? (
                                                            <div
                                                                  className="product__desc"
                                                                  dangerouslySetInnerHTML={{
                                                                        __html: product.description
                                                                  }}
                                                            ></div>
                                                      ) : (
                                                            <>
                                                                  <TitleSkeleton
                                                                        height={
                                                                              30
                                                                        }
                                                                  />
                                                                  <TitleSkeleton
                                                                        height={
                                                                              30
                                                                        }
                                                                  />
                                                                  <TitleSkeleton
                                                                        height={
                                                                              30
                                                                        }
                                                                  />
                                                                  <TitleSkeleton
                                                                        height={
                                                                              30
                                                                        }
                                                                  />
                                                            </>
                                                      )}
                                                </div>
                                                <div
                                                      class="tab-pane fade"
                                                      id="profile-tab-pane"
                                                      role="tabpanel"
                                                      aria-labelledby="profile-tab"
                                                      tabindex="0"
                                                >
                                                      {!loading ? (
                                                            <div
                                                                  className="product__desc"
                                                                  dangerouslySetInnerHTML={{
                                                                        __html: product.specification
                                                                  }}
                                                            ></div>
                                                      ) : (
                                                            <>
                                                                  <TitleSkeleton
                                                                        height={
                                                                              30
                                                                        }
                                                                  />
                                                                  <TitleSkeleton
                                                                        height={
                                                                              30
                                                                        }
                                                                  />
                                                                  <TitleSkeleton
                                                                        height={
                                                                              30
                                                                        }
                                                                  />
                                                                  <TitleSkeleton
                                                                        height={
                                                                              30
                                                                        }
                                                                  />
                                                            </>
                                                      )}
                                                </div>
                                                <div
                                                      class="tab-pane fade"
                                                      id="installation-tab-pane"
                                                      role="tabpanel"
                                                      aria-labelledby="installation-tab"
                                                      tabindex="0"
                                                >
                                                      {!loading ? (
                                                            <div
                                                                  className="product__desc"
                                                                  dangerouslySetInnerHTML={{
                                                                        __html: product.installation
                                                                  }}
                                                            ></div>
                                                      ) : (
                                                            <>
                                                                  <TitleSkeleton
                                                                        height={
                                                                              30
                                                                        }
                                                                  />
                                                                  <TitleSkeleton
                                                                        height={
                                                                              30
                                                                        }
                                                                  />
                                                                  <TitleSkeleton
                                                                        height={
                                                                              30
                                                                        }
                                                                  />
                                                                  <TitleSkeleton
                                                                        height={
                                                                              30
                                                                        }
                                                                  />
                                                            </>
                                                      )}
                                                </div>
                                                <div
                                                      class="tab-pane fade"
                                                      id="warrenty-tab-pane"
                                                      role="tabpanel"
                                                      aria-labelledby="warrenty-tab"
                                                      tabindex="0"
                                                >
                                                      {!loading ? (
                                                            <div
                                                                  className="product__desc"
                                                                  dangerouslySetInnerHTML={{
                                                                        __html: product.warrenty
                                                                  }}
                                                            ></div>
                                                      ) : (
                                                            <>
                                                                  <TitleSkeleton
                                                                        height={
                                                                              30
                                                                        }
                                                                  />
                                                                  <TitleSkeleton
                                                                        height={
                                                                              30
                                                                        }
                                                                  />
                                                                  <TitleSkeleton
                                                                        height={
                                                                              30
                                                                        }
                                                                  />
                                                                  <TitleSkeleton
                                                                        height={
                                                                              30
                                                                        }
                                                                  />
                                                            </>
                                                      )}
                                                </div>
                                                <div
                                                      class="tab-pane fade"
                                                      id="download-tab-pane"
                                                      role="tabpanel"
                                                      aria-labelledby="download-tab"
                                                      tabindex="0"
                                                >
                                                      {!loading ? (
                                                            <div
                                                                  className="product__desc"
                                                                  dangerouslySetInnerHTML={{
                                                                        __html: product.download
                                                                  }}
                                                            ></div>
                                                      ) : (
                                                            <>
                                                                  <TitleSkeleton
                                                                        height={
                                                                              30
                                                                        }
                                                                  />
                                                                  <TitleSkeleton
                                                                        height={
                                                                              30
                                                                        }
                                                                  />
                                                                  <TitleSkeleton
                                                                        height={
                                                                              30
                                                                        }
                                                                  />
                                                                  <TitleSkeleton
                                                                        height={
                                                                              30
                                                                        }
                                                                  />
                                                            </>
                                                      )}
                                                </div>
                                          </div>
                                    </div>
                              </Col>
                        </Row>
                  </ProductAdditionalContainer>
            </>
      );
};
export default ProductAdditional;
