import React, { useEffect, useState } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { Col, Container, Row } from 'react-bootstrap';
import { ServiceContent, ServiceImage, ServiceTitle } from './styles';
import { CategoryBackground } from '../category/styles';
import { SubscriberContainer } from '../../components/common/SubscribeBox/styles';
import SubscribeBox from '../../components/common/SubscribeBox';
import { ProjectDescription } from '../projectDetail/styles';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';

const ServiceDetail = () => {
      const { serviceId } = useParams();
      const [loading, setLoading] = useState(false);
      const [service, setService] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/services/${serviceId}`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setService(response.data.service);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <BreadcrumbBox title={service.title} />
                  <CategoryBackground>
                        <Container>
                              <Row>
                                    <Col lg={8}>
                                          <ProjectDescription className="mt-0">
                                                <ServiceContent>
                                                      <ServiceTitle>
                                                            <h2>
                                                                  {!loading ? (
                                                                        service?.title
                                                                  ) : (
                                                                        <Skeleton
                                                                              height={
                                                                                    40
                                                                              }
                                                                              width={
                                                                                    180
                                                                              }
                                                                        />
                                                                  )}
                                                            </h2>
                                                      </ServiceTitle>
                                                      {!loading ? (
                                                            <div
                                                                  dangerouslySetInnerHTML={{
                                                                        __html: service.summary
                                                                  }}
                                                            ></div>
                                                      ) : (
                                                            <>
                                                                  <Skeleton
                                                                        height={
                                                                              20
                                                                        }
                                                                  />
                                                                  <Skeleton
                                                                        height={
                                                                              20
                                                                        }
                                                                  />
                                                                  <Skeleton
                                                                        height={
                                                                              20
                                                                        }
                                                                        width={
                                                                              260
                                                                        }
                                                                  />
                                                            </>
                                                      )}
                                                      <ServiceImage>
                                                            {!loading ? (
                                                                  <img
                                                                        src={
                                                                              service.image
                                                                        }
                                                                        alt={
                                                                              service.title
                                                                        }
                                                                  />
                                                            ) : (
                                                                  <Skeleton
                                                                        height="476px"
                                                                        className="mt-3"
                                                                        style={{
                                                                              transform:
                                                                                    'scale(1)'
                                                                        }}
                                                                  />
                                                            )}
                                                      </ServiceImage>
                                                      {!loading ? (
                                                            <div
                                                                  dangerouslySetInnerHTML={{
                                                                        __html: service.description
                                                                  }}
                                                            ></div>
                                                      ) : (
                                                            <>
                                                                  <Skeleton
                                                                        height={
                                                                              20
                                                                        }
                                                                  />
                                                                  <Skeleton
                                                                        height={
                                                                              20
                                                                        }
                                                                  />
                                                                  <Skeleton
                                                                        height={
                                                                              20
                                                                        }
                                                                  />
                                                                  <Skeleton
                                                                        height={
                                                                              20
                                                                        }
                                                                  />
                                                                  <Skeleton
                                                                        height={
                                                                              20
                                                                        }
                                                                  />
                                                                  <Skeleton
                                                                        height={
                                                                              20
                                                                        }
                                                                  />
                                                                  <Skeleton
                                                                        height={
                                                                              20
                                                                        }
                                                                        width={
                                                                              260
                                                                        }
                                                                  />
                                                            </>
                                                      )}
                                                </ServiceContent>
                                          </ProjectDescription>
                                    </Col>
                                    <Col lg={4}>
                                          <SubscribeBox margin="0rem" />
                                    </Col>
                              </Row>
                        </Container>
                  </CategoryBackground>
            </>
      );
};

export default ServiceDetail;
