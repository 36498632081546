import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const StainlessPanelTankContainer = styled(Container)``;
export const StainlessPanelTankImage = styled.div``;

export const StainlessPanelTankFeatures = styled.div`
      background: #f5f5f5;
`;
export const StainlessPanelTankFaq = styled.div`
      background: #f5f5f5;
`;
