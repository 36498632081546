import React from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import CategoryContactBox from '../CategoryContact';

const PoolSpaAccordion = ({title}) => {
      return (
            <div className="mt-5 pb-5">
                  <Row>
                        <h3 className="w-100 text-center mb-4">
                              {title}
                        </h3>
                        <Col lg={7}>
                              <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                          <Accordion.Header>
                                                What is the difference between
                                                ThermoTech & Bubble?
                                          </Accordion.Header>
                                          <Accordion.Body>
                                                Both insulate and stop
                                                evaporation however ThermoTech
                                                has a slightly higher insulation
                                                rate compared to bubble.
                                          </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                          <Accordion.Header>
                                                What's the difference between a
                                                Pool Cover and Pool Blanket?
                                          </Accordion.Header>
                                          <Accordion.Body>
                                                Nothing.
                                          </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                          <Accordion.Header>
                                                Does water really evaporate
                                                overnight?
                                          </Accordion.Header>
                                          <Accordion.Body>
                                                Yes, however strong winds can
                                                cause more evaporation than the
                                                sun
                                          </Accordion.Body>
                                    </Accordion.Item>
                              </Accordion>
                        </Col>
                        <Col>
                              <CategoryContactBox />
                        </Col>
                  </Row>
            </div>
      );
};

export default PoolSpaAccordion;
