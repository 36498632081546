import React from 'react';
import { Row } from 'react-bootstrap';
import {
      FooterBody,
      FooterContact,
      FooterContainer,
      FooterContent,
      FooterCredential,
      FooterCredentialContainer,
      FooterHeading,
      FooterItem,
      FooterLogoContainer
} from '../../../styles/layouts/footer';
import FooterMenuItem from './FooterMenuItem';
import { LocationIcon, PhoneIcon, MailIcon } from '../../../icons';
import SocialMedia from '../../../components/common/SocialMedia';
import { Link } from 'react-router-dom';

const Footer = () => {
      return (
            <>
                  <FooterContainer>
                        <FooterBody>
                              <Row className="g-5">
                                    <FooterItem lg={4}>
                                          <FooterHeading>
                                                <FooterLogoContainer className="d-flex gap-4 align-items-center mb-4">
                                                      <Link to={'/'}>
                                                            <h3>
                                                                  <img
                                                                        src="/images/png/Innmotek White@2x 1.png"
                                                                        alt="Innmotek white logo"
                                                                        width="100%"
                                                                        height="100%"
                                                                  />
                                                            </h3>
                                                      </Link>
                                                      {/* <FooterParent>
                                                            <img
                                                                  src="/images/png/kswhite.png"
                                                                  alt="Innmotek white logo"
                                                                  width="100%"
                                                                  height="100%"
                                                            />
                                                      </FooterParent> */}
                                                </FooterLogoContainer>
                                          </FooterHeading>
                                          <FooterContent marginBottom={true}>
                                                <p>
                                                      Innmotek offers
                                                      top-quality Hot Water and
                                                      Pool Heat Pumps, along
                                                      with accessories, serving
                                                      residential, commercial,
                                                      and industrial sectors.
                                                </p>
                                          </FooterContent>
                                          <SocialMedia />
                                    </FooterItem>
                                    <FooterItem>
                                          <FooterHeading>
                                                <h4>Quick Links</h4>
                                          </FooterHeading>
                                          <FooterContent>
                                                <ul>
                                                      <FooterMenuItem
                                                            link="/faqs"
                                                            title="FAQs"
                                                      />
                                                      <FooterMenuItem
                                                            link="/blogs"
                                                            title="Blogs and News"
                                                      />
                                                      <FooterMenuItem
                                                            link="/company-policy"
                                                            title="Company Policy"
                                                      />
                                                      <FooterMenuItem
                                                            link="/projects"
                                                            title="Projects"
                                                      />
                                                </ul>
                                          </FooterContent>
                                    </FooterItem>
                                    <FooterItem>
                                          <FooterHeading>
                                                <h4>Our Products</h4>
                                          </FooterHeading>
                                          <FooterContent>
                                                <ul>
                                                      <FooterMenuItem
                                                            title="Heat Pump"
                                                            link="/category/hot-water-heat-pump"
                                                      />
                                                      {/* <FooterMenuItem title="Heaters" /> */}
                                                      <FooterMenuItem
                                                            title="Pool & Spa"
                                                            link="/category/pool-and-spa"
                                                      />
                                                      <FooterMenuItem
                                                            title="Hot Storage Water Tank"
                                                            link="/category/hot-water-storage-tanks"
                                                      />
                                                      <FooterMenuItem
                                                            title="Solar Water Heaters"
                                                            link="/category/solar-water-heaters"
                                                      />
                                                </ul>
                                          </FooterContent>
                                    </FooterItem>
                                    <FooterItem className="mobile__footer">
                                          <FooterHeading>
                                                <h4>Contact Us</h4>
                                          </FooterHeading>
                                          <FooterContent>
                                                <FooterContact>
                                                      <LocationIcon />
                                                      <span>
                                                            Uttar Pradesh,
                                                            India, 273001
                                                      </span>
                                                </FooterContact>
                                                <FooterContact>
                                                      <PhoneIcon />
                                                      <Link to="tel:919821213350">
                                                            +91 98212 13350
                                                      </Link>
                                                </FooterContact>
                                                <FooterContact>
                                                      <MailIcon />
                                                      <Link to="mailto:info@innmotek.com">
                                                            info@innmotek.com
                                                      </Link>
                                                </FooterContact>
                                          </FooterContent>
                                    </FooterItem>
                              </Row>
                        </FooterBody>
                        <FooterCredential>
                              <FooterCredentialContainer>
                                    <div class="footer__copyright">
                                          Copyright ©2024 by{' '}
                                          <span>Innmotek Pvt. Ltd.</span>
                                    </div>
                                    <div class="footer__credit">
                                          Powered by:{' '}
                                          <Link
                                                to="https://infinityinfosys.com"
                                                target="_blank"
                                          >
                                                Infinity Infosys
                                          </Link>
                                    </div>
                              </FooterCredentialContainer>
                        </FooterCredential>
                  </FooterContainer>
            </>
      );
};

export default Footer;
