import React, { useMemo, useState } from 'react';
import {
      FaqAccordionContent,
      FaqAccordionItem,
      FaqAccordionTitle
} from './styles';
import { Accordion } from 'react-bootstrap';
import axios from 'axios';
import { error } from 'jquery';
import { useEffect } from 'react';
// ... (previous code)

const FaqAccordion = () => {
      const [loading, setLoading] = useState(false);
      const [types, setTypes] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/faqs`)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setTypes(response.data.types);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);
      return (
            <>
                  {!loading
                        ? types?.length > 0
                              ? types?.map((type, index) => (
                                      <FaqAccordionItem key={index}>
                                            <FaqAccordionTitle
                                                  color={(props) =>
                                                        props.theme.primary
                                                  }
                                            >
                                                  {type.title}
                                            </FaqAccordionTitle>
                                            {type?.faqs?.length > 0 ? (
                                                  <FaqAccordionContent flush>
                                                        {type?.faqs?.map(
                                                              (item, key) => (
                                                                    <Accordion.Item
                                                                          eventKey={`${key}`}
                                                                    >
                                                                          <Accordion.Header>
                                                                                {
                                                                                      item.question
                                                                                }
                                                                          </Accordion.Header>
                                                                          <Accordion.Body>
                                                                                {
                                                                                      item.answer
                                                                                }
                                                                          </Accordion.Body>
                                                                    </Accordion.Item>
                                                              )
                                                        )}
                                                  </FaqAccordionContent>
                                            ) : null}
                                      </FaqAccordionItem>
                                ))
                              : null
                        : null}
            </>
      );
};

export default FaqAccordion;
