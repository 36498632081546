import React from 'react';

const WifiIcon = () => {
      return (
            <>
                  <svg
                        height="24"
                        viewBox="0 0 24 24"
                        width="24"
                        xmlns="http://www.w3.org/2000/svg"
                        id="fi_11530392"
                  >
                        <g>
                              <path d="m21.4841 10.027c-5.0345-4.77114-12.78606-5.02226-18.10593-.75253-.29509.23686-.58281.48769-.86227.75253-.30065.2849-.77535.2722-1.06028-.02847-.28492-.30065-.27217-.77536.02848-1.06028.30934-.29316.62805-.57103.95513-.83357 5.89983-4.73522 14.49327-4.45767 20.07667.83357.3006.28492.3134.75962.0285 1.06027-.2849.30068-.7597.31338-1.0603.02848z"></path>
                              <path d="m4.46967 12.3691c4.15889-4.15885 10.90173-4.15885 15.06063 0 .2929.2929.2929.7678 0 1.0607s-.7677.2929-1.0606 0c-3.5731-3.5731-9.36627-3.5731-12.93937 0-.2929.2929-.76777.2929-1.06066 0-.2929-.2929-.2929-.7678 0-1.0607z"></path>
                              <path d="m7.46967 15.6265c2.50204-2.502 6.55863-2.502 9.06063 0 .2929.2929.2929.7678 0 1.0607s-.7677.2929-1.0606 0c-1.9163-1.9163-5.0231-1.9163-6.93937 0-.2929.2929-.76777.2929-1.06066 0-.2929-.2929-.2929-.7678 0-1.0607z"></path>
                              <path d="m12 20c.6903 0 1.25-.5597 1.25-1.25 0-.6904-.5597-1.25-1.25-1.25-.6904 0-1.25.5596-1.25 1.25 0 .6903.5596 1.25 1.25 1.25z"></path>
                        </g>
                  </svg>
            </>
      );
};

export default WifiIcon;
