import styled from 'styled-components';

export const SolarBannerSection = styled.section`
   background: url(images/back.png);
   background-size: cover;
   background-position: top center;
   position: relative;
   padding: 118px 0px;
   video {
      position: absolute;
      left: 0;
      top: 0;
      height: 338px;
   }

   &:after {
      position: absolute;
      top: 0;
      left: 0;
      background: #000000c4;
      content: '';
      width: 100%;
      height: 100%;
      z-index: 9;
   }
`;
export const SolarBannerContent = styled.div`
   position: relative;
   z-index: 10;
   font-size: 23px;
   color: ${(props) => props.theme.white};
   span {
      color: #3e78d5;
   }
`;
