import styled from 'styled-components';

export const CategoryContactContainer = styled.div`
      background: ${(props) => (props.color ? props.color : '#fff')};
      padding: 40px 30px;
      p{
       font-size: 15px;
       color: #f4f4f4;
      }
      .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    color: var(--bs-btn-active-color);
    background-color:transparent;
    border-color:transparent;
}
      button {
            padding: 10px;20px;
            font-size: 14px;
            background: #fff;
            border-color: #fff;
            color: #011E65;
            &:hover{
             background: linear-gradient(to left,#0e95b3 1%,#00145e);
             border: 1px solid #fff;
            }
      }
      .modal-backdrop{
       position: relative;
       z-index: 10000000;

      }
      .modal{
       position: relative;
       z-index: 100000000;
      }
`;
