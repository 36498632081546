import React from 'react';
import RecommendedProduct from '../../../../components/frontend/products/RecommendedProduct';

const ProductRecommendation = ({ products, loading }) => {
      return (
            <>
                  <div style={{ backgroundColor: '#EEEEEE' }} className="mt-5">
                        <RecommendedProduct
                              title="Related Products"
                              loading={loading}
                              products={products}
                              padding={true}
                        />
                  </div>
            </>
      );
};

export default ProductRecommendation;
