import styled from 'styled-components';

export const SolarThermoIntroContainer = styled.div`
   grid-column-gap: 1px;
   grid-row-gap: 1px;
   background: ${(props) => props.theme.primary};
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   position: relative;
   overflow: hidden;
   &:after {
      background: #ffffff;
      clip-path: polygon(49% 0, 50% 0, 65% 100%, 64% 100%);
      content: '';
      height: 101%;
      width: 100%;
      position: absolute;
      top: 0px;
      left: 3px;
      z-index: 100;
   }
`;
export const SolarThermoIntroContent = styled.div`
   background: ${(props) => props.theme.primary};
   color: ${(props) => props.theme.white};
   padding-left: 110px;
   position: relative;
   top: 50%;
   transform: translateY(-30%);
   z-index: 10;
`;
export const SolarThermoIntroImage = styled.div`
   clip-path: polygon(0 0, 100% 0%, 100% 100%, 30% 100%);
`;
