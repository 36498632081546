import axios from 'axios';
import React, { memo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useParams } from 'react-router-dom';
import BreadcrumbBox from '../../../components/common/BreadcrumbBox';
import { Col, Row } from 'react-bootstrap';
import {
   BlogCategory,
   BlogDetailContainer,
   BlogDetailContent,
   BlogDetailDescription,
   BlogDetailImage,
   BlogDetailTitle,
   BlogExtra,
   BlogExtraItem,
   RealtedBlogItem,
   RealtedBlogItemTitle,
   RealtedCategoryItem,
   RelatedBlogContent
} from './styles';
import RelatedBlogSkeleton from '../../../components/skeletons/RelatedBlogSkeleton';
import TitleSkeleton from '../../../components/skeletons/TitleSkeleton';
import { Skeleton } from '@mui/material';
import { Helmet } from 'react-helmet';

const BlogDetail = memo(() => {
   const { blogId } = useParams();
   const [blog, setBlog] = useState([]);
   const [blogs, setBlogs] = useState([]);
   const [categories, setCategories] = useState([]);
   const [loading, setLoading] = useState(false);

   const loadData = async () => {
      setLoading(true);
      await axios
         .get(`${process.env.REACT_APP_SECRET_KEY}/api/blogs/${blogId}`)
         .then((response) => {
            if (response.data.result === 'success') {
               setBlog(response.data.blog);
               setCategories(response.data.categories);
               setBlogs(response.data.blogs);
            }
         })
         .catch((error) => {
            toast.error(error.message);
         });
      setLoading(false);
   };

   useEffect(() => {
      loadData();
   }, []);

      return (
            <>
                  <Helmet>
                        <title>
                              {blog.seo_title ? blog.seo_title : blog.title}
                        </title>
                        {blog.seo_description && (
                              <meta
                                    name="description"
                                    content={blog.seo_description}
                              />
                        )}
                        {blog.seo_keyword && (
                              <meta name="keyword" content={blog.seo_keyword} />
                        )}
                        <link rel="canonical" href={window.location.href} />
                  </Helmet>
                  <BreadcrumbBox
                        link={`/blogs/categories/${blog.category_slug}`}
                        linkTitle={blog.category}
                        title={blog.title}
                  >
                        <li class="breadcrumb-item">
                              <Link to="/blogs">Blogs</Link>
                        </li>
                  </BreadcrumbBox>

                  <BlogDetailContainer>
                        <Row>
                              <Col lg={8}>
                                    <BlogDetailContent>
                                          {!loading ? (
                                                <>
                                                      <BlogDetailImage>
                                                            <img
                                                                  src={
                                                                        blog.image
                                                                  }
                                                                  alt={
                                                                        blog.title
                                                                  }
                                                                  width="100%"
                                                                  height="100%"
                                                            />
                                                            <BlogCategory>
                                                                  {
                                                                        blog.category
                                                                  }
                                                            </BlogCategory>
                                                      </BlogDetailImage>
                                                </>
                                          ) : (
                                                <Skeleton
                                                      height="473px"
                                                      style={{
                                                            transform:
                                                                  'scale(1)'
                                                      }}
                                                />
                                          )}

                                          <BlogExtra>
                                                {!loading ? (
                                                      <>
                                                      
                                                            <BlogExtraItem>
                                                                  <i className="bx bx-calendar"></i>
                                                                  <div>
                                                                        {
                                                                              blog.date
                                                                        }
                                                                  </div>
                                                            </BlogExtraItem>
                                                      </>
                                                ) : (
                                                      <>
                                                            <div className="d-flex gap-4">
                                                                  <Skeleton
                                                                        height={
                                                                              40
                                                                        }
                                                                        width={
                                                                              120
                                                                        }
                                                                  />
                                                                  <Skeleton
                                                                        height={
                                                                              40
                                                                        }
                                                                        width={
                                                                              120
                                                                        }
                                                                  />
                                                            </div>
                                                      </>
                                                )}
                                          </BlogExtra>
                                          <BlogDetailDescription>
                                                {!loading ? (
                                                      <div
                                                            dangerouslySetInnerHTML={{
                                                                  __html: blog.description
                                                            }}
                                                      ></div>
                                                ) : (
                                                      <>
                                                            <TitleSkeleton
                                                                  height={30}
                                                            />
                                                            <TitleSkeleton
                                                                  height={30}
                                                            />
                                                            <TitleSkeleton
                                                                  height={30}
                                                            />
                                                            <TitleSkeleton
                                                                  height={30}
                                                            />
                                                      </>
                                                )}
                                          </BlogDetailDescription>
                                    </BlogDetailContent>
                              </Col>
                              <Col lg={4}>
                                    <BlogDetailContent>
                                          <BlogDetailTitle>
                                                Categories
                                          </BlogDetailTitle>
                                          <RelatedBlogContent>
                                                {!loading ? (
                                                      categories?.length > 0 ? (
                                                            categories?.map(
                                                                  (
                                                                        category,
                                                                        index
                                                                  ) => (
                                                                        <RealtedCategoryItem
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <RealtedBlogItemTitle
                                                                                    to={`/blogs/categories/${category.slug}`}
                                                                              >
                                                                                    <div className="category__item">
                                                                                          {
                                                                                                category.title
                                                                                          }
                                                                                    </div>
                                                                              </RealtedBlogItemTitle>
                                                                        </RealtedCategoryItem>
                                                                  )
                                                            )
                                                      ) : null
                                                ) : (
                                                      <>
                                                            <TitleSkeleton
                                                                  height={30}
                                                            />
                                                            <TitleSkeleton
                                                                  height={30}
                                                            />
                                                      </>
                                                )}
                                          </RelatedBlogContent>
                                    </BlogDetailContent>
                                    <BlogDetailContent>
                                          <BlogDetailTitle>
                                                Related Feeds
                                          </BlogDetailTitle>
                                          <RelatedBlogContent>
                                                {!loading ? (
                                                      blogs?.length > 0 ? (
                                                            blogs?.map(
                                                                  (
                                                                        item,
                                                                        index
                                                                  ) => (
                                                                        <RealtedBlogItem
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <Link
                                                                                    to={`/blogs/${blog.slug}`}
                                                                              >
                                                                                    <img
                                                                                          src={
                                                                                                item.image
                                                                                          }
                                                                                          alt={
                                                                                                item.title
                                                                                          }
                                                                                          width="100%"
                                                                                          height="100%"
                                                                                    />
                                                                              </Link>

                                    <RealtedBlogItemTitle
                                       to={`/blogs/${blog.slug}`}
                                    >
                                       <div>{item.title}</div>
                                       <BlogExtraItem color={true}>
                                          <i className="bx bx-calendar"></i>
                                          <div>{item.date}</div>
                                       </BlogExtraItem>
                                    </RealtedBlogItemTitle>
                                 </RealtedBlogItem>
                              ))
                           ) : null
                        ) : (
                           <>
                              <RelatedBlogSkeleton />
                              <RelatedBlogSkeleton />
                           </>
                        )}
                     </RelatedBlogContent>
                  </BlogDetailContent>
               </Col>
            </Row>
         </BlogDetailContainer>
      </>
   );
});

export default BlogDetail;
