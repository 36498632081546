import React, { memo } from 'react';
import { ButtonBox } from './styles';

const FormButton = memo(({ title, loading }) => {
      return (
            <>
                  <ButtonBox>
                        <span>{title}</span>
                  </ButtonBox>
            </>
      );
});

export default FormButton;
