import React from 'react';
import {
      ApplicableMaterialContent,
      ApplicableMaterialImage,
      ApplicableMaterialSector,
      ApplicableMaterialTitle
} from '../../../../frontend/CategoryDetail/style';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { RecommendedItem } from './style';

const RecommendedProduct = ({ loading, products, title, padding }) => {
      return (
            <>
                  <Container>
                        {!loading ? (
                              products?.length > 0 ? (
                                    <ApplicableMaterialSector
                                          className={!padding && 'pb-5'}
                                    >
                                          <h2 className="mb-4">{title}</h2>
                                          <Swiper
                                                slidesPerView={1}
                                                spaceBetween={10}
                                                pagination={{
                                                      clickable: true
                                                }}
                                                autoplay={{
                                                      delay: 2500,
                                                      disableOnInteraction: false
                                                }}
                                                modules={[Autoplay]}
                                                breakpoints={{
                                                      640: {
                                                            slidesPerView: 2,
                                                            spaceBetween: 20
                                                      },
                                                      768: {
                                                            slidesPerView: 4,
                                                            spaceBetween: 40
                                                      },
                                                      1024: {
                                                            slidesPerView: 4,
                                                            spaceBetween: 30
                                                      }
                                                }}
                                                className="mySwiper"
                                          >
                                                {products?.map(
                                                      (item, index) => (
                                                            <SwiperSlide
                                                                  key={index}
                                                            >
                                                                  <RecommendedItem>
                                                                        <Link
                                                                              to={`/products/${item.slug}`}
                                                                        >
                                                                              <ApplicableMaterialImage
                                                                                    src={
                                                                                          item.image
                                                                                    }
                                                                                    className="img-fluid"
                                                                                    alt={
                                                                                          item.title
                                                                                    }
                                                                                    width="100%"
                                                                                    height="100%"
                                                                              />
                                                                        </Link>
                                                                        <Link
                                                                              to={`/products/${item.slug}`}
                                                                        >
                                                                              <ApplicableMaterialTitle
                                                                                    className="text-center mt-3 pb-3 px-1"
                                                                                    height="52px"
                                                                              >
                                                                                    {
                                                                                          item.title
                                                                                    }
                                                                              </ApplicableMaterialTitle>
                                                                        </Link>
                                                                  </RecommendedItem>
                                                            </SwiperSlide>
                                                      )
                                                )}
                                          </Swiper>
                                    </ApplicableMaterialSector>
                              ) : null
                        ) : null}
                  </Container>
            </>
      );
};

export default RecommendedProduct;
