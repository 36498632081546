import { Skeleton } from '@mui/material';
import React from 'react';

const TitleSkeleton = ({ height }) => {
      return (
            <>
                  <Skeleton
                        animation="wave"
                        variant="text"
                        height={height ? height : 12}
                  />
            </>
      );
};

export default TitleSkeleton;
