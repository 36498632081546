import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Autoplay } from 'swiper/modules';
import {
      ApplicableMaterialContent,
      ApplicableMaterialImage,
      ApplicableMaterialSector,
      ApplicableMaterialTitle
} from '../style';
import { applicable } from '../../../data/applicableMaterialList';
const ApplicableMaterial = () => {
      return (
            <>
                  <ApplicableMaterialSector>
                        <h4 className="mb-4">Applicable Material</h4>
                        <Swiper
                              slidesPerView={1}
                              spaceBetween={10}
                              pagination={{
                                    clickable: true
                              }}
                              autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false
                              }}
                              modules={[Autoplay]}
                              breakpoints={{
                                    640: {
                                          slidesPerView: 2,
                                          spaceBetween: 20
                                    },
                                    768: {
                                          slidesPerView: 4,
                                          spaceBetween: 40
                                    },
                                    1024: {
                                          slidesPerView: 5,
                                          spaceBetween: 40
                                    }
                              }}
                              className="mySwiper"
                        >
                              {applicable?.map((apply, index) => (
                                    <SwiperSlide key={index}>
                                          <ApplicableMaterialContent>
                                                <ApplicableMaterialImage
                                                      src={apply.image}
                                                      className="img-fluid"
                                                />
                                                <ApplicableMaterialTitle className="text-center mt-3">
                                                      {apply.title}
                                                </ApplicableMaterialTitle>
                                          </ApplicableMaterialContent>
                                    </SwiperSlide>
                              ))}
                        </Swiper>
                  </ApplicableMaterialSector>
            </>
      );
};

export default ApplicableMaterial;
