import styled from 'styled-components';
export const ProductContentTitle = styled.h2``;
export const ProductRatingTop = styled.div``;
export const ProductRatingTopIcon = styled.div``;
export const ProductRatingTopInfo = styled.div``;
export const ProductContentPrice = styled.div``;
export const ProductAction = styled.div``;
export const ProductWishList = styled.button`
      i {
            font-size: 24px;
      }
`;
export const ProductMeta = styled.div`
      ul li {
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: 300;
            color: #1b1b1b;
      }
      ul li span {
            color: #1c1c1c;
      }
      .share {
            .facebook {
                  &:hover {
                        color: #0866ff;
                  }
            }
            .twitter {
                  &:hover {
                        color: blue;
                  }
            }
            .pinterest {
                  &:hover {
                        color: red;
                  }
            }
            a {
                  color: #666666;
                  font-size: 24px;
            }
      }
`;
export const ProductActionContainer = styled.div.attrs(() => ({
      className: 'd-flex gap-4 align-items-center'
}))``;
export const ProductCart = styled.div``;
export const ProductInquiry = styled.div``;
export const ProductQuantity = styled.div`
      display: inline-block;
`;
export const InputCommonStyle = styled.div`
      cursor: pointer;
      padding: 7px 10px;
`;
export const InputSpinner = styled.div`
      border: 1px solid #a2aba9;
      i {
            font-size: 12px;
            color: #a2aba9;
      }
`;
export const Subtract = styled(InputCommonStyle)`
      border-right: 1px solid #a2aba9;
`;
export const Quantity = styled.div`
      padding: 7px 20px;
      color: #525252;
      font-size: 15px;
`;
export const Add = styled(InputCommonStyle)`
      border-left: 1px solid #a2aba9;
`;
export const ProductContentContainer = styled.div`
      background-color: ${(props) => props.theme.white};
      padding: 20px 30px;

      ${ProductContentTitle} {
            font-size: 24px;
      }

      ${ProductRatingTopIcon} {
            color: #ff9900;
      }
      ${ProductRatingTopInfo} {
            color: #555;
            font-size: 14px;
      }
      ${ProductContentPrice} {
            font-size: 17px;
      }
      /* product details page endss */

      .product__content--price {
            font-size: 19px;
      }
      .product__content hr {
            color: var(--background-color);
            border-top: 1px solid #a2aba9;
      }
      .product__content--shortdesc p {
            font-size: 15px;
            color: #1b1b1b;
            font-weight: 300;
      }
      .product__quantity {
            display: inline-block;
      }
      .product__cart {
            display: inline-block;
      }
      .input__spinner {
            border: 1px solid #a2aba9;
      }
      .input__spinner i {
            font-size: 12px;
            color: #a2aba9;
      }
      .subtract {
            border-right: 1px solid #a2aba9;
      }
      .quantity {
            padding: 7px 20px;
            color: #525252;
            font-size: 15px;
      }
      .add {
            border-left: 1px solid #a2aba9;
      }
      .add,
      .subtract {
            cursor: pointer;
            padding: 7px 10px;
      }
      .add:hover,
      .subtract:hover {
            background: var(--primary-color);
      }
      .add:hover i,
      .subtract:hover i {
            color: #fff;
      }
      .button-2 {
            padding-left: 10px;
            padding-right: 10px;
            color: #fff;
            position: relative;
      }
      .button-2::before {
            transition: all 0.5s ease;
            position: absolute;
            content: '';
            bottom: 0;
            right: 0;
            height: 8px;
            width: 8px;
      }
      .button-2 button {
            color: #fff;
            z-index: 1;
            position: relative;
      }
      .button-2:hover::before {
            transition: all 0.5s ease;
            width: 100%;
            height: 100%;
      }
      .product__cart {
            background-color: var(--primary-color);
      }
      .product__cart.button-2::before {
            background: #000000;
      }
      .product__buy {
            background-color: #000;
      }
      .product__buy.button-2::before {
            background: var(--primary-color);
      }
      .product__meta ul li {
            list-style-type: none;
            margin-bottom: 10px;
            font-size: 15px;
            font-weight: 300;
            color: #1b1b1b;
      }
      .product__meta ul li span {
            color: #1c1c1c;
      }
      .product__additional ul li button {
            font-size: 18px;
            font-weight: 300;
      }
      .product__desc ul li::marker {
            color: var(--primary-color);
      }
      .product__desc {
            padding: 30px;
      }
      .product__desc {
            font-size: 16px;
            font-weight: 300;
      }
      .nav-link,
      .nav-link:hover {
            color: #333;
      }
      .nav-tabs .nav-link.active {
            position: relative;
            transition: all 0.3s linear;
      }
      .nav-tabs .nav-link.active::after {
            position: absolute;
            content: ' ';
            width: 100%;
            height: 2px;
            top: 0;
            left: 0;
            background: var(--primary-color);
            transition: all 0.3s linear;
      }
`;
