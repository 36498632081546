import React, { memo } from 'react';
import {
      ContactItemContainer,
      ContactItemDetail,
      ContactItemIcon,
      ContactItemTitle
} from './styles';

const ContactItemBox = memo(({ title, icon, children }) => {
      return (
            <>
                  <ContactItemContainer>
                        <ContactItemIcon>{icon}</ContactItemIcon>
                        <ContactItemTitle>{title}</ContactItemTitle>
                        <ContactItemDetail>{children}</ContactItemDetail>
                  </ContactItemContainer>
            </>
      );
});

export default ContactItemBox;
