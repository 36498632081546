import styled from 'styled-components';

export const PoolPumpContainer = styled.div``;
export const PoolPumpHeading = styled.div`
      background-image: ${(props) => props.image};
      background-size: cover;
      padding: 84px;
`;
export const PoolPumpIntroImage = styled.div`
      img {
            transform: translateY(-57px);
      }
`;
export const PoolPumpIntro = styled.div`
      p {
            font-size: 17px;
            line-height: 1.6;
            letter-spacing: 0.1 px;
            text-align: justify;
            span {
                  color: #1155a6;
                  font-size: 17px;
                  font-weight: 500;
            }
      }
`;
export const PoolEnergyImage = styled.div``;
export const PoolEnergyContent = styled.div``;
export const PoolEnergyFeature = styled.div``;
export const PoolEnergyIcon = styled.div`
      h5 {
            color: #1155a6;
      }
      svg {
            width: 50px;
            height: 50px;
      }
`;

export const PoolNoiseContainer = styled.div`
      position: relative;
      padding: 70px;
      background: url('/images/img/poolpump/media-card-carousel-bg.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      h2 {
            font-weight: 400;
            font-size: 27px;
      }
`;

export const PoolNoiseContent = styled.div`
      h4 {
            font-size: 19px;
            font-weight: 400;
            width: 65%;
      }
`;
export const PoolPumpCover = styled.div``;
export const PoolNoiseIcon = styled.div`
      svg {
            width: 50px;
            height: 50px;
            fill: #fff;
      }
`;

export const ImageFloat = styled.div`
      position: absolute;
      top: 0;
      right: 258px;
`;

export const PoolPumpFeatureContainer = styled.div`
      background: #f8f8f8;
`;
export const PoolPumpFeatureContent = styled.div``;
export const PoolPumpTitle = styled.div`
      margin-bottom: 40px;
`;
export const PoolPumpItemContainer = styled.div``;
export const PoolPumpItem = styled.div`
      margin-bottom: 35px;
`;
export const PoolPumpItemIcon = styled.div`
      border: 1px solid #094e99;
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 6px;
      border-bottom-left-radius: 6px;
      svg {
            width: 30px;
            height: 30px;
            fill: #094e99;
      }
`;

export const PoolPumpItemContent = styled.div`
      h5 {
            font-size: 18px;
      }
      p {
            margin-bottom: 0px;
      }
`;
export const PoolPumpComparision = styled.div``;
export const PoolCompareContainer = styled.div``;
export const PoolCompareContent = styled.div`
      h3 {
            color: #1164b0;
      }
      thead {
            tr {
                  th {
                        background: #1164b0;
                        color: #ffff;
                        font-size: 16px;
                        font-weight: 300;
                  }
                  td{
                        text-algin:center;
                  }
            }
      }
`;
export const PoolCompareImage = styled.div``;
export const PoolCompareTitle = styled.div`
      h5 {
            margin-left: 15px;
            margin-top: 20px;
      }
      margin-bottom: 20px;
      position: relative;
      &:after {
            position: absolute;
            content: '';
            top: -1px;
            left: 1px;
            background: #eaaa02;
            height: 30px;
            width: 3px;
      }
`;
