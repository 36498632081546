import React from 'react';
import {
      PoolPumpHeading,
      PoolPumpIntro,
      PoolPumpIntroImage
} from '../PoolPump/styles';
import { Col, Container, Row } from 'react-bootstrap';
import {
      StainlessPanelTankContainer,
      StainlessPanelTankFaq,
      StainlessPanelTankFeatures,
      StainlessPanelTankImage
} from './styles';
import PoolSpaAccordion from '../PoolSpaCover/PoolSpaAccodion';
import { InformationButton } from '../home/components/InformationBox/styles';
import { Link } from 'react-router-dom';

const StainlessPanelTank = () => {
      return (
            <>
                  <PoolPumpHeading image="url('/images/img/poolpump/about-xl.png')">
                        <Container>
                              <h1 className="text-white">Modular Panel Tank</h1>
                        </Container>
                  </PoolPumpHeading>
                  <StainlessPanelTankContainer>
                        <Row className="g-5">
                              <Col>
                                    <PoolPumpIntro className="mt-5">
                                          <p>
                                                <span>
                                                      The Stainless Steel Panel
                                                      storage tank
                                                </span>{' '}
                                                has high material strength
                                                allows for thinner construction.
                                                This makes it very lightweight,
                                                approximately 1/30 the weight of
                                                concrete. This is a great
                                                advantage when installing a tank
                                                on soft ground, since
                                                unnecessary foundation costs can
                                                be eliminated.
                                          </p>
                                          <p>
                                                Stainless Steel tank
                                                construction utilizes
                                                prefabricated panels that are
                                                welded and assembled. This
                                                results in a quick installation
                                                time, about 1/3 the time of a
                                                concrete tank. Furthermore,
                                                there are many advantages to
                                                using stainless steel tanks
                                                regarding construction
                                                management and quality control.
                                                For example, stainless steel
                                                tanks utilize lighter and fewer
                                                materials which make them easier
                                                to construct.
                                          </p>
                                          <InformationButton className="mt-3">
                                                <Link
                                                      to="/"
                                                      className="d-flex gap-2 align-items-center justify-content-cetner"
                                                >
                                                      Download Catalogue
                                                      <i class="bx bxs-download"></i>
                                                </Link>
                                          </InformationButton>
                                    </PoolPumpIntro>
                              </Col>
                              <Col lg={4}>
                                    <PoolPumpIntroImage>
                                          <img
                                                src="/images/img/watertank/steelpaneltank.png"
                                                alt=""
                                                className="img-fluid"
                                                style={{
                                                      transform:
                                                            ' translateY(-0px)'
                                                }}
                                          />
                                    </PoolPumpIntroImage>
                              </Col>
                        </Row>
                        <Row>
                              <Col lg={6}>
                                    <StainlessPanelTankImage>
                                          <img src="" alt="" />
                                    </StainlessPanelTankImage>
                              </Col>
                              <Col lg={6}></Col>
                        </Row>
                  </StainlessPanelTankContainer>
                  <StainlessPanelTankFeatures style={{ background: '#E2F4FD' }}>
                        <Container className="p-5">
                              <h3
                                    className="text-center mb-5"
                                    style={{ color: '#02172C' }}
                              >
                                    Advantages of Stainless Steel Panel Tank
                              </h3>
                              <Row className="px-5 g-5">
                                    <Col lg={6}>
                                          <img
                                                src="/images/img/watertank/panel1.webp"
                                                alt=""
                                                className="img-fluid w-100"
                                          />
                                    </Col>
                                    <Col
                                          lg={6}
                                          className="d-flex justify-content-center ps-5 flex-column"
                                    >
                                          <h4>
                                                Lightweight: 1/30 the weight of
                                                concrete and can be constructed
                                                on soft ground
                                          </h4>
                                          <p>
                                                Stainless Steel high material
                                                strength allows for thinner
                                                construction. This makes it very
                                                lightweight, approximately 1/30
                                                the weight of concrete. This is
                                                a great advantage when
                                                installing a tank on soft
                                                ground, since unnecessary
                                                foundation costs can be
                                                eliminated.
                                          </p>
                                    </Col>
                                    <Col
                                          lg={6}
                                          className="d-flex justify-content-center p-2 flex-column"
                                    >
                                          <h4>
                                                Fast Installation: 1/3 the time
                                                of Concrete Construction
                                          </h4>
                                          <p>
                                                Stainless Steel tank
                                                construction utilizes
                                                prefabricated panels that are
                                                welded and assembled. This
                                                results in a quick installation
                                                time, about 1/3 the time of a
                                                concrete tank. Furthermore,
                                                there are many advantages to
                                                using stainless steel tanks
                                                regarding construction
                                                management and quality control.
                                                For example, stainless steel
                                                tanks utilize lighter and fewer
                                                materials which make them easier
                                                to construct.
                                          </p>
                                    </Col>
                                    <Col lg={6}>
                                          <img
                                                src="/images/img/watertank/panel2.png"
                                                alt=""
                                                className="img-fluid w-100"
                                          />
                                    </Col>
                                    <Col lg={6}>
                                          <img
                                                src="/images/img/watertank/panel3.webp"
                                                alt=""
                                                className="img-fluid w-100"
                                          />
                                    </Col>
                                    <Col
                                          lg={6}
                                          className="d-flex justify-content-center ps-5 flex-column"
                                    >
                                          <h4>
                                                Economic: Life Cycle Cost is
                                                less than a Concrete Tank
                                          </h4>
                                          <p>
                                                {' '}
                                                Hot Water Storage Tanks are
                                                available in various dimensions
                                                according to the client's
                                                requirements and needs.
                                          </p>
                                    </Col>
                              </Row>
                        </Container>
                  </StainlessPanelTankFeatures>
                  <StainlessPanelTankFaq className="pt-3">
                        <Container>
                              <Col lg={12}>
                                    {' '}
                                    <PoolSpaAccordion title="Stainless Steel Panel Tank FAQs"></PoolSpaAccordion>
                              </Col>
                        </Container>
                  </StainlessPanelTankFaq>
            </>
      );
};

export default StainlessPanelTank;
