import React, { useContext } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { BlogDetailContainer } from '../blogs/BlogDetail/styles';
import { Col, Row } from 'react-bootstrap';
import SubscribeBox from '../../components/common/SubscribeBox';
import FaqAccordion from './components/FaqAccordion';
import ContactUsBox from '../../components/common/ContactUsBox';
import { WidgetContainer } from './components/styles';
import MetaContext from '../../stores/MetaContext';

const FaqPage = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('faqs');

      return (
            <>
                  <BreadcrumbBox title="FAQs" />

                  <BlogDetailContainer>
                        <Row>
                              <Col lg={4}>
                                    <WidgetContainer className="sticky-top">
                                          <SubscribeBox margin="0rem" />
                                          <ContactUsBox />
                                    </WidgetContainer>
                              </Col>
                              <Col lg={8}>
                                    <FaqAccordion />
                              </Col>
                        </Row>
                  </BlogDetailContainer>
            </>
      );
};

export default FaqPage;
