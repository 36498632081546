import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import {
      ProductContent,
      ProductSection,
      TestimonialHeading
} from '../../../styles/frontend/home';
import ProductItemBox from '../../../components/common/ProductItemBox';
import { SwiperSlide } from 'swiper/react';
import { ProductSlider } from './styles';
import axios from 'axios';
import HomeProductSkeleton from '../../../components/skeletons/HomeProductSkeleton';

const ProductBox = () => {
      const [loading, setLoading] = useState(false);
      const [products, setProducts] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/featured/products`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProducts(response.data.products);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <ProductSection>
                        <Container>
                              <TestimonialHeading>
                                    <h2>
                                          Our Products{' '}
                                          <span class="ms-2"></span>
                                    </h2>
                                    <h3>Explore Our Popular Products.</h3>
                              </TestimonialHeading>
                              <ProductContent>
                                    {!loading ? (
                                          products?.length > 0 && (
                                                <ProductSlider
                                                      spaceBetween={30}
                                                      autoplay={true}
                                                      slidesPerView={4}
                                                      breakpoints={{
                                                            0: {
                                                                  slidesPerView: 1,
                                                                  spaceBetween: 10
                                                            },
                                                            640: {
                                                                  slidesPerView: 2,
                                                                  spaceBetween: 20
                                                            },
                                                            992: {
                                                                  slidesPerView: 3,
                                                                  spaceBetween: 20
                                                            },
                                                            1200: {
                                                                  slidesPerView: 5,
                                                                  spaceBetween: 20
                                                            }
                                                      }}
                                                      className="py-3"
                                                >
                                                      {products?.map(
                                                            (
                                                                  product,
                                                                  index
                                                            ) => (
                                                                  <SwiperSlide
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <ProductItemBox
                                                                              product={
                                                                                    product
                                                                              }
                                                                        />
                                                                  </SwiperSlide>
                                                            )
                                                      )}
                                                </ProductSlider>
                                          )
                                    ) : (
                                          <ProductSlider
                                                spaceBetween={30}
                                                autoplay={true}
                                                slidesPerView={4}
                                                className="py-3"
                                                breakpoints={{
                                                      0: {
                                                            slidesPerView: 1,
                                                            spaceBetween: 0
                                                      },
                                                      640: {
                                                            slidesPerView: 2,
                                                            spaceBetween: 20
                                                      },
                                                      992: {
                                                            slidesPerView: 3,
                                                            spaceBetween: 20
                                                      },
                                                      1200: {
                                                            slidesPerView: 4,
                                                            spaceBetween: 20
                                                      }
                                                }}
                                          >
                                                <SwiperSlide>
                                                      <HomeProductSkeleton />
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                      <HomeProductSkeleton />
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                      <HomeProductSkeleton />
                                                </SwiperSlide>
                                                <SwiperSlide>
                                                      <HomeProductSkeleton />
                                                </SwiperSlide>
                                          </ProductSlider>
                                    )}
                              </ProductContent>
                        </Container>
                  </ProductSection>
            </>
      );
};

export default ProductBox;
