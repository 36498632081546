import React, { useState } from 'react';
import {
      ProductAction,
      ProductActionContainer,
      ProductContentContainer,
      ProductContentTitle,
      ProductInquiry,
      ProductMeta
} from './styles';
import { ProductItemButton } from '../../../../styles/frontend/home';
import { Link } from 'react-router-dom';
import TitleSkeleton from '../../../../components/skeletons/TitleSkeleton';
import { Skeleton } from '@mui/material';

const ProductContent = ({ product, loading }) => {
      const [num, setNum] = useState(0);
      const incNum = () => {
            if (num < 10) {
                  setNum(Number(num) + 1);
            }
      };
      const decNum = () => {
            if (num > 0) {
                  setNum(Number(num) - 1);
            }
      };
      return (
            <>
                  <ProductContentContainer>
                        <div className="product__content">
                              <ProductContentTitle>
                                    {!loading ? (
                                          <>
                                                {product.title}
                                                {/* <ProductWishList className="btn float-end">
                                                      <i class="bx bx-heart "></i>
                                                </ProductWishList> */}
                                          </>
                                    ) : (
                                          <div>
                                                <TitleSkeleton height={50} />
                                          </div>
                                    )}
                              </ProductContentTitle>
                              <hr />

                              <ProductMeta>
                                    {!loading ? (
                                          <div
                                                dangerouslySetInnerHTML={{
                                                      __html: product.summary
                                                }}
                                          ></div>
                                    ) : (
                                          <>
                                                <TitleSkeleton height={28} />
                                                <TitleSkeleton height={28} />
                                                <TitleSkeleton height={28} />
                                                <TitleSkeleton height={28} />
                                                <TitleSkeleton height={28} />
                                          </>
                                    )}
                                    <ul className="p-0">
                                          {!loading ? (
                                                <li
                                                      className="share"
                                                      style={{
                                                            listStyle: 'none'
                                                      }}
                                                >
                                                      Share On:
                                                      <br />
                                                      <>
                                                            <Link
                                                                  to="/"
                                                                  className="me-2 facebook"
                                                            >
                                                                  <i class="bx bxl-facebook"></i>
                                                            </Link>
                                                            <Link
                                                                  to="/"
                                                                  className="me-2 twitter"
                                                            >
                                                                  <i class="bx bxl-twitter"></i>
                                                            </Link>
                                                            <Link
                                                                  to="/"
                                                                  className="me-2 pinterest"
                                                            >
                                                                  <i class="bx bxl-pinterest"></i>
                                                            </Link>
                                                      </>
                                                </li>
                                          ) : (
                                                <>
                                                      <Skeleton
                                                            height={40}
                                                            width={120}
                                                      />
                                                </>
                                          )}
                                    </ul>
                              </ProductMeta>
                              <hr />
                              <ProductAction className="mb-4">
                                    <ProductActionContainer className="mt-2">
                                          <ProductInquiry>
                                                {!loading ? (
                                                      <ProductItemButton>
                                                            <Link
                                                                  to="#"
                                                                  className="d-inline-block"
                                                            >
                                                                  <span>
                                                                        Enquiry
                                                                        Now
                                                                  </span>
                                                            </Link>
                                                      </ProductItemButton>
                                                ) : (
                                                      <>
                                                            <Skeleton
                                                                  height={90}
                                                                  width={180}
                                                            />
                                                      </>
                                                )}
                                          </ProductInquiry>
                                    </ProductActionContainer>
                              </ProductAction>
                        </div>
                  </ProductContentContainer>
            </>
      );
};
export default ProductContent;
