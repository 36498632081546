import React from 'react';
import { FancoilContainer, FancoilContent, FancoilImage } from './styles';
import { Col, Container, Row } from 'react-bootstrap';
import SolarButton from '../../../common/SolarCommonLayout/SolarButton';

const Fancoil = () => {
      return (
            <>
                  <FancoilContainer>
                        <Container>
                              <Row>
                                    <Col lg="6">
                                          <FancoilContent>
                                                <h3>Innmotek Fancoil</h3>
                                                <p>
                                                      The{' '}
                                                      <strong>fancoil</strong>{' '}
                                                      is one of the elements
                                                      that make up a{' '}
                                                      <strong>
                                                            refrigeration or air
                                                            conditioning system.
                                                      </strong>{' '}
                                                      This is responsible for
                                                      recirculating the air in a
                                                      heated space through a
                                                      fin-shaped coil, inside
                                                      which hot or cold water
                                                      circulates. Consequently,
                                                      the heat transfer between
                                                      the air and the coil is
                                                      faster and more efficient,
                                                      to air-condition or cool a
                                                      given space.
                                                </p>
                                                <p>
                                                      A fan coil is a system
                                                      terminal that can be
                                                      integrated with a heat
                                                      pump or hybrid system. The
                                                      fan coil draws air from
                                                      the room, it is filtered
                                                      and then pushed by the fan
                                                      towards the heat exchange
                                                      coil.
                                                </p>
                                                <h5>
                                                      Benefits of Innmotek
                                                      Fancoil
                                                </h5>
                                                <ul>
                                                      <li>Smart Control</li>
                                                      <li>High Efficiency</li>
                                                      <li>Ultra-thin Casing</li>
                                                      <li>Easy Installation</li>
                                                </ul>
                                          </FancoilContent>
                                         
                                    </Col>
                                    <Col lg="6">
                                          <FancoilImage>
                                                <img
                                                      src={
                                                            '/images/img/radiator/fancoil.png'
                                                      }
                                                      alt="innmotek image"
                                                      className="img-fluid"
                                                />
                                          </FancoilImage>
                                    </Col>
                              </Row>
                        </Container>
                  </FancoilContainer>
            </>
      );
};

export default Fancoil;
