import React from 'react';

const SolarCollectorTable = () => {
   return (
      <div className="container">
         <div
            class="wptb-table-container-matrix col-lg-12"
            id="wptb-table-id-2544"
            data-wptb-version="1.4.13"
            data-wptb-pro-status="true"
         >
            <table
               class="wptb-preview-table wptb-element-main-table_setting-2544"
               data-wptb-adaptive-table="0"
               data-wptb-cells-width-auto-count="10"
               data-wptb-table-tds-sum-max-width="1044"
               style={{
                  borderStyle: 'solid',
                  borderWidth: '0px',
                  borderColor: ' rgb(221, 221, 221)',
                  borderSpacing: ' 0px',
                  borderCollapse: ' collapse !important',
                  display: 'table',
                  minWidth: ' 100%',
                  width: ' auto'
               }}
               data-table-columns="11"
               data-reconstraction="0"
               data-wptb-table-directives="eyJpbm5lckJvcmRlcnMiOnsiYWN0aXZlIjoiYWxsIiwiYm9yZGVyV2lkdGgiOiIxIiwiYm9yZGVyUmFkaXVzZXMiOnsiYWxsIjpudWxsLCJjb2x1bW4iOm51bGwsInJvdyI6bnVsbH19fQ=="
               data-wptb-horizontal-scroll-status="true"
               data-wptb-extra-styles="LyogRW50ZXIgeW91ciBjdXN0b20gQ1NTIHJ1bGVzIGhlcmUgKi8="
               data-wptb-first-column-sticky="false"
               role="table"
               data-wptb-fixed-width-size="264"
               data-wptb-table-alignment="center"
               data-wptb-responsive-directives="eyJyZXNwb25zaXZlRW5hYmxlZCI6dHJ1ZSwicmVzcG9uc2l2ZU1vZGUiOiJhdXRvIiwicHJlc2VydmVSb3dDb2xvciI6ZmFsc2UsInJlbGF0aXZlV2lkdGgiOiJjb250YWluZXIiLCJoZWFkZXJGdWxseU1lcmdlZCI6ZmFsc2UsIm1vZGVPcHRpb25zIjp7ImF1dG8iOnsiZGlzYWJsZWQiOnsiZGVza3RvcCI6ZmFsc2UsInRhYmxldCI6dHJ1ZSwibW9iaWxlIjp0cnVlfSwidG9wUm93QXNIZWFkZXIiOnsiZGVza3RvcCI6ZmFsc2UsInRhYmxldCI6ZmFsc2UsIm1vYmlsZSI6ZmFsc2V9LCJyZXBlYXRNZXJnZWRIZWFkZXIiOnsiZGVza3RvcCI6dHJ1ZSwidGFibGV0Ijp0cnVlLCJtb2JpbGUiOnRydWV9LCJzdGF0aWNUb3BSb3ciOnsiZGVza3RvcCI6ZmFsc2UsInRhYmxldCI6ZmFsc2UsIm1vYmlsZSI6ZmFsc2V9LCJjZWxsU3RhY2tEaXJlY3Rpb24iOnsiZGVza3RvcCI6InJvdyIsInRhYmxldCI6InJvdyIsIm1vYmlsZSI6ImNvbHVtbiJ9LCJjZWxsc1BlclJvdyI6eyJkZXNrdG9wIjoxLCJ0YWJsZXQiOjEsIm1vYmlsZSI6MX19fSwiYnJlYWtwb2ludHMiOnsiZGVza3RvcCI6eyJuYW1lIjoiZGVza3RvcCIsIndpZHRoIjoxMDI0fSwidGFibGV0Ijp7Im5hbWUiOiJ0YWJsZXQiLCJ3aWR0aCI6NzAwfSwibW9iaWxlIjp7Im5hbWUiOiJtb2JpbGUiLCJ3aWR0aCI6MzAwfX19"
               data-wptb-even-row-background-color="#FFFFFFFF"
               data-wptb-odd-row-background-color="#E6E6E6FF"
               data-wptb-header-background-color="#2557A7FF"
               data-wptb-td-width-auto="66"
               data-border-spacing-columns="0"
               data-border-spacing-rows="0"
               data-wptb-pro-pagination-top-row-header="true"
               data-wptb-rows-per-page="3"
               data-wptb-pro-search-top-row-header="true"
               data-wptb-searchbar-position="left"
               data-wptb-breakpoint="desktop"
            >
               <tbody
                  data-global-font-color="#000000"
                  data-global-link-color="#000000"
                  data-global-font-size="15px"
               >
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(37, 87, 167)' }}
                  >
                     <td
                        class="wptb-cell"
                        data-x-index="0"
                        data-y-index="0"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           width: '252px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-3"
                           style={{
                              color: 'rgb(255, 255, 255)',
                              fontSize: '15px',
                              padding: '0px',
                              margin: ' 0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'left' }}>
                                 <strong>Model</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="0"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        data-wptb-css-td-auto-width="true"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-4"
                           style={{
                              color: 'rgb(255, 255, 255)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>
                                 <strong>1.50 V</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="2"
                        data-y-index="0"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-5"
                           style={{
                              color: 'rgb(255, 255, 255)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>
                                 <strong>1.50 H</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="3"
                        data-y-index="0"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-6"
                           style={{
                              color: 'rgb(255, 255, 255)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>
                                 <strong>1.82 V</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="4"
                        data-y-index="0"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-7"
                           style={{
                              color: 'rgb(255, 255, 255)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>
                                 <strong>1.82 H</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="5"
                        data-y-index="0"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-8"
                           style={{
                              color: 'rgb(255, 255, 255)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>
                                 <strong>2.00 V</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="6"
                        data-y-index="0"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-9"
                           style={{
                              color: 'rgb(255, 255, 255)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>
                                 <strong>2.00 H</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="7"
                        data-y-index="0"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-10"
                           style={{
                              color: 'rgb(255, 255, 255)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>
                                 <strong>2.37 V</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="8"
                        data-y-index="0"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-11"
                           style={{
                              color: 'rgb(255, 255, 255)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>
                                 <strong>2.37 H</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="9"
                        data-y-index="0"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-12"
                           style={{
                              color: 'rgb(255, 255, 255)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>
                                 <strong>2.72 V</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="10"
                        data-y-index="0"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-13"
                           style={{
                              color: 'rgb(255, 255, 255)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>
                                 <strong>2.72</strong> <strong>H</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                  >
                     <td
                        class="wptb-cell"
                        data-x-index="0"
                        data-y-index="1"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           width: '252px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        data-sorted-horizontal="ask-disabled"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-14"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p>
                                 <strong>Gross area [m²]</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="1"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        data-wptb-css-td-auto-width="true"
                        data-wptb-cell-vertical-alignment="center"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-15"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>1.50</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="2"
                        data-y-index="1"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-16"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '15px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>1.50</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="3"
                        data-y-index="1"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-17"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>1.82</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="4"
                        data-y-index="1"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-18"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>1.82</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="5"
                        data-y-index="1"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-19"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>2.00</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="6"
                        data-y-index="1"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-20"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>2.00</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="7"
                        data-y-index="1"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-21"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>2.37</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="8"
                        data-y-index="1"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-22"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>2.37</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="9"
                        data-y-index="1"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-23"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>2.72</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="10"
                        data-y-index="1"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-24"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>2.72</p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(230, 230, 230)' }}
                  >
                     <td
                        class="wptb-cell"
                        data-x-index="0"
                        data-y-index="2"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        data-sorted-horizontal="ask-disabled"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-25"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p>
                                 <strong>Total Dimensions [mm]</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="2"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        data-wptb-css-td-auto-width="true"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-26"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>L:1480</p>
                              <p style={{ textAlign: 'center' }}>W:1010</p>
                              <p style={{ textAlign: 'center' }}>H:86</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="2"
                        data-y-index="2"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-27"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>L:1010</p>
                              <p style={{ textAlign: 'center' }}>W:1480</p>
                              <p style={{ textAlign: 'center' }}>H:86</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="3"
                        data-y-index="2"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-28"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>L:1480</p>
                              <p style={{ textAlign: 'center' }}>W:1230</p>
                              <p style={{ textAlign: 'center' }}>H:86</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="4"
                        data-y-index="2"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-29"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>L:1230</p>
                              <p style={{ textAlign: 'center' }}>W:1480</p>
                              <p style={{ textAlign: 'center' }}>H:86</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="5"
                        data-y-index="2"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-30"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>L:1980</p>
                              <p style={{ textAlign: 'center' }}>W:1010</p>
                              <p style={{ textAlign: 'center' }}>H:86</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="6"
                        data-y-index="2"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-31"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>L:1010</p>
                              <p style={{ textAlign: 'center' }}>W:1980</p>
                              <p style={{ textAlign: 'center' }}>H:86</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="7"
                        data-y-index="2"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-32"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>L:1930</p>
                              <p style={{ textAlign: 'center' }}>W:1230</p>
                              <p style={{ textAlign: 'center' }}>H:86</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="8"
                        data-y-index="2"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-33"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>L:1230</p>
                              <p style={{ textAlign: 'center' }}>W:1930</p>
                              <p style={{ textAlign: 'center' }}>H:86</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="9"
                        data-y-index="2"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-34"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>L:2160</p>
                              <p style={{ textAlign: 'center' }}>W:1260</p>
                              <p style={{ textAlign: 'center' }}>H:86</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="10"
                        data-y-index="2"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-35"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>L:1260</p>
                              <p style={{ textAlign: 'center' }}>W:2160</p>
                              <p style={{ textAlign: 'center' }}>H:86</p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                  >
                     <td
                        class="wptb-cell"
                        data-x-index="0"
                        data-y-index="3"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        data-sorted-horizontal="ask-disabled"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-46"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p>
                                 <strong>Weight empty [kg]</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="3"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        data-wptb-css-td-auto-width="true"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-47"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>24.30</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="2"
                        data-y-index="3"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-48"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>24.70</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="3"
                        data-y-index="3"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-49"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>29.40</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="4"
                        data-y-index="3"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-50"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>29.60</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="5"
                        data-y-index="3"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-51"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>31.80</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="6"
                        data-y-index="3"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-52"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>32.40</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="7"
                        data-y-index="3"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-53"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>37.30</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="8"
                        data-y-index="3"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-54"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>37.80</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="9"
                        data-y-index="3"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-55"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>42.30</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="10"
                        data-y-index="3"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-56"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>43.00</p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(230, 230, 230)' }}
                  >
                     <td
                        class="wptb-cell"
                        data-x-index="0"
                        data-y-index="4"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-57"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p>
                                 <strong>Max. operating Pressure [bar]</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="4"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        colspan="10"
                        rowspan="1"
                        data-wptb-css-td-auto-width="true"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-58"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>10</p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                  >
                     <td
                        class="wptb-cell"
                        data-x-index="0"
                        data-y-index="5"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        data-sorted-horizontal="ask-disabled"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-60"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p>
                                 <strong>Thermal Liquid Capacity [lt]</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="5"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        data-wptb-css-td-auto-width="true"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-61"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>1.17</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="2"
                        data-y-index="5"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-62"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>1.48</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="3"
                        data-y-index="5"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-63"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>1.43</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="4"
                        data-y-index="5"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-64"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>1.59</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell wptb-ondragenter"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="5"
                        data-y-index="5"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-ondragenter wptb-element-text-65"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>1.33</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="6"
                        data-y-index="5"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-66"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>1.96</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="7"
                        data-y-index="5"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-67"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>1.61</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="8"
                        data-y-index="5"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-68"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>2.07</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="9"
                        data-y-index="5"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-69"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>1.73</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="10"
                        data-y-index="5"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-70"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>2.21</p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(230, 230, 230)' }}
                  >
                     <td
                        class="wptb-cell"
                        data-x-index="0"
                        data-y-index="6"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-71"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p>
                                 <strong>
                                    Collector front Cover-Thickness
                                 </strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="6"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        colspan="10"
                        rowspan="1"
                        data-wptb-css-td-auto-width="true"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-72"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>
                                 LOW IRON TEMPERED GLASS 3.2mm
                              </p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                  >
                     <td
                        class="wptb-cell"
                        data-x-index="0"
                        data-y-index="7"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-74"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p>
                                 <strong>Insulation</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="7"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        colspan="10"
                        rowspan="1"
                        data-wptb-css-td-auto-width="true"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-75"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>
                                 30mm MINERAL WOOL, λ=0.035 [W/(mK)]
                              </p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(230, 230, 230)' }}
                  >
                     <td
                        class="wptb-cell"
                        data-x-index="0"
                        data-y-index="8"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-77"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p>
                                 <strong>Casing Material</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="8"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        colspan="10"
                        rowspan="1"
                        data-wptb-css-td-auto-width="true"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-78"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>
                                 ALUMINUM&nbsp;
                              </p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                  >
                     <td
                        class="wptb-cell"
                        data-x-index="0"
                        data-y-index="9"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-80"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p>
                                 <strong>Sealing Materials</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="9"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        colspan="10"
                        rowspan="1"
                        data-wptb-css-td-auto-width="true"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-81"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>
                                 POLYURETHANE - SILICON - EPDM
                              </p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(230, 230, 230)' }}
                  >
                     <td
                        class="wptb-cell"
                        data-x-index="0"
                        data-y-index="10"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        data-sorted-horizontal="ask-disabled"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-83"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p>
                                 <strong>Absorber Area [m²]</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="10"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        data-wptb-css-td-auto-width="true"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-84"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>1.38</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="2"
                        data-y-index="10"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-85"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>1.38</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="3"
                        data-y-index="10"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-86"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>1.72</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="4"
                        data-y-index="10"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-87"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>1.72</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="5"
                        data-y-index="10"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-88"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>1.86</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="6"
                        data-y-index="10"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-89"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>1.86</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="7"
                        data-y-index="10"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-90"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>2.23</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="8"
                        data-y-index="10"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-91"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>2.23</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="9"
                        data-y-index="10"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-92"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>2.57</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="10"
                        data-y-index="10"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-93"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>2.57</p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                  >
                     <td
                        class="wptb-cell wptb-ondragenter"
                        data-x-index="0"
                        data-y-index="11"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-ondragenter wptb-element-text-95"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p>
                                 <strong>
                                    Water-frame type/material/diameter
                                 </strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="11"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        colspan="10"
                        rowspan="1"
                        data-wptb-css-td-auto-width="true"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-96"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>
                                 Harp type, copper, ø22 headers-ø8 risers
                              </p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(230, 230, 230)' }}
                  >
                     <td
                        class="wptb-cell wptb-ondragenter"
                        data-x-index="0"
                        data-y-index="12"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        data-sorted-horizontal="ask-disabled"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-ondragenter wptb-element-text-98"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p>
                                 <strong>Nr. Of risers</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="12"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        data-wptb-css-td-auto-width="true"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-99"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>8</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="2"
                        data-y-index="12"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-100"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>12</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="3"
                        data-y-index="12"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-101"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>10</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="4"
                        data-y-index="12"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-102"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>12</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="5"
                        data-y-index="12"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-103"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>8</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="6"
                        data-y-index="12"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-104"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>16</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="7"
                        data-y-index="12"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-105"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>10</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="8"
                        data-y-index="12"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-106"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>16</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="9"
                        data-y-index="12"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-107"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>10</p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-wptb-css-td-auto-width="true"
                        data-x-index="10"
                        data-y-index="12"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-108"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>17</p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                  >
                     <td
                        class="wptb-cell wptb-ondragenter"
                        data-x-index="0"
                        data-y-index="13"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-ondragenter wptb-element-text-110"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p>
                                 <strong>Absorber Material-Treatment</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="13"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        colspan="10"
                        rowspan="1"
                        data-wptb-css-td-auto-width="true"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-111"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>
                                 ALUMINUM / PVD COATING / HIGH SELECTIVE –
                                 A=0.95±0.02 / e=0.05±0.02
                              </p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(230, 230, 230)' }}
                  >
                     <td
                        class="wptb-cell wptb-ondragenter"
                        data-x-index="0"
                        data-y-index="14"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-113"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p>
                                 <strong>Absorber construction Type</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="14"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        colspan="10"
                        rowspan="1"
                        data-wptb-css-td-auto-width="true"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-114"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>LASER</p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                  >
                     <td
                        class="wptb-cell wptb-ondragenter"
                        data-x-index="0"
                        data-y-index="15"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-ondragenter wptb-element-text-116"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p>
                                 <strong>Heat transfer Medium</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="15"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        colspan="10"
                        rowspan="1"
                        data-wptb-css-td-auto-width="true"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-117"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>
                                 &nbsp;GLYCOL + WATER MIXTURE
                              </p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(230, 230, 230)' }}
                  >
                     <td
                        class="wptb-cell wptb-ondragenter"
                        data-x-index="0"
                        data-y-index="16"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-ondragenter wptb-element-text-119"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p>
                                 <strong>Tests and Certifications</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="16"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        colspan="10"
                        rowspan="1"
                        data-wptb-css-td-auto-width="true"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-120"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>
                                 SOLAR KEYMARK
                              </p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                  >
                     <td
                        class="wptb-cell wptb-ondragenter"
                        data-x-index="0"
                        data-y-index="17"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)',
                           width: '252px'
                        }}
                        colspan="1"
                        rowspan="1"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-ondragenter wptb-element-text-122"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '15px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p></p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        data-x-index="1"
                        data-y-index="17"
                        data-wptb-css-td-auto-width="true"
                        colspan="10"
                        rowspan="1"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-150"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>
                                 <strong>
                                    EFFICIENCY VALUES BASED ON EN ISO 9806:2013
                                    STANDARD (SKM10086)
                                 </strong>
                              </p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(230, 230, 230)' }}
                  >
                     <td
                        class="wptb-cell"
                        data-x-index="0"
                        data-y-index="18"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-124"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p>
                                 <strong>Efficiency η0,b</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="18"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        colspan="10"
                        rowspan="1"
                        data-wptb-css-td-auto-width="true"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-125"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>0.74</p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                  >
                     <td
                        class="wptb-cell"
                        data-x-index="0"
                        data-y-index="19"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-127"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p>
                                 <strong>Thermal loss a1 [w/(m²K) ]</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="19"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        colspan="10"
                        rowspan="1"
                        data-wptb-css-td-auto-width="true"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-128"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>3.73</p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(230, 230, 230)' }}
                  >
                     <td
                        class="wptb-cell wptb-ondragenter"
                        data-x-index="0"
                        data-y-index="20"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-ondragenter wptb-element-text-130"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p>
                                 <strong>Thermal loss a2 (w/(m²K² )</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="20"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        colspan="10"
                        rowspan="1"
                        data-wptb-css-td-auto-width="true"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-131"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>0.007</p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                  >
                     <td
                        class="wptb-cell wptb-ondragenter"
                        data-x-index="0"
                        data-y-index="21"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-ondragenter wptb-element-text-133"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p>
                                 <strong>IAM (Κθ at 50°)</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="21"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        colspan="10"
                        rowspan="1"
                        data-wptb-css-td-auto-width="true"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-134"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>0.9</p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(230, 230, 230)' }}
                  >
                     <td
                        class="wptb-cell"
                        data-x-index="0"
                        data-y-index="22"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-136"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p>
                                 <strong>Stagnation temp. [°C]</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="22"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        colspan="10"
                        rowspan="1"
                        data-wptb-css-td-auto-width="true"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-137"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>185</p>
                           </div>
                        </div>
                     </td>
                  </tr>
                  <tr
                     class="wptb-row"
                     style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                  >
                     <td
                        class="wptb-cell"
                        data-x-index="0"
                        data-y-index="23"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-139"
                           style={{
                              color: 'rgb(37, 87, 167)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p>
                                 <strong>ηcol</strong>
                              </p>
                           </div>
                        </div>
                     </td>
                     <td
                        class="wptb-cell"
                        data-x-index="1"
                        data-y-index="23"
                        style={{
                           padding: '6px',
                           borderStyle: 'none',
                           borderWidth: '1px',
                           borderColor: 'rgb(221, 221, 221)'
                        }}
                        colspan="10"
                        rowspan="1"
                        data-wptb-css-td-auto-width="true"
                     >
                        <div
                           class="wptb-text-container wptb-ph-element wptb-element-text-140"
                           style={{
                              color: 'rgb(74, 85, 104)',
                              fontSize: '14px',
                              padding: '0px',
                              margin: '0px'
                           }}
                        >
                           <div class="" style={{ position: 'relative' }}>
                              <p style={{ textAlign: 'center' }}>57%</p>
                           </div>
                        </div>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>
      </div>
   );
};

export default SolarCollectorTable;
