import styled from 'styled-components';
import OwlCarousel from 'react-owl-carousel';

export const ContactSlider = styled(OwlCarousel)``;

export const ContactForm = styled.div`
      width: 100%;
      margin-top: 3rem;
      border: 2px solid #a5a5a5;
      padding: 30px;
      background-color: #f7f7fa;

      .form-label {
            font-size: 18px;
      }

      .form-control {
            border-radius: 0;

            &:focus {
                  box-shadow: none;
            }
      }
`;
