import styled from 'styled-components';

export const MobileMenuButton = styled.div`
      cursor: pointer;
      display: none;

      @media only screen and (max-width: 992px) {
            display: block;
      }
`;
