import React from 'react';

const ControlIcon = () => {
      return (
            <>
                  <svg
                        version="1.1"
                        id="fi_687305"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 335.2 335.2"
                        style={{ enableBackground: 'new 0 0 335.2 335.2;' }}
                        xmlSpace="preserve"
                  >
                        <g>
                              <g>
                                    <path
                                          d="M327.6,61.8H194.4C191.2,47,177.6,35.4,162,35.4c-16,0-29.2,11.2-32.4,26.4H7.2C3.2,61.8,0,65,0,69s3.2,7.2,7.2,7.2h122
			c3.2,14.8,16.8,26.4,32.4,26.4c16,0,29.2-11.2,32.4-26.4h133.6c4,0,7.2-3.2,7.2-7.2S331.6,61.8,327.6,61.8z M162,88.6
			c-10.4,0-19.2-8.4-19.2-19.2s8.4-19.2,19.2-19.2c10.4,0,19.2,8.4,19.2,19.2C181.2,79.8,172.8,88.6,162,88.6z"
                                    ></path>
                              </g>
                        </g>
                        <g>
                              <g>
                                    <path
                                          d="M328,160.6H126c-3.2-14.8-16.8-26.4-32.4-26.4c-16,0-29.2,11.2-32.4,26.4h-54c-4,0-7.2,3.2-7.2,7.2s3.2,7.2,7.2,7.2h54
			c3.2,14.8,16.8,26.4,32.4,26.4c16,0,29.2-11.2,32.4-26.4h202c4,0,7.2-3.2,7.2-7.2S332,160.6,328,160.6z M93.6,187
			c-10.4,0-19.2-8.4-19.2-19.2c0-10.4,8.8-19.2,19.2-19.2c10.4,0,19.2,8.4,19.2,19.2C112.8,178.2,104.4,187,93.6,187z"
                                    ></path>
                              </g>
                        </g>
                        <g>
                              <g>
                                    <path
                                          d="M327.6,259h-62.8c-3.2-14.8-16.8-26.4-32.4-26.4c-16,0-29.2,11.2-32.4,26.4H7.2c-4,0-7.2,3.2-7.2,7.2s3.2,7.2,7.2,7.2
			h192.4c3.2,14.8,16.8,26.4,32.4,26.4c16,0,29.2-11.2,32.4-26.4h63.2c4,0,7.2-3.2,7.2-7.2S331.6,259,327.6,259z M232,285.8
			c-10.4,0-19.2-8.4-19.2-19.2c0-10.8,8.8-19.2,19.2-19.2c10.4,0,19.2,8.4,19.2,19.2C251.2,277.4,242.8,285.8,232,285.8z"
                                    ></path>
                              </g>
                        </g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                  </svg>
            </>
      );
};

export default ControlIcon;
