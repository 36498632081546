import React from 'react';

const ShareIcon = () => {
      return (
            <div>
                  <svg
                        id="fi_12695980"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                  >
                        <path d="m50 82c-15.9 0-28.84-12.94-28.84-28.84 0-11.57 6.88-21.99 17.52-26.54 1.43-.61 3.07.05 3.68 1.48s-.05 3.07-1.48 3.68c-8.58 3.67-14.12 12.06-14.12 21.39 0 12.81 10.42 23.23 23.23 23.23s23.23-10.42 23.23-23.23c0-9.32-5.54-17.72-14.12-21.39-1.42-.61-2.08-2.26-1.48-3.68.61-1.42 2.26-2.08 3.68-1.48 10.64 4.55 17.52 14.97 17.52 26.54 0 15.9-12.94 28.84-28.84 28.84z"></path>
                        <path d="m49.98 46.56c-1.81 0-3.27-1.46-3.27-3.27v-23.02c0-1.81 1.46-3.27 3.27-3.27s3.27 1.46 3.27 3.27v23.02c0 1.81-1.46 3.27-3.27 3.27z"></path>
                  </svg>
            </div>
      );
};

export default ShareIcon;
