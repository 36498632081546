import { Skeleton } from '@mui/material';
import React from 'react';

const ProjectDetailSkeleton = () => {
      return (
            <>
                  <Skeleton
                        variant="rectangular"
                        height={460}
                        animation="wave"
                  />
            </>
      );
};

export default ProjectDetailSkeleton;
