import React from 'react';
import {
      Accordion,
      Button,
      Col,
      Container,
      Form,
      FormControl,
      FormLabel,
      Modal,
      Row
} from 'react-bootstrap';
import { RoboticCleanerFaq, RoboticCleanerFaqContent } from '../styles';
import { ContactForm } from '../../contacts/styles';
import FormButton from '../../../components/Form/FormButton';
import { useState } from 'react';

const RoboticPoolCleanerFaq = ({ title, image }) => {
      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);
      return (
            <div>
                  <RoboticCleanerFaq>
                        <Container>
                              <Row>
                                    <Col lg={6}>
                                          <RoboticCleanerFaqContent className="w-100 h-100 d-flex  justify-content-center flex-column">
                                                <h3 className="mb-4">
                                                      FAQs for
                                                      <br />
                                                      {title}
                                                </h3>
                                                <Button
                                                      onClick={handleShow}
                                                      className="rounded-0 "
                                                >
                                                      Send Your Inquiry{' '}
                                                      <i class="bx bx-paper-plane ms-2"></i>
                                                </Button>
                                                <Modal
                                                      show={show}
                                                      onHide={handleClose}
                                                      size="lg"
                                                      aria-labelledby="contained-modal-title-vcenter"
                                                      centered
                                                >
                                                      <Modal.Body>
                                                            <Col>
                                                                  <ContactForm>
                                                                        <Modal.Title className="mb-3">
                                                                              Send
                                                                              Us
                                                                              Your
                                                                              Query
                                                                        </Modal.Title>{' '}
                                                                        <Form>
                                                                              <Row className="g-4">
                                                                                    <Col
                                                                                          lg={
                                                                                                6
                                                                                          }
                                                                                    >
                                                                                          <FormLabel>
                                                                                                First
                                                                                                Name
                                                                                          </FormLabel>
                                                                                          <FormControl type="text" />
                                                                                    </Col>
                                                                                    <Col
                                                                                          lg={
                                                                                                6
                                                                                          }
                                                                                    >
                                                                                          <FormLabel>
                                                                                                Last
                                                                                                Name
                                                                                          </FormLabel>
                                                                                          <FormControl type="text" />
                                                                                    </Col>
                                                                                    <Col
                                                                                          lg={
                                                                                                6
                                                                                          }
                                                                                    >
                                                                                          <FormLabel>
                                                                                                Email
                                                                                                Address
                                                                                          </FormLabel>
                                                                                          <FormControl type="text" />
                                                                                    </Col>
                                                                                    <Col
                                                                                          lg={
                                                                                                6
                                                                                          }
                                                                                    >
                                                                                          <FormLabel>
                                                                                                Phone
                                                                                                No.
                                                                                          </FormLabel>
                                                                                          <FormControl type="text" />
                                                                                    </Col>
                                                                                    <Col
                                                                                          lg={
                                                                                                12
                                                                                          }
                                                                                    >
                                                                                          <FormLabel>
                                                                                                Message
                                                                                          </FormLabel>
                                                                                          <FormControl
                                                                                                as="textarea"
                                                                                                rows={
                                                                                                      4
                                                                                                }
                                                                                                type="text"
                                                                                          />
                                                                                    </Col>
                                                                                    <Col
                                                                                          lg={
                                                                                                12
                                                                                          }
                                                                                    >
                                                                                          <FormButton title="Submit" />
                                                                                    </Col>
                                                                              </Row>
                                                                        </Form>
                                                                  </ContactForm>
                                                            </Col>
                                                      </Modal.Body>
                                                </Modal>
                                          </RoboticCleanerFaqContent>
                                    </Col>
                                    <Col lg={6}>
                                          <img
                                                src={image}
                                                alt=""
                                                className="img-fluid"
                                          />
                                    </Col>
                              </Row>
                        </Container>
                  </RoboticCleanerFaq>
            </div>
      );
};

export default RoboticPoolCleanerFaq;
