import React from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import {
      ImageClip,
      RoboticCleanerContainer,
      RoboticCleanerContent,
      RoboticCleanerContentImage,
      RoboticCleanerFaq,
      RoboticCleanerFaqContent,
      RoboticCleanerHeading,
      RoboticCleanerHeadingContent,
      RoboticCleanerHeadingImage,
      RoboticCleanerHeadingOverlay,
      RoboticCleanerRemote,
      RoboticCleanerRemoteContent,
      RoboticCleanerTitle,
      RoboticCleanerVideo,
      RoboticClenerHeadingContainer,
      RoboticPoolCleanerContainer
} from './styles';
import {
      Button,
      Col,
      Container,
      Form,
      FormControl,
      FormLabel,
      Modal,
      Row
} from 'react-bootstrap';
import RoboticPoolCleanerFaq from './RoboticPoolCleanerFaq';
import CategoryContactBox from '../PoolSpaCover/CategoryContact';
import { ContactForm } from '../contacts/styles';
import FormButton from '../../components/Form/FormButton';
import { useState } from 'react';

const RoboticPoolCleaner = () => {
      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);
      return (
            <>
                  <RoboticPoolCleanerContainer>
                        {/* <BreadcrumbBox title="Robotic Pool Cleaner" /> */}
                        <RoboticCleanerHeading className="d-flex mb-5">
                              <RoboticClenerHeadingContainer className="w-50 ">
                                    <RoboticCleanerHeadingContent className="w-100 h-100 d-flex align-items-center">
                                          <h1 className="text-white">
                                                ROBOTIC POOL CLEANER
                                          </h1>
                                    </RoboticCleanerHeadingContent>
                              </RoboticClenerHeadingContainer>

                              <RoboticCleanerHeadingOverlay className="w-50 d-flex justify-content-end ">
                                    <Col lg={6}>
                                          <RoboticCleanerHeadingImage>
                                                <img
                                                      src="/images/img/robotic pool cleaner/robotic-image.png"
                                                      alt=""
                                                      className="img-fluid"
                                                />
                                          </RoboticCleanerHeadingImage>
                                    </Col>
                              </RoboticCleanerHeadingOverlay>
                        </RoboticCleanerHeading>
                        <RoboticCleanerContainer className="mb-5">
                              <Container>
                                    <Row className="g-5">
                                          <Col lg={5}>
                                                <RoboticCleanerContentImage>
                                                      <img
                                                            src="/images/img/robotic pool cleaner/image-1.jpg"
                                                            alt=""
                                                            className="img-fluid"
                                                      />
                                                </RoboticCleanerContentImage>
                                          </Col>
                                          <Col>
                                                <RoboticCleanerContent>
                                                      <RoboticCleanerTitle className="mt-5">
                                                            <h2>
                                                                  Welcome To A
                                                                  New Era Of
                                                                  Technology
                                                            </h2>
                                                            <p>
                                                                  Innmotek's
                                                                  range of
                                                                  products is
                                                                  crafted to
                                                                  revolutionize
                                                                  the way you
                                                                  experience the
                                                                  world. With a
                                                                  steadfast
                                                                  focus on
                                                                  sustainability,
                                                                  innovation,
                                                                  and enhanced
                                                                  user features,
                                                                  we are proud
                                                                  to provide
                                                                  cutting-edge
                                                                  solutions that
                                                                  surpass your
                                                                  expectations.
                                                            </p>
                                                            <p>
                                                                  At Innmotek,
                                                                  we envision a
                                                                  future where
                                                                  technology
                                                                  seamlessly
                                                                  harmonizes
                                                                  with the
                                                                  environment.
                                                                  Sustainability
                                                                  is ingrained
                                                                  in every
                                                                  aspect of our
                                                                  work, with our
                                                                  products
                                                                  meticulously
                                                                  designed to
                                                                  reduce their
                                                                  ecological
                                                                  footprint. We
                                                                  are excited
                                                                  that you've
                                                                  chosen to
                                                                  partner with
                                                                  Innmotek.
                                                                  Together,
                                                                  let's forge a
                                                                  path towards a
                                                                  more
                                                                  sustainable
                                                                  future.
                                                            </p>
                                                      </RoboticCleanerTitle>
                                                </RoboticCleanerContent>
                                          </Col>
                                    </Row>
                              </Container>
                        </RoboticCleanerContainer>
                        <RoboticCleanerVideo className="">
                              <video
                                    playsinline="playsinline"
                                    autoplay="autoplay"
                                    muted
                                    loop="loop"
                                    className="w-100"
                              >
                                    <source
                                          src="https://4337543.fs1.hubspotusercontent-na1.net/hubfs/4337543/GT%20Freedom%20Webpage%20Video%20(2).mp4"
                                          type="video/mp4"
                                    />
                              </video>
                        </RoboticCleanerVideo>
                        <Container className="p-5">
                              <Row className="g-0">
                                    <Col lg={6}>
                                          <img
                                                src="/images/img/robotic pool cleaner/robotic-image1.jpg"
                                                className="img-fluid"
                                                data-aos="fade-right"
                                          />
                                    </Col>
                                    <Col lg={6}>
                                          <RoboticCleanerContent
                                                className="w-100 h-100 d-flex flex-column justify-content-center p-5"
                                                data-aos="fade-left"
                                          >
                                                <h4>
                                                      <ImageClip>10</ImageClip>{' '}
                                                      Hours
                                                      <br />
                                                      AI-Wireless Technology
                                                </h4>
                                                <p>
                                                      Innmotek's original
                                                      Full-inverter technology
                                                      ensures that the internal
                                                      DC-Inverter motors operate
                                                      at varying capacities
                                                      (ranging from 20% to 100%,
                                                      averaging at 50%) through
                                                      advanced algorithmic
                                                      control. This leads to an
                                                      extended endurance and
                                                      lifespan compared to
                                                      previous standards.
                                                </p>
                                          </RoboticCleanerContent>
                                    </Col>
                              </Row>
                              <Row className="g-0">
                                    <Col lg={6}>
                                          <RoboticCleanerContent
                                                className="w-100 h-100 d-flex flex-column justify-content-center p-5"
                                                data-aos="fade-right"
                                          >
                                                <h4>
                                                      Breakthrough Tech
                                                      <br />
                                                      Breakthrough Freedom
                                                </h4>
                                                <p>
                                                      Simply set the cleaning
                                                      task and mode on the App,
                                                      the wireless pool cleaning
                                                      robot with the longest
                                                      endurance promises to keep
                                                      your pool clean for up to
                                                      2 weeks with just 1
                                                      charge.
                                                </p>
                                          </RoboticCleanerContent>
                                    </Col>
                                    <Col lg={6}>
                                          <img
                                                src="/images/img/robotic pool cleaner/robotic-image2.jpg"
                                                className="img-fluid"
                                                data-aos="fade-left"
                                          />
                                    </Col>
                              </Row>
                        </Container>
                        <Container
                              className=" p-0"
                              fluid={true}
                              style={{ backgroundColor: '#F5F5F5' }}
                        >
                              <Row className="g-0">
                                    <Col lg={6}>
                                          <video
                                                src="/images/img/robotic pool cleaner/video/video1.mp4"
                                                muted
                                                autoplay="autoplay"
                                                loop="loop"
                                                className="img-fluid"
                                                data-aos="fade-left"
                                          ></video>
                                    </Col>
                                    <Col lg={6}>
                                          <RoboticCleanerContent
                                                className="w-100 h-100 d-flex flex-column justify-content-center p-5 ms-5 pe-5"
                                                data-aos="fade-right"
                                          >
                                                <h4 className="mb-4">
                                                      Intelligent 3D S path
                                                      <br />
                                                      Patented
                                                </h4>
                                                <p className="w-75">
                                                      With the advanced control
                                                      system, robotic cleaner
                                                      analyzes the pool
                                                      environment and plans the
                                                      most efficient cleaning
                                                      path, saving you 60%
                                                      cleaning time!
                                                </p>
                                          </RoboticCleanerContent>
                                    </Col>
                              </Row>
                              <Row className="g-0 pb-5">
                                    <Col lg={6}>
                                          <RoboticCleanerContent
                                                className="w-100 h-100 d-flex flex-column justify-content-center p-5"
                                                data-aos="fade-left"
                                          >
                                                <h4 className="mb-4">
                                                      AI Mode for Vacation
                                                      <br />
                                                      Breakthrough Freedom
                                                </h4>
                                                <p className="w-75">
                                                      The original AI mode
                                                      allows the users to charge
                                                      1 time for 2 weeks using &
                                                      plans the cleaning cycle.
                                                      Even if the owner goes out
                                                      travelling, your reliable
                                                      wireless pool cleaner, can
                                                      still keep your pool
                                                      automatically with a
                                                      crystal-clean water at the
                                                      pre-set cleaning time.
                                                </p>
                                          </RoboticCleanerContent>
                                    </Col>
                                    <Col lg={6}>
                                          <video
                                                src="/images/img/robotic pool cleaner/video/video2.mp4"
                                                muted
                                                autoplay="autoplay"
                                                loop="loop"
                                                className="img-fluid"
                                                data-aos="fade-right"
                                          ></video>
                                    </Col>
                              </Row>
                        </Container>
                        <RoboticCleanerRemote className="">
                              <Container fluid={true} className="p-0">
                                    <RoboticCleanerRemoteContent>
                                          <h3 className="text-white text-center">
                                                AI Mode
                                          </h3>
                                          <p className="text-white">
                                                ONE Click to Open a Free
                                                Lifestyle
                                          </p>
                                    </RoboticCleanerRemoteContent>
                                    <img
                                          src="images/img/robotic pool cleaner/robotic-image3.jpg"
                                          alt=""
                                          className="img-fluid"
                                    />
                              </Container>
                        </RoboticCleanerRemote>
                        <RoboticPoolCleanerFaq
                              title="Robotic Pool Cleaner"
                              image="/images/img/robotic pool cleaner/robotic-image4.png"
                        ></RoboticPoolCleanerFaq>
                  </RoboticPoolCleanerContainer>
            </>
      );
};

export default RoboticPoolCleaner;
