import React from 'react';

const CostIcon = () => {
      return (
            <>
                  <svg
                        id="fi_7822088"
                        height="512"
                        viewBox="0 0 60 60"
                        width="512"
                        xmlns="http://www.w3.org/2000/svg"
                  >
                        <path
                              d="m59 30a28.991 28.991 0 0 1 -49.5 20.5l-.52.52a1.34 1.34 0 0 1 -2.24-.87l-.43-4.29a1.231 1.231 0 0 1 1.37-1.36l4.28.43a1.339 1.339 0 0 1 .88 2.23l-.5.5a24.992 24.992 0 0 0 41.19-26.12 1.986 1.986 0 0 1 .89-2.4 2.031 2.031 0 0 1 1-.27 1.973 1.973 0 0 1 1.87 1.31 28.751 28.751 0 0 1 1.71 9.82z"
                              fill="#99db5e"
                        ></path>
                        <path
                              d="m53.69 14.14a1.231 1.231 0 0 1 -1.37 1.36l-4.28-.43a1.339 1.339 0 0 1 -.88-2.23l.5-.5a24.992 24.992 0 0 0 -41.19 26.12 1.986 1.986 0 0 1 -.89 2.4 2.031 2.031 0 0 1 -1 .27 1.973 1.973 0 0 1 -1.87-1.31 29 29 0 0 1 47.79-30.32l.52-.52a1.34 1.34 0 0 1 2.24.87z"
                              fill="#99db5e"
                        ></path>
                        <path
                              d="m27.2 48.428 14.4-19.234a2 2 0 0 0 -1.6-3.194h-8l3.3-13.237a1.41 1.41 0 0 0 -2.5-1.191l-14.4 19.234a2 2 0 0 0 1.6 3.194h8l-3.3 13.237a1.41 1.41 0 0 0 2.5 1.191z"
                              fill="#ffcc4d"
                        ></path>
                  </svg>
            </>
      );
};

export default CostIcon;
