import React, { useState } from 'react';
import {
   SolarButtonContainer,
   SolarOverviewContainer,
   SolarOverviewContent,
   SolarOverviewImage,
   SolarOverviewTitle
} from './styles';
import { Col, Collapse, Container, Row } from 'react-bootstrap';
import SolarButton from '../SolarButton';
import SolarCollectorTable from '../SolarTableLayout/SolarCollectorTable';
import SolarCertification from '../SolarCertification';

const overviews = [
   {
      content: (
         <p>
            <strong>1. </strong>
            <strong>Frame of the collector: </strong>Aluminium profile powder
            coated for maximum protection in seaside areas.
         </p>
      )
   },
   {
      content: (
         <p>
            <strong>2. </strong>
            <strong>Absorbing surface: </strong>Aluminium surface with blue
            titanium high selective treatment with high absorption and low
            emission (α=95%, ε=4%), laser welded on the copper water frame.
         </p>
      )
   },
   {
      content: (
         <p>
            <strong>3. </strong>
            <strong>Transparent cover:</strong> Security-Tempered prismatic
            solar glass for maximum protection against extreme weather
            conditions and temperature changes.
         </p>
      )
   },
   {
      content: (
         <p>
            <strong>4. </strong>
            <strong>Header of water frame: </strong>Copper tubes Ø22, which are
            welded to the vertical tubes with hard silver solder. Each water
            frame is tested at the pressure of 15 bars. Headers are punched with
            upper expansion for perfect fitting with vertical tubes and minimum
            pressure drop in the collector.
         </p>
      )
   },
   {
      content: (
         <p>
            <strong>5. </strong>
            <strong>Vertical tubes: </strong>Copper tubes in diameter Ø8mm.
         </p>
      )
   },
   {
      content: (
         <p>
            <strong>6. </strong>
            <strong>Thermal insulation: </strong>30mm thick layer of prepressed
            mineral wool special for solar panels for minimum thermal loss.
            Thermal conductivity: 0=0.035 W/m°K (EN 13162) and heat capacity
            0.84 kJ/kgK.
         </p>
      )
   },
   {
      content: (
         <p>
            <strong>7. </strong>
            <strong>Back cover: </strong>Aluzinc 0,4mm thick. Aluzinc stands for
            aluminium and zinc, fused in almost equal proportions, as a coating
            for the steel sheet that is coated with a silvery spangle composed
            of Aluminium (55%), Zinc (43,4%) and a touch of Silicon (1,6%).
            Great mechanical strength and 7 times more resistant to corrosion
            than common galvanized steel.
         </p>
      )
   },
   {
      content: (
         <p>
            <strong>8. </strong>
            <strong>Sealing materials: </strong>For perfect waterproof finish
            and proper ventilation of collectors casing, all materials used
            (EPDM, polyurethane sealant, silicon air vents and silicon header
            flanges) resist to extreme weather conditions and temperature
            changes.
         </p>
      )
   },
   {
      content: (
         <p>The collector can be installed on a flat roof or tiled roof.</p>
      )
   }
];

const SolarOverview = () => {
   const [open, setOpen] = useState(false);
   return (
      <SolarOverviewContainer>
         <Container>
            <Row>
               <Col lg={12}>
                  <SolarOverviewContent>
                     <SolarOverviewImage>
                        <img
                           src={
                              'images/img/solar/SOLO-overview-image-1200x673.png'
                           }
                           alt=""
                           className="img-fluid"
                        />
                     </SolarOverviewImage>
                     <SolarOverviewTitle>
                        SOLAR THERMAL COLLECTORS TECHNICAL DATA
                     </SolarOverviewTitle>
                     <ul>
                        {overviews.map((item, index) => (
                           <li key={index}>{item.content}</li>
                        ))}
                     </ul>
                     <SolarButtonContainer className="d-flex align-items-center gap-2">
                        <SolarButton
                           title={'View Specifications'}
                           setOpen={setOpen}
                           open={open}
                           index={1}
                        />
                        <SolarButton
                           title={'View Certifications'}
                           setOpen={setOpen}
                           open={open}
                           index={2}
                        />
                     </SolarButtonContainer>

                     <Collapse in={open === 1} className="mt-4">
                        <div id="example-collapse-text-1  pt-4">
                           <SolarCollectorTable />
                        </div>
                     </Collapse>
                     <Collapse in={open === 2} className="mt-4">
                        <div id="example-collapse-text-2  pt-4">
                           <SolarCertification />
                        </div>
                     </Collapse>
                  </SolarOverviewContent>
               </Col>
            </Row>
         </Container>
      </SolarOverviewContainer>
   );
};

export default SolarOverview;
