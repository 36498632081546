import { Skeleton } from '@mui/material';
import React from 'react';

const SliderSkeleton = () => {
      return (
            <>
                  <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={593}
                  />
            </>
      );
};

export default SliderSkeleton;
