import { Skeleton } from '@mui/material';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

const HomeProductSkeleton = () => {
      return (
            <>
                  <div className=" p-3 rounded">
                        <Skeleton
                              height={270}
                              style={{
                                    transform: 'scale(1)'
                              }}
                        />
                        <div className="px-2">
                              <Skeleton variant="text" height={30} />
                        </div>

                        <div className="w-100 d-flex align-items-center justify-content-center">
                              <Skeleton
                                    className="mt-3 text-center"
                                    width={160}
                                    height={40}
                                    style={{
                                          transform: 'scale(1)'
                                    }}
                              />
                        </div>
                  </div>
            </>
      );
};

export default HomeProductSkeleton;
