import React from 'react';
import {
      RadiatorFaq,
      RadiatorPageContainer,
      RadiatorPageImage,
      RadiatorPageIntro,
      RadiatorPageIntroContent
} from './styles';
import { Col, Container } from 'react-bootstrap';
import {
      RadiatorAdditional,
      RadiatorCategory,
      RadiatorInfo,
      RadiatorSlider
} from '../../components/frontend/radiator';
import PoolSpaAccordion from '../PoolSpaCover/PoolSpaAccodion';
import Fancoil from '../../components/frontend/radiator/Fancoil';

const RadiatorPage = () => {
      return (
            <>
                  <RadiatorPageContainer>
                        <RadiatorPageIntro>
                              <Container>
                                    <RadiatorPageIntroContent>
                                          <h2>Innmotek Radiators</h2>
                                          <p>
                                                Radiators are the most popular
                                                type of hydronic heating and
                                                cooling devices and include a
                                                host of different model types:
                                                panel, decorative, towel warmers
                                                and different kinds of
                                                convectors. Whilst most commonly
                                                referred to as a ‘heating’
                                                product some radiator types use
                                                fan support to provide cooling.
                                                Modern radiators help to
                                                maintain a comfortable room
                                                temperature while making a
                                                significant contribution toward
                                                lowering your energy
                                                consumption.
                                          </p>
                                    </RadiatorPageIntroContent>
                              </Container>

                              <RadiatorPageImage>
                                    <img
                                          src="/images/img/radiator/radiator.webp"
                                          alt="innmotek radiator"
                                          className="img-fluid"
                                    />
                              </RadiatorPageImage>
                        </RadiatorPageIntro>
                        <RadiatorSlider />
                        <RadiatorInfo />
                        <RadiatorCategory />
                        <RadiatorAdditional />
                        <Fancoil />
                        <RadiatorFaq>
                              <Container>
                                    <Col lg={12}>
                                          {' '}
                                          <PoolSpaAccordion title="Radiator Faq"></PoolSpaAccordion>
                                    </Col>
                              </Container>
                        </RadiatorFaq>
                  </RadiatorPageContainer>
            </>
      );
};

export default RadiatorPage;
