import React from 'react';
import {
      ProductItem,
      ProductItemButton,
      ProductItemDetail,
      ProductItemImage,
      ProductItemTitle
} from '../../../styles/frontend/home';
import { Link } from 'react-router-dom';

const ProductItemBox = ({ product }) => {
      return (
            <>
                  <ProductItem>
                        <ProductItemImage>
                              <Link to={`${product.slug}`}>
                                    <img
                                          src={product.image}
                                          alt={product.title}
                                          class="img-fluid"
                                          width="100%"
                                          height="100%"
                                    />
                              </Link>
                        </ProductItemImage>
                        <ProductItemDetail>
                              <ProductItemTitle className="text-center">
                                    <h4>{product.title}</h4>
                              </ProductItemTitle>
                              {/* <ProductItemButton>
                                    <Link to={`/products/${product.slug}`}>
                                          <span>Learn More</span>
                                    </Link>
                              </ProductItemButton> */}
                        </ProductItemDetail>
                  </ProductItem>
            </>
      );
};

export default ProductItemBox;
