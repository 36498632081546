import React from 'react';
import {
      PageNotFoundButton,
      PageNotFoundContainer,
      PageNotFoundContent
} from './styles';
import { Col, Row } from 'react-bootstrap';
import { ImageClip } from '../RoboticPoolCleaner/styles';
import { AboutButton } from '../../styles/frontend/home';
import { Link, useNavigate } from 'react-router-dom';

const PageNotFound = ({ title, fontSize, subTitle, desc }) => {
      // const navigate = useNavigate();

      return (
            <>
                  <PageNotFoundContainer>
                        <Row className="justify-content-center">
                              <Col lg={7}>
                                    <PageNotFoundContent
                                          fontSize={fontSize}
                                          className="w-100 text-center"
                                    >
                                          <h1>
                                                <ImageClip>
                                                      {title ? title : '404'}
                                                </ImageClip>
                                          </h1>
                                          <h2>
                                                {subTitle
                                                      ? subTitle
                                                      : 'Oops! Page Not Found!'}
                                          </h2>
                                          <p>
                                                {desc
                                                      ? desc
                                                      : 'The page you are looking for does not exist. It might have been move or deleted'}
                                          </p>
                                          <PageNotFoundButton className="mt-5">
                                                <Link to="/">
                                                      <span>
                                                            {title
                                                                  ? 'Go to Home Page'
                                                                  : 'Return to Home Page'}
                                                      </span>
                                                </Link>
                                          </PageNotFoundButton>
                                    </PageNotFoundContent>
                              </Col>
                        </Row>
                  </PageNotFoundContainer>
            </>
      );
};

export default PageNotFound;
