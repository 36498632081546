import React from 'react';
import {
      PoolPumpHeading,
      PoolPumpIntro,
      PoolPumpIntroImage
} from '../PoolPump/styles';
import { Col, Container, Row } from 'react-bootstrap';
import {
      StainlessPanelTankContainer,
      StainlessPanelTankFaq,
      StainlessPanelTankFeatures,
      StainlessPanelTankImage
} from '../stainlesspaneltank/styles';
import { InformationButton } from '../home/components/InformationBox/styles';
import { Link } from 'react-router-dom';
import PoolSpaAccordion from '../PoolSpaCover/PoolSpaAccodion';

const StainlessWaterTank = () => {
      return (
            <div>
                  <>
                        <PoolPumpHeading image="url('/images/img/poolpump/about-xl.png')">
                              <Container>
                                    <h1 className="text-white">
                                          Hot Water Storage Tank
                                    </h1>
                              </Container>
                        </PoolPumpHeading>
                        <StainlessPanelTankContainer>
                              <Row className="g-5">
                                    <Col>
                                          <PoolPumpIntro className="mt-5">
                                                <p>
                                                      <span>
                                                            The Stainless Steel
                                                            hot water storage
                                                            tank
                                                      </span>{' '}
                                                      is made in India of
                                                      high-quality,
                                                      corrosion-resistant
                                                      stainless steel and is
                                                      designed for optimal
                                                      layering with almost 100%
                                                      volume usage. It can be
                                                      used as well as for
                                                      heating and cooling
                                                      storage. Innmotek offers
                                                      both vertical and
                                                      horizontal stainless steel
                                                      storage tanks that are
                                                      designed to store liquids
                                                      at a specific pressure, as
                                                      well as atmospheric
                                                      pressure. Additionally,
                                                      customers may choose to
                                                      include heat transfer
                                                      tubes (heating coils)
                                                      inside the tank to produce
                                                      hot water.
                                                </p>
                                                <InformationButton className="mt-3">
                                                      <Link
                                                            to="/"
                                                            className="d-flex gap-2 align-items-center justify-content-cetner"
                                                      >
                                                            Download Catalogue
                                                            <i class="bx bxs-download"></i>
                                                      </Link>
                                                </InformationButton>
                                          </PoolPumpIntro>
                                    </Col>
                                    <Col lg={4}>
                                          <PoolPumpIntroImage>
                                                <img
                                                      src="/images/img/watertank/Hot Water Storage Tank.png"
                                                      alt=""
                                                      className="img-fluid"
                                                      style={{
                                                            marginTop: '0px'
                                                      }}
                                                />
                                          </PoolPumpIntroImage>
                                    </Col>
                              </Row>
                              <Row>
                                    <Col lg={6}>
                                          <StainlessPanelTankImage>
                                                <img src="" alt="" />
                                          </StainlessPanelTankImage>
                                    </Col>
                                    <Col lg={6}></Col>
                              </Row>
                        </StainlessPanelTankContainer>
                        <StainlessPanelTankFeatures>
                              <Container className="p-5">
                                    <Row className="px-5 g-0">
                                          <Col lg={6}>
                                                <img
                                                      src="/images/img/watertank/metal-wine-storage-tanks-winery.jpg"
                                                      alt="Durability"
                                                      className="img-fluid w-100"
                                                />
                                          </Col>
                                          <Col
                                                lg={6}
                                                className="d-flex justify-content-center ps-5 flex-column"
                                          >
                                                <h3>Durability</h3>
                                                <p>
                                                      Hot Water Tanks are
                                                      factory-made from
                                                      high-specification MS &
                                                      Stainless Steel and are
                                                      functionally designed to
                                                      resist all types of
                                                      corrosion, including
                                                      crevice corrosion and
                                                      stress corrosion our range
                                                      of tanks uses high
                                                      recovery smooth tube coils
                                                      for optimal performance.
                                                      Hot Water Tanks provide
                                                      high mechanical strength
                                                      which promises durability,
                                                      while automated welding
                                                      procedures, post-weld
                                                      curing and guarantee each
                                                      individual solid weld.
                                                      FireSlab Water Systems Hot
                                                      Water Tanks are pressure
                                                      confirmed up to 9 bar.
                                                </p>
                                                <ul>
                                                      <li>
                                                            Steel is a very
                                                            durable material
                                                      </li>
                                                      <li>
                                                            Steel is always in
                                                            excellent condition
                                                            even after decades
                                                            of usage
                                                      </li>
                                                      <li>
                                                            It's anti-corrosive
                                                            and quake-resistant
                                                      </li>
                                                </ul>
                                          </Col>
                                          <Col
                                                lg={6}
                                                className="d-flex justify-content-center p-2 flex-column"
                                          >
                                                <h3>Installation Time</h3>
                                                <p>
                                                      Innmotek Water Systems
                                                      Stainless Steel Hot Water
                                                      Tanks deliver a dependable
                                                      and cost-effective answer
                                                      to commercial premises'
                                                      hot water heating and
                                                      storage needs. Stainless
                                                      Steel Hot Water Tanks
                                                      provide quick recovery and
                                                      are suited to high-demand
                                                      usage.
                                                </p>
                                                <p>
                                                      Hot Water Storage Tanks
                                                      are available in various
                                                      dimensions according to
                                                      the client's requirements
                                                      and needs.
                                                </p>
                                          </Col>
                                          <Col lg={6}>
                                                <img
                                                      src="/images/img/watertank/IMG-20230603-WA0016.jpg"
                                                      alt="Hygiene"
                                                      className="img-fluid w-100"
                                                />
                                          </Col>
                                          <Col lg={6}>
                                                <img
                                                      src="/images/img/watertank/IMG-20230501-WA0005.jpg"
                                                      alt="Economic"
                                                      className="img-fluid w-100"
                                                />
                                          </Col>
                                          <Col
                                                lg={6}
                                                className="d-flex justify-content-center ps-5 flex-column"
                                          >
                                                <h3>Hygiene</h3>
                                                <p>
                                                      Innmotek Water Systems's
                                                      Stainless Steel Hot Water
                                                      Tanks are made of
                                                      superior-grade stainless
                                                      steel which is resistant
                                                      to corrosion, and chemical
                                                      abrasion and is designed
                                                      to withstand high pressure
                                                      and compression.
                                                </p>
                                          </Col>
                                          <Col
                                                lg={6}
                                                className="d-flex justify-content-center p-3 flex-column"
                                          >
                                                <h3>Economic</h3>
                                                <p>
                                                      Innmotek Water Systems'
                                                      Stainless Steel Hot Water
                                                      Tanks are made in
                                                      Gorakhpur, India and are
                                                      100% quality checked and
                                                      tested since it is
                                                      manufactured by the latest
                                                      well-equipped CNC
                                                      machinery and the latest
                                                      techniques like cutting
                                                      and bending and for any
                                                      other structural flaws by
                                                      our highly qualified
                                                      professional staff
                                                      according to Indian and
                                                      international industry
                                                      standards guidelines.
                                                </p>
                                          </Col>
                                          <Col lg={6}>
                                                <img
                                                      src="/images/img/watertank/P1.jpg"
                                                      alt=""
                                                      className="img-fluid w-100"
                                                />
                                          </Col>
                                    </Row>
                              </Container>
                        </StainlessPanelTankFeatures>
                        <Container className="p-5">
                              <Row className="px-5">
                                    <Col className="" lg={3}>
                                          <img
                                                src="/images/img/watertank/water1.png"
                                                alt="Pressurized hot water tank"
                                                className="img-fluid border"
                                          />
                                    </Col>
                                    <Col className="py-5" lg={9}>
                                          <h4 className="mt-4  mb-3 text-capitalized">
                                                Pressurized hot water tank
                                          </h4>
                                          <p className="text-justify">
                                                A pressurized hot water tank is
                                                a container or vessel designed
                                                to store and heat water for
                                                domestic or industrial use. The
                                                term "pressurized" indicates
                                                that the tank is sealed to
                                                maintain internal pressure,
                                                which helps to deliver water at
                                                a consistent and elevated
                                                pressure when needed. This type
                                                of tank is commonly used in
                                                water heating systems, such as
                                                those for residential homes or
                                                commercial buildings. In a
                                                pressurized hot water tank
                                                system, cold water enters the
                                                tank, and a heating element
                                                (usually electric or gas) heats
                                                the water to the desired
                                                temperature. The pressurized
                                                design allows hot water to be
                                                supplied at various outlets
                                                throughout a building, such as
                                                faucets and showers, with
                                                adequate pressure. This setup
                                                ensures a reliable and efficient
                                                supply of hot water for daily
                                                activities like bathing,
                                                cleaning, and cooking.
                                          </p>
                                    </Col>
                                    <Col lg={9} className="py-5 ps-4">
                                          <h4 className="mt-4  mb-3 text-capitalized ">
                                                Non-pressurized water tank
                                          </h4>
                                          <p>
                                                A non-pressurized water tank is
                                                an unsealed container designed
                                                to store and hold water without
                                                maintaining internal pressure.
                                                Unlike pressurized tanks
                                                commonly used in hot water
                                                systems, non-pressurized tanks
                                                operate at atmospheric pressure.
                                                These tanks are often used for
                                                various purposes, including
                                                water storage, rainwater
                                                harvesting, agricultural
                                                irrigation, and other
                                                application
                                          </p>
                                    </Col>
                                    <Col lg={3}>
                                          <img
                                                src="/images/img/watertank/water3.jpg"
                                                alt=""
                                                className="img-fluid border"
                                          />
                                    </Col>
                                    <Col
                                          className="d-flex align-items-center"
                                          lg={3}
                                    >
                                          <img
                                                src="/images/img/watertank/water2.jpg"
                                                alt="A Glass-lined hot water tank"
                                                className="img-fluid border"
                                          />
                                    </Col>
                                    <Col className="py-5" lg={9}>
                                          <h4 className="mt-4  mb-3 text-capitalized">
                                                A Glass-lined hot water tank
                                          </h4>
                                          <p className="text-justify">
                                                A glass-lined hot water tank is
                                                a specialized water storage unit
                                                with an interior surface coated
                                                in glass. This glass lining
                                                serves to prevent corrosion,
                                                ensuring the longevity of the
                                                tank and maintaining the purity
                                                of the hot water stored inside.
                                                The glass lining also offers
                                                chemical inertness, making it
                                                suitable for applications where
                                                the stored water may have
                                                corrosive elements. These tanks
                                                find widespread use in
                                                residential and industrial
                                                settings, providing reliable and
                                                durable solutions for heating
                                                and storing hot water.
                                          </p>
                                    </Col>
                              </Row>
                        </Container>
                        <StainlessPanelTankFaq className="pt-3">
                              <Container>
                                    <Col lg={12}>
                                          {' '}
                                          <PoolSpaAccordion title="Stainless Steel Water Tank FAQs"></PoolSpaAccordion>
                                    </Col>
                              </Container>
                        </StainlessPanelTankFaq>
                  </>
            </div>
      );
};

export default StainlessWaterTank;
