import React, { useEffect, useState } from 'react';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import axios from 'axios';
import toast from 'react-hot-toast';
import {
   BannerSection,
   BannerShape,
   BannerSlider
} from '../../../styles/frontend/home';
import SliderSkeleton from '../../../components/skeletons/SliderSkeleton';

const BannerBox = () => {
   const [loading, setLoading] = useState(false);
   const [banners, setBanners] = useState([]);

   const loadData = async () => {
      setLoading(true);
      try {
         const response = await axios.get(
            `${process.env.REACT_APP_SECRET_KEY}/api/banners`
         );
         if (response.data.result === 'success') {
            setBanners(response.data.banners);
         }
      } catch (error) {
         toast.error(error.message);
      }
      setLoading(false);
   };

   useEffect(() => {
      loadData();
   }, []); // Only fetch data once when the component mounts

   return (
      <BannerSection>
         <BannerSlider>
            {!loading ? (
               banners?.length > 0 && (
                  <Swiper
                     spaceBetween={30}
                     centeredSlides={true}
                     autoplay={{
                        delay: 2500,
                        disableOnInteraction: false
                     }}
                     pagination={{
                        clickable: true
                     }}
                     navigation={true}
                     modules={[Autoplay, Pagination, Navigation]}
                     className="mySwiper"
                  >
                     {banners?.map((banner, index) => (
                        <SwiperSlide key={index}>
                           <img
                              src={banner.image}
                              alt={banner.title}
                              width="100%"
                              height="100%"
                           />
                        </SwiperSlide>
                     ))}
                  </Swiper>
               )
            ) : (
               <SliderSkeleton />
            )}
         </BannerSlider>
         <BannerShape>
            <img
               src="/images/img/banner/shape.png"
               alt="innmotek banner shape"
               width="100%"
               height="100%"
            />
         </BannerShape>
      </BannerSection>
   );
};

export default BannerBox;
