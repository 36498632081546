import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const ProductSwiper = styled(Swiper)`
      height: 320px;
      background-color: white;

      img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            border: 1px solid #e5e5e5;
      }
`;

export const ProductThumbnail = styled(Swiper)`
      border: 1px solid #e5e5e5;
      padding: 10px;
      margin-top: 20px;
      background-color: white;

      img {
            width: 100%;
            height: 90px;
            border-radius: 5px;
            object-fit: contain;
      }
`;
