import React, { memo } from 'react';

const MapIcon = memo(() => {
      return (
            <>
                  <i className="bx bx-map"></i>
            </>
      );
});

export default MapIcon;
