import styled from 'styled-components';

export const ServiceContent = styled.div`
      p {
            text-align: justify;
      }

      img {
            margin-bottom: 10px;
            width: 100%;
            height: 100%;
      }
`;

export const ServiceTitle = styled.div`
      h2 {
            font-size: 24px;
      }
`;

export const ServiceImage = styled.div`
      img {
            margin-bottom: 10px;
            width: 100%;
            height: 100%;
      }
`;
