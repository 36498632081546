import styled from 'styled-components';

export const RadiatorSliderContainer = styled.div`
      padding: 60px 0px;
`;

export const RadiatorSliderImage = styled.div``;
export const RadiatorSliderInfo = styled.div`
      background: ${(props) => props.theme.primary};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      color: ${(props) => props.theme.white};
      position: relative;
      overflow: hidden;
      h3 {
            background-image: url('/images/img/radiator/5.jpg');
            font-size: 6vw;
            background-size: cover;
            background-postion: center;
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
      }
      &:after {
            content: '';
            width: 100%;
            height: 100%;
            border: 2px solid #363636;
            position: absolute;
            z-index: 100;
            transform: rotate(-45deg);
      }
`;
