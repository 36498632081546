import styled from 'styled-components';

export const SolarIntroductionContainer = styled.div`
   background: url(images/background.png);
   background-size: cover;
   background-position: top center;
   padding: 70px 0px;
   position: relative;
`;

export const SolarIntroductionContent = styled.div`
   clip-path: polygon(0 0, 99% 0, 93% 100%, 0% 100%);
   width: 65%;
   background: #00000094;
   color: ${(props) => props.theme.white};
   padding: 50px 125px 50px 100px;
   position: relative;
`;
export const SolarIntroductionHeading = styled.h2``;

export const SolarIntroductionDescription = styled.p`
   text-align: justify;
`;
