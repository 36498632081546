import styled from 'styled-components';

export const SolarBenefitsContainer = styled.div`
   padding: 30px 0px;
`;
export const SolarBenefitImage = styled.div``;
export const SolarBenefitTitle = styled.h4``;
export const SolarBenefitContent = styled.div``;
export const SolarBenefitKeyFeature = styled.div`
   color: #2557a7;
   font-weight: 600;
   font-size: 17px;
`;
export const SolarBenefitItems = styled.ul`
   list-style-type: none;
   margin-top: 20px;
   margin-bottom: 30px;
`;
export const SolarBenefitItem = styled.li`
   margin-bottom: 10px;
   i {
      color: #2557a7;
   }
`;
export const SolarBenefitItemImage = styled.div``;
