import styled from 'styled-components';

export const RadiatorPageContainer = styled.div``;

export const RadiatorPageIntro = styled.div`
      position: relative;
`;
export const RadiatorPageIntroContent = styled.div`
      position: absolute;
      color: ${(props) => props.theme.white};
      top: 90px;
      left: 110px;
      max-width: 550px;
      h2 {
            color: ${(props) => props.theme.secondary};
      }
      p {
            color: #f5f5f5;
            text-align: justify;
      }
`;

export const RadiatorPageImage = styled.div``;

export const RadiatorFaq = styled.div`
      ${'' /* margin-top: 60px; */}
      padding: 10px 0px;
      background: #f5f5f5;
`;
