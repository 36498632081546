import React from 'react';
import { DehumidifierContent, DehumidifierHeading } from './styles';
import { Col, Container, Row } from 'react-bootstrap';

import { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';
import RoboticPoolCleanerFaq from '../RoboticPoolCleaner/RoboticPoolCleanerFaq';
const Dehumidifier = () => {
      return (
            <>
                  <DehumidifierHeading>
                        <h1 className="text-white">Dehumidifier</h1>
                        <h4>Full-Inverter® Dehumidifier 360</h4>
                  </DehumidifierHeading>
                  <Container className="p-5">
                        <Row>
                              <Col className="d-flex  flex-column align-items-center">
                                    <h3
                                          style={{
                                                color: '#D84A27',
                                                fontSize: '40px'
                                          }}
                                    >
                                          2x
                                    </h3>
                                    <h5>Energy Saving</h5>
                              </Col>{' '}
                              <Col className="d-flex  flex-column align-items-center">
                                    <h3
                                          style={{
                                                color: '#D84A27',
                                                fontSize: '40px'
                                          }}
                                    >
                                          10x
                                    </h3>
                                    <h5>Quieter</h5>
                              </Col>
                        </Row>
                  </Container>
                  <Container fluid={true} className="p-0">
                        <img
                              src="/images/img/dehumidifier/proY2.png"
                              alt=""
                              className="img-fluid"
                        />
                  </Container>
                  <DehumidifierContent>
                        <Container className="d-flex flex-column align-items-center">
                              <h2 className="text-center mb-3">
                                    The Full-Inverter Dehumidifier 360 By
                                    Full-Inverter®
                              </h2>
                              <p className="w-75 text-center ">
                                    Innmotek's advanced full-inverter technology
                                    enables the Dehumidifier to operate
                                    intelligently, adjusting its performance
                                    according to the humidity levels in your
                                    indoor pool space for optimal energy savings
                                    and quiet operation.
                              </p>
                        </Container>
                  </DehumidifierContent>
                  <Container className="mt-3 px-5">
                        <Swiper
                              autoplay
                              modules={[Pagination]}
                              className="mySwiper"
                        >
                              <SwiperSlide>
                                    <img
                                          src="/images/img/dehumidifier/group1.jpg"
                                          alt=""
                                          className="w-100 rounded-5"
                                    />
                                    <h2
                                          className="mt-3"
                                          style={{ color: '#D84A27' }}
                                    >
                                          2 Times Energy Saving
                                    </h2>
                                    <p> Average DER4.3 (Air 30°C/RH 80%) </p>
                              </SwiperSlide>
                              <SwiperSlide>
                                    <img
                                          src="/images/img/dehumidifier/dehumidifier2.png"
                                          alt=""
                                          className="w-100 rounded-5"
                                    />
                                    <h2
                                          className="mt-3"
                                          style={{ color: '#D84A27' }}
                                    >
                                          2 Times Energy Saving
                                    </h2>
                                    <p> Average DER4.3 (Air 30°C/RH 80%) </p>
                              </SwiperSlide>
                              <SwiperSlide>
                                    <img
                                          src="/images/img/dehumidifier/dehumidifier3.png"
                                          alt=""
                                          className="w-100 rounded-5"
                                    />
                                    <h2
                                          className="mt-3"
                                          style={{ color: '#D84A27' }}
                                    >
                                          Patented Design
                                    </h2>
                                    <p>
                                          {' '}
                                          Compact design for space-saving, 2
                                          installation options
                                          <br />
                                          (Floor-standing or wall-mounted
                                          installation)）
                                    </p>
                              </SwiperSlide>
                        </Swiper>
                  </Container>
                  <Container className="px-5">
                        <Row className="px-5 g-0">
                              <Col lg={6}>
                                    <img
                                          src="/images/img/dehumidifier/image1.jpg"
                                          alt=""
                                          className="img-fluid w-100"
                                    />
                              </Col>
                              <Col
                                    lg={6}
                                    className="d-flex align-items-center ps-5"
                              >
                                    <h3>R32 Eco-friendly Refrigerant</h3>
                              </Col>
                              <Col
                                    lg={6}
                                    className="d-flex justify-content-center ps-5 flex-column"
                              >
                                    <h3>2 Options for Easy Installation</h3>
                                    <p>Floor-standing & Wall-mounted</p>
                              </Col>
                              <Col lg={6}>
                                    <img
                                          src="/images/img/dehumidifier/Group 2.png"
                                          alt=""
                                          className="img-fluid w-100"
                                    />
                              </Col>
                              <Col lg={6}>
                                    <img
                                          src="/images/img/dehumidifier/image3.jpg"
                                          alt=""
                                          className="img-fluid w-100"
                                    />
                              </Col>
                              <Col
                                    lg={6}
                                    className="d-flex justify-content-center ps-5 flex-column"
                              >
                                    <h3>Strong Anti-rusting Casing</h3>
                                    <p>Metal & Aluminum Alloy</p>
                              </Col>
                              <Col
                                    lg={6}
                                    className="d-flex justify-content-center ps-5 flex-column"
                              >
                                    <h3>Remote Control</h3>
                              </Col>
                              <Col lg={6}>
                                    <img
                                          src="/images/img/dehumidifier//Group 6.png"
                                          alt=""
                                          className="img-fluid w-100"
                                    />
                              </Col>
                              <Col lg={6}>
                                    <img
                                          src="/images/img/dehumidifier/Group 3.png"
                                          alt=""
                                          className="img-fluid w-100"
                                    />
                              </Col>
                              <Col
                                    lg={6}
                                    className="d-flex justify-content-center ps-5 flex-column"
                              >
                                    <h3>Optional Electric heating</h3>
                              </Col>
                        </Row>
                  </Container>
                  <RoboticPoolCleanerFaq
                        title="Dehumidifier"
                        image="/images/img/dehumidifier/Group 4.png"
                  />
            </>
      );
};

export default Dehumidifier;
