import axios from 'axios';
import React, { useEffect, useState } from 'react';
import BreadcrumbBox from '../../../components/common/BreadcrumbBox';
import {
      PrivacyPolicyContainer,
      PrivacyPolicyContent,
      PrivacyPolicyNavigation
} from '../styles';
import { Col, Row } from 'react-bootstrap';
import PrivacyPolicySlider from '../PrivacyPolicySlider/PrivacyPolicySlider';
import { Helmet } from 'react-helmet';

const DealerPolicy = () => {
      const [loading, setLoading] = useState(false);
      const [policy, setPolicy] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/pages/dealer-policy`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setPolicy(response.data.page);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);
      return (
            <>
                  {!loading ? (
                        <Helmet>
                              <title>
                                    {policy.seo_title
                                          ? policy.seo_title
                                          : policy.title}
                              </title>
                              {policy.seo_description && (
                                    <meta
                                          name="description"
                                          content={policy.seo_description}
                                    />
                              )}
                              {policy.seo_keyword && (
                                    <meta
                                          name="keyword"
                                          content={policy.seo_keyword}
                                    />
                              )}
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                        </Helmet>
                  ) : null}
                  <BreadcrumbBox title="Privacy Policy" />
                  <PrivacyPolicyContainer className="my-5">
                        <Row className="justify-content-center">
                              <Col lg={12}>
                                    <PrivacyPolicyNavigation>
                                          <PrivacyPolicySlider />
                                    </PrivacyPolicyNavigation>
                              </Col>
                              <Col lg={12}>
                                    <PrivacyPolicyContent className="d-flex">
                                          {!loading ? (
                                                <div
                                                      dangerouslySetInnerHTML={{
                                                            __html: policy.description
                                                      }}
                                                ></div>
                                          ) : null}
                                          {!loading
                                                ? policy?.image && (
                                                        <img
                                                              src={policy.image}
                                                              alt="Innmotek dealer policy"
                                                              className="img-fluid "
                                                        />
                                                  )
                                                : null}
                                    </PrivacyPolicyContent>
                              </Col>
                        </Row>
                  </PrivacyPolicyContainer>
            </>
      );
};

export default DealerPolicy;
