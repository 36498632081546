import React from 'react';
import {
      FeaturedContainer,
      FeaturedImage,
      FeaturedSection
} from '../../../styles/frontend/home';
import { Container } from 'react-bootstrap';
import FeaturedItem from '../../../components/frontend/home/FeaturedItem';

const FeaturedBox = () => {
      return (
            <>
                  <FeaturedSection>
                        <Container>
                              <FeaturedContainer>
                                    <FeaturedItem
                                          image="/images/svg/snowflake.svg"
                                          title="Green Cooling Power"
                                    />
                                    <FeaturedImage>
                                          <img
                                                src="/images/img/sitebanner/ac-object.png"
                                                alt="Innmotek featured pump logo"
                                                width="420"
                                                height="250"
                                          />
                                    </FeaturedImage>
                                    <FeaturedItem
                                          image="/images/svg/sun.svg"
                                          title="High Pressure Protection "
                                          color="#FFFFFF"
                                    />
                              </FeaturedContainer>
                        </Container>
                  </FeaturedSection>
            </>
      );
};

export default FeaturedBox;
