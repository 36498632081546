import React from 'react';
import { HeaderLinkItem } from '../../../styles/layouts/header';
import { Link } from 'react-router-dom';

const SocialMediaLink = ({ icon, link, title }) => {
   return (
      <>
         <HeaderLinkItem>
            <Link to={link} target="_blank">
               {icon}
            </Link>
         </HeaderLinkItem>
      </>
   );
};

export default SocialMediaLink;
