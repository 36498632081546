import React from 'react';

const BirdIcon = () => {
      return (
            <>
                  <svg
                        id="fi_5688426"
                        height="512"
                        viewBox="0 0 64 64"
                        width="512"
                        xmlns="http://www.w3.org/2000/svg"
                  >
                        <g>
                              <g>
                                    <path d="m29 56c-.2 0-.4 0-.5-.1-.8-.2-1.4-.6-1.8-1.3-.3-.5-.3-1.2-.3-1.6 0-.1 0-.1 0-.2v-.1c0-.1-.1-.1-.2-.1-.7-.2-1.2-.7-1.5-1.3-.3-.7-.3-1.2-.3-1.6 0-.1 0-.1 0-.2v-.1c0-.1-.1-.1-.2-.1-.7-.2-1.2-.7-1.5-1.3-.4-.6-.5-1.4-.3-2.1.2-.8.8-1.4 1.5-1.7.1 0 .1 0 .2-.1l6.9-1c.4-.1.8.2.9.6s-.2.8-.6.9l-6.8 1c-.3.2-.5.4-.6.7s0 .7.1 1c.2.3.4.5.6.6.4.1.8.4 1 .7.3.4.4.8.3 1.2 0 .3 0 .5.2.9.1.3.3.4.6.5.4.1.8.4 1 .7s.4.8.3 1.2c0 .3 0 .6.1.8.2.3.4.5.8.6.3.1.7 0 .9-.2l5.6-6.7c.3-.3.7-.4 1.1-.1.3.3.4.7.1 1.1l-5.6 6.8-.1.1c-.7.3-1.3.5-1.9.5z"></path>
                              </g>
                              <g>
                                    <path d="m22.5 39.8c-.1 0-.3 0-.4-.1-9.2-5.7-8.1-20.8-8-21.4 0-.3.2-.5.5-.6s.6 0 .8.2c5.3 5.7 10.7 9.5 16.2 11.6.4.1.6.6.4 1-.1.4-.6.6-1 .4-5.4-2-10.5-5.5-15.6-10.7 0 4.1.7 14.2 7.4 18.3.4.2.5.7.2 1 0 .2-.2.3-.5.3z"></path>
                              </g>
                              <g>
                                    <path d="m34 49.1c-1.4 0-2.6-.6-3.4-1.8-.6-.9-.7-2.2-.2-3.4.2-.8.6-1.5 1-2.3-1.9.1-3.7 0-5.4-.4-.4-.1-.7-.5-.6-.9s.5-.7.9-.6c1.8.4 4 .5 6.2.2.3 0 .5.1.7.3s.2.5.1.8c0 0-.2.5-.5 1.1-.4.8-.8 1.5-1 2.3-.3.8-.3 1.5 0 2 .6.9 1.6 1.3 2.7 1.1 11.8-2.4 12-7.9 12.1-12.7 0-1.5.1-2.8.5-4s1.4-2.4 2.1-3.2c-1-.8-1.9-1.2-2.5-1.1-.8.1-1.5 1.2-2.2 2.3-.3.5-.6.9-.9 1.4-1.4 1.7-3.5 2.6-5.5 2.4-1-.1-1.9-.3-3-.5-.4-.1-.6-.5-.5-.9s.5-.6.9-.5c1 .3 1.9.4 2.9.5 1.4.2 3-.5 4.1-1.8.3-.4.5-.8.8-1.2.9-1.4 1.8-2.8 3.3-3 1.2-.2 2.6.5 4.3 2 .2.1.2.3.2.5s-.1.4-.2.5c-.5.5-1.9 2-2.3 3.2-.3 1-.4 2.3-.4 3.6-.1 4.8-.3 11.5-13.4 14.2-.2-.2-.5-.1-.8-.1z"></path>
                              </g>
                              <g>
                                    <path d="m31 30.7c-.4 0-.7-.3-.7-.7-.7-7.7 1.6-14 7.3-19.3.2-.2.5-.3.8-.1.3.1.5.4.5.7 0 4.5.3 7.8 1.1 10.7.1.4-.1.8-.5.9s-.8-.1-.9-.5c-.7-2.6-1.1-5.5-1.2-9.3-4.4 4.7-6.2 10.2-5.6 16.8 0 .4-.3.8-.8.8z"></path>
                              </g>
                              <g>
                                    <path d="m42.6 30.8c-.2 0-.5-.1-.6-.3-.8-1.2-1.5-2.5-2.3-4.2-.2-.4 0-.8.4-1s.8 0 1 .4c.7 1.7 1.3 2.8 2.1 4 .2.3.2.8-.2 1-.1.1-.2.1-.4.1z"></path>
                              </g>
                              <g>
                                    <path d="m20 30.3c-.1 0-.1 0-.2 0s-2.7-.7-4.7-3c-.3-.3-.2-.8.1-1.1s.8-.2 1.1.1c1.7 2 4 2.6 4 2.6.4.1.6.5.5.9-.2.3-.5.5-.8.5z"></path>
                              </g>
                              <g>
                                    <path d="m35.8 19.1c-.3 0-.7-.2-.7-.6 0 0-.2-1.2-1.2-2.4-.2-.3-.2-.8.2-1 .3-.2.8-.2 1 .2 1.1 1.5 1.4 2.9 1.4 3 .1.4-.2.8-.6.9 0-.2 0-.1-.1-.1z"></path>
                              </g>
                              <g>
                                    <path d="m34.7 23.8c-.3 0-.6-.2-.7-.5 0 0-.5-1.7-1.9-3.3-.3-.3-.2-.8.1-1.1s.8-.2 1.1.1c1.6 1.9 2.2 3.8 2.2 3.9.1.4-.1.8-.5.9-.2 0-.2 0-.3 0z"></path>
                              </g>
                              <g>
                                    <path d="m32.8 26.7c-.2 0-.5-.1-.6-.3-.4-.5-.8-1.1-1.4-1.5-.3-.3-.4-.7-.1-1.1.3-.3.7-.4 1.1-.1.7.6 1.3 1.3 1.7 1.8.2.3.2.8-.1 1.1-.3.1-.5.1-.6.1z"></path>
                              </g>
                              <g>
                                    <path d="m21.2 35.6c-.1 0-1.7 0-3.6-.8-.4-.2-.6-.6-.4-1s.6-.6 1-.4c1.6.7 3 .7 3 .7.4 0 .7.3.7.8s-.2.7-.7.7z"></path>
                              </g>
                              <g>
                                    <path d="m32.5 41.6c-.1 0-.2 0-.2 0-.4-.1-.6-.6-.5-1 .1-.2 1.9-5.5 7.5-9.3.3-.2.8-.1 1 .2s.1.8-.2 1c-5.1 3.5-6.9 8.5-6.9 8.6-.1.3-.4.5-.7.5z"></path>
                              </g>
                              <g>
                                    <path d="m51.3 29c-.1 0-.3 0-.4-.1l-1.2-.8c-.3-.2-.4-.7-.2-1s.7-.4 1-.2l1.2.8c.3.2.4.7.2 1-.1.2-.3.3-.6.3z"></path>
                              </g>
                              <g>
                                    <path d="m46.8 28.1c-.2 0-.4-.1-.5-.2s-.2-.3-.2-.5.1-.4.2-.5c.3-.3.8-.3 1.1 0 .1.1.2.3.2.5s-.1.4-.2.5c-.2.2-.4.2-.6.2z"></path>
                              </g>
                              <g>
                                    <path d="m32 58.7c-14.6 0-26.5-12-26.5-26.7s11.9-26.7 26.5-26.7 26.5 12 26.5 26.7-11.9 26.7-26.5 26.7zm0-51.9c-13.8 0-25 11.3-25 25.2s11.2 25.2 25 25.2 25-11.3 25-25.2-11.2-25.2-25-25.2z"></path>
                              </g>
                              <g>
                                    <path d="m12.7 49.6c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1l36-36.3c.3-.3.8-.3 1.1 0s.3.8 0 1.1l-36.1 36.3c-.1.2-.3.2-.5.2z"></path>
                              </g>
                              <g>
                                    <path d="m15.2 52.2c-.2 0-.4-.1-.5-.2-.3-.3-.3-.8 0-1.1l36.1-36.4c.3-.3.8-.3 1.1 0s.3.8 0 1.1l-36.1 36.4c-.2.1-.4.2-.6.2z"></path>
                              </g>
                              <g>
                                    <path d="m32 62.6c-16.7 0-30.3-13.7-30.3-30.6s13.6-30.6 30.3-30.6 30.3 13.7 30.3 30.6-13.6 30.6-30.3 30.6zm0-59.7c-15.9 0-28.8 13.1-28.8 29.1s12.9 29.1 28.8 29.1 28.8-13 28.8-29.1c0-16-12.9-29.1-28.8-29.1z"></path>
                              </g>
                        </g>
                  </svg>
            </>
      );
};

export default BirdIcon;
