import styled from 'styled-components';
import { RadiatorInfoImage } from '../RadiatorInfo/styles';
import { RadiatorSliderImage } from '../RadiatorSlider/styles';

export const RadiatorCategoryContainer = styled.div`
      padding: 60px 0px;
      h3 {
            text-align: center;
            text-transform: capitalize;
            span {
                  color: ${(props) => props.theme.secondary};
            }
      }
`;

export const RadiatorCategoryTitle = styled.div``;
export const RadiatorCategoryContent = styled.div`
      position: relative;
      ${RadiatorSliderImage} {
            position: relative;
            z-index: -1;
      }
      ${RadiatorCategoryTitle} {
            position: absolute;
            z-index: 1000;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 55px;
            background: #0000006e;
            color: #ffffff;
         
      }
`;
