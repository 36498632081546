import React, { useEffect, useState } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { Col, Container, Row } from 'react-bootstrap';

import {
      CategoryBackground,
      CategoryContent,
      CategoryItem,
      CategoryItemImage
} from '../category/styles';
import axios from 'axios';
import { Link, useLocation, useParams } from 'react-router-dom';
import ParentSkeleton from '../../components/skeletons/ParentSkeleton';
import RecommendedProduct from '../../components/frontend/products/RecommendedProduct';
import PageNotFound from '../page-not-found';
import { Helmet } from 'react-helmet';

const Product = () => {
      const { categoryId } = useParams();
      const [loading, setLoading] = useState(false);
      const [products, setProducts] = useState([]);
      const [category, setCategory] = useState([]);

      const [relatedProducts, setRelatedProducts] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/products/${categoryId}`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProducts(response.data.products);
                              setCategory(response.data.category);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/products/related/${categoryId}`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setRelatedProducts(response.data.products);
                        }
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [useLocation().pathname]);

      return (
            <>
                  {!loading ? (
                        <Helmet>
                              <title>
                                    {category.seo_title
                                          ? category.seo_title
                                          : category.title}
                              </title>
                              {category.seo_description && (
                                    <meta
                                          name="description"
                                          content={category.seo_description}
                                    />
                              )}
                              {category.seo_keyword && (
                                    <meta
                                          name="keyword"
                                          content={category.seo_keyword}
                                    />
                              )}
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                        </Helmet>
                  ) : null}
                  {products?.length > 0 ? (
                        <BreadcrumbBox
                              linkTitle={category.parentTitle}
                              link={`/category/${category.parentLink}`}
                              title={category.title}
                        />
                  ) : (
                        ''
                  )}

                  <CategoryBackground
                        className={
                              !loading ? products?.length === 0 && 'pb-0' : null
                        }
                  >
                        <Container>
                              <Row className="g-4">
                                    {!loading ? (
                                          products?.length > 0 ? (
                                                products?.map((item, index) => (
                                                      <Col lg={4} key={index}>
                                                            <CategoryItem>
                                                                  <CategoryItemImage height="320px">
                                                                        <img
                                                                              src={
                                                                                    item.image
                                                                              }
                                                                              alt={
                                                                                    item.title
                                                                              }
                                                                              width="100%"
                                                                              height="100%"
                                                                        />
                                                                  </CategoryItemImage>
                                                                  <CategoryContent>
                                                                        <h2>
                                                                              {
                                                                                    item.title
                                                                              }
                                                                        </h2>
                                                                        <div
                                                                              dangerouslySetInnerHTML={{
                                                                                    __html: item.summary
                                                                              }}
                                                                        ></div>
                                                                        <div className="mt-auto">
                                                                              <Link
                                                                                    className="w-100 text-center"
                                                                                    to={`/products/${item.slug}`}
                                                                              >
                                                                                    Show
                                                                                    More
                                                                              </Link>
                                                                        </div>
                                                                  </CategoryContent>
                                                            </CategoryItem>
                                                      </Col>
                                                ))
                                          ) : (
                                                <PageNotFound
                                                      fontSize="5 vw"
                                                      title="Coming Soon"
                                                      subTitle="Our products are coming soon."
                                                      desc="We are working on it. Subscribe our newsletter to get updated about our latest products"
                                                />
                                          )
                                    ) : (
                                          <>
                                                <Col lg={4}>
                                                      <ParentSkeleton />
                                                </Col>
                                                <Col lg={4}>
                                                      <ParentSkeleton />
                                                </Col>
                                                <Col lg={4}>
                                                      <ParentSkeleton />
                                                </Col>
                                          </>
                                    )}
                              </Row>
                        </Container>
                  </CategoryBackground>
                  <div style={{ backgroundColor: '#EEEEEE' }}>
                        <RecommendedProduct
                              title="Recommended Products"
                              loading={loading}
                              products={relatedProducts}
                              otherProducts={products}
                        />
                  </div>
            </>
      );
};

export default Product;
