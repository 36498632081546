import styled from 'styled-components';

export const BreadcrumbContainer = styled.div`
   padding: 50px 0;
   background-size: cover;
   background-position: center center;
   background-repeat: no-repeat;
   h1 {
      font-family: ${(props) => props.theme.primaryFont};
   }
   a {
      color: ${(props) => props.theme.primary};
   }

   @media only screen and (max-width: 568px) {
      padding: 20px 0;
   }
`;
