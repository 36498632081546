import React, { useEffect, useState } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import {
      CategoryDetailContainer,
      CategoryDetailDescription,
      CategoryDetailHeading,
      CategoryDetailSection
} from './style';
import {
      Col,
      Container,
      Form,
      FormControl,
      FormLabel,
      Row
} from 'react-bootstrap';
import IconCheckmark from '../../components/icons/IconCheckmark';
import ApplicableMaterial from './components/ApplicableMaterial';
import { ContactForm, ContactSlider } from '../contacts/styles';
import FormButton from '../../components/Form/FormButton';
import ContactItemBox from '../../components/common/ContactItemBox';
import MessageIcon from '../../icons/MessageIcon';
import ContactIcon from '../../icons/ContactIcon';
import MapIcon from '../../icons/MapIcon';
import { BlogDetailContainer } from '../blogs/BlogDetail/styles';
import axios from 'axios';
import RecommendedProduct from '../../components/frontend/products/RecommendedProduct';

const CategoryDetail = ({ title }) => {
      const [loading, setLoading] = useState(false);
      const [products, setProducts] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/products/related/heat-pump`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProducts(response.data.products);
                        }
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <BreadcrumbBox title="Heat Pump Air Dryer" />
                  <CategoryDetailContainer className="my-5">
                        <Container>
                              <Row className="g-5">
                                    <Col lg={6}>
                                          <CategoryDetailDescription className="mb-4">
                                                <p className="text-justify">
                                                      <span>
                                                            Heat pump dehydrator
                                                      </span>
                                                      (also known as heat pump
                                                      dryer) can provide a good
                                                      drying solution. Can save
                                                      75% of electricity, low
                                                      power consumption. The
                                                      product uses an
                                                      intelligent control panel
                                                      (PLC) to control
                                                      temperature, humidity, and
                                                      drying time. In addition
                                                      to hot air drying, it also
                                                      has a dehumidification
                                                      function, which can
                                                      shorten the drying time.
                                                      Low-temperature drying
                                                      (adjustable from 18°C to
                                                      80°C), the material can
                                                      maintain a good
                                                      appearance, color,
                                                      texture, smell, nutrients
                                                      and essential ingredients.
                                                      The machine and pallet are
                                                      stainless steel 304, very
                                                      durable, with a lifespan
                                                      of more than 10 years. It
                                                      comes with WiFi control
                                                      and can monitor the drying
                                                      process from your phone.
                                                </p>
                                                <FormButton title="Download PDF" />
                                          </CategoryDetailDescription>
                                    </Col>
                                    <Col lg={6}>
                                          <img
                                                src="/images/img/category/categorydetail/category-2.png"
                                                alt={title}
                                                className="img-fluid"
                                          />
                                    </Col>
                                    <Col lg={12}>
                                          <Row>
                                                <Col lg={3}>
                                                      <img
                                                            src="/images/img/category/categorydetail/categorydetail1.webp"
                                                            alt=""
                                                            className="img-fluid"
                                                      />
                                                </Col>
                                                <Col lg={3}>
                                                      <img
                                                            src="/images/img/category/categorydetail/categorydetail2.webp"
                                                            alt=""
                                                            className="img-fluid"
                                                      />
                                                </Col>
                                                <Col lg={3}>
                                                      <img
                                                            src="/images/img/category/categorydetail/categorydetail3.webp"
                                                            alt=""
                                                            className="img-fluid"
                                                      />
                                                </Col>
                                                <Col lg={3}>
                                                      <img
                                                            src="/images/img/category/categorydetail/categorydetail4.webp"
                                                            alt=""
                                                            className="img-fluid"
                                                      />
                                                </Col>
                                          </Row>
                                    </Col>
                                    <Col lg={6}>
                                          <CategoryDetailDescription>
                                                <CategoryDetailHeading>
                                                      <h3>
                                                            What are Heat Pump
                                                            Dehydrator?
                                                      </h3>
                                                </CategoryDetailHeading>
                                                <p>
                                                      heat pump based
                                                      condensation drying
                                                      reconciles all these
                                                      seemingly conflicting
                                                      qualities: 75% energy
                                                      saving, low temperatures,
                                                      gentle drying, short
                                                      drying cycles, quality
                                                      results, customized air
                                                      routeing, integrated heat
                                                      pump technology,
                                                      energetically open and
                                                      closed system. Fancy
                                                      innovation? We made a
                                                      compare test of the heat
                                                      pump dryer and convetional
                                                      electric heater dryer
                                                      machine Input 1kW
                                                      electricity, absorb 3kW
                                                      air source energy, output
                                                      4kWheat energyHeat Energy
                                                      Efficiency 400%
                                                </p>
                                                <Col>
                                                      <img
                                                            src="/images/img/category/categorydetail/category1.png"
                                                            alt=""
                                                            className="img-fluid mb-5"
                                                      />
                                                </Col>
                                                <CategoryDetailHeading>
                                                      <h3>
                                                            {' '}
                                                            Working Principle of
                                                            Heat pump
                                                            drying(HPD)
                                                      </h3>
                                                </CategoryDetailHeading>
                                                <CategoryDetailDescription className="mb-5">
                                                      <p>
                                                            The principle of the
                                                            HPD is to use the
                                                            Carnot cycle
                                                            coolingandheatingprinciple,
                                                            to power compressor
                                                            by electricity which
                                                            drives
                                                            therefrigerant to
                                                            absorb the energy in
                                                            the air and transfer
                                                            it tothedryingroom.
                                                            The temperature in
                                                            the drying room
                                                            rises and the
                                                            humiditydrops,then
                                                            material to be
                                                            dried.
                                                      </p>

                                                      <p>
                                                            HPD system consists
                                                            of evaporator,
                                                            condenser,
                                                            compressor, fan,
                                                            expansionvalve and a
                                                            drying chamber with
                                                            trays or trolleys of
                                                            trays
                                                      </p>
                                                      <div className="highlight">
                                                            Key Advantage: 75%
                                                            energy saving
                                                      </div>
                                                </CategoryDetailDescription>
                                          </CategoryDetailDescription>
                                    </Col>
                                    <Col lg={6}>
                                          <img
                                                src="/images/img/category/categorydetail/heat-pump-drying-machine5 (1).jpg"
                                                className="img-fluid"
                                          />
                                    </Col>
                              </Row>
                        </Container>

                        <CategoryDetailSection className="py-5">
                              <Container>
                                    <CategoryDetailDescription>
                                          <CategoryDetailHeading className="mb-3">
                                                <h3>
                                                      Why Open and closed loop
                                                      heat pump?
                                                </h3>
                                          </CategoryDetailHeading>
                                          <Row className="g-5 ">
                                                <Col lg={6}>
                                                      <h4 className="mb-3">
                                                            Open Loop HPD:
                                                            <br />
                                                      </h4>
                                                      <p>
                                                            Similar
                                                            asairconditioner,
                                                            cold
                                                            exchangeratoutsideto
                                                            adsorb the
                                                            heat(evaporator),hotexchanger
                                                            at
                                                            insidetoreleasetheheat(condenser).
                                                            Input
                                                            1kWelectricitytransfer
                                                            3kWheat energy.
                                                            Movetheenergy
                                                            outside
                                                            thedryingroomintothe
                                                            room, energy
                                                            transfer
                                                            insteadofconversion.
                                                            Conventional
                                                            electricheaterdryer:1Kw
                                                            can only
                                                            produce0.8Kwofheatenergy,
                                                            which canonlyremovem
                                                            o i s t u r e a n d
                                                            dr y, but
                                                            cannotdehumidify.
                                                      </p>
                                                      <p>
                                                            <div className="highlight mb-3">
                                                                  Key
                                                                  Advantage：
                                                            </div>
                                                            <ul className="ps-0">
                                                                  <li className="d-flex align-items-top">
                                                                        <IconCheckmark />
                                                                        <p>
                                                                              The
                                                                              temperature
                                                                              can
                                                                              reach
                                                                              80℃
                                                                              or
                                                                              even
                                                                              higher.
                                                                        </p>
                                                                  </li>
                                                                  <li className="d-flex align-items-top">
                                                                        <IconCheckmark />
                                                                        <p>
                                                                              Very
                                                                              suitable
                                                                              for
                                                                              the
                                                                              second
                                                                              half
                                                                              of
                                                                              the
                                                                              drying
                                                                              cycle.
                                                                        </p>
                                                                  </li>
                                                                  <li className="d-flex align-items-top">
                                                                        <IconCheckmark />
                                                                        <p>
                                                                              High
                                                                              energy
                                                                              efficiency
                                                                              in
                                                                              summer
                                                                              and
                                                                              autumn,
                                                                              3
                                                                              times
                                                                              higher
                                                                              than
                                                                              conventional
                                                                              electric
                                                                              heater
                                                                              dryer.
                                                                        </p>
                                                                  </li>
                                                            </ul>
                                                      </p>
                                                </Col>
                                                <Col lg={6}>
                                                      <img
                                                            src="/images/img/category/categorydetail/category2.png"
                                                            alt={title}
                                                            className="img-fluid"
                                                      />
                                                </Col>
                                                <Col lg={6}>
                                                      <img
                                                            src="/images/img/category/categorydetail/category3.png"
                                                            alt={title}
                                                            className="img-fluid"
                                                      />
                                                </Col>
                                                <Col lg={6}>
                                                      <p>
                                                            <div className="highlight mb-3">
                                                                  Major
                                                                  Defects：
                                                            </div>
                                                            <ul className="ps-0">
                                                                  <li className="d-flex align-items-top">
                                                                        <IconCheckmark />
                                                                        <p>
                                                                              It
                                                                              must
                                                                              discharge
                                                                              the
                                                                              hot
                                                                              humidity
                                                                              air
                                                                              duringdrying,the
                                                                              more
                                                                              hot
                                                                              humidity
                                                                              air
                                                                              goes
                                                                              out,
                                                                              the
                                                                              greater
                                                                              theheat
                                                                              loss.
                                                                        </p>
                                                                  </li>
                                                                  <li className="d-flex align-items-top">
                                                                        <IconCheckmark />
                                                                        <p>
                                                                              The
                                                                              temperature
                                                                              rise
                                                                              in
                                                                              the
                                                                              drying
                                                                              roomisimpactedby
                                                                              the
                                                                              ambient
                                                                              temperature
                                                                        </p>
                                                                  </li>
                                                            </ul>
                                                      </p>
                                                </Col>
                                          </Row>
                                          <Row className="g-5 my-4">
                                                <Col lg={6}>
                                                      <h4 className="mb-3">
                                                            Closed Loop HPD:
                                                            <br />
                                                      </h4>
                                                      <p>
                                                            {' '}
                                                            Similar to
                                                            dehumidifier,
                                                            steamiscondensedin
                                                            the condenser
                                                            (temperaturedrop),waterevaporate
                                                            in the
                                                            evaporator(temperaturerise).
                                                            The cooling and heat
                                                            balance,
                                                            thecondensation
                                                            process will
                                                            releasealargeamount
                                                            of latent heat,
                                                            whichmeansmorecondensed
                                                            water more heat.
                                                            <br />
                                                            But if no condensed
                                                            water,
                                                            thennoheat,energy
                                                            consumption will
                                                            behigherthanelectric
                                                            heater dryer,
                                                            largeextraauxiliaryelectric
                                                            heater is required.
                                                      </p>
                                                      <p>
                                                            <div className="highlight mb-3">
                                                                  Key
                                                                  Advantage：
                                                            </div>
                                                            <ul className="ps-0">
                                                                  <li className="d-flex align-items-top">
                                                                        <IconCheckmark />
                                                                        <p>
                                                                              More
                                                                              efficient
                                                                              than
                                                                              the
                                                                              open
                                                                              loop
                                                                              system,
                                                                              because
                                                                              product
                                                                              with
                                                                              high
                                                                              humidity
                                                                              but
                                                                              low
                                                                              temperature
                                                                              drying.
                                                                        </p>
                                                                  </li>
                                                                  <li className="d-flex align-items-top">
                                                                        <IconCheckmark />
                                                                        <p>
                                                                              Can
                                                                              collect
                                                                              the
                                                                              valuable
                                                                              condensate
                                                                              or
                                                                              its
                                                                              ingredients
                                                                              for
                                                                              flowers
                                                                              or
                                                                              herbs
                                                                              etc
                                                                        </p>
                                                                  </li>
                                                                  <li className="d-flex align-items-top">
                                                                        <IconCheckmark />
                                                                        <p>
                                                                              No
                                                                              interchange
                                                                              with
                                                                              ambient
                                                                              air,
                                                                              still
                                                                              high
                                                                              efficiency
                                                                              even
                                                                              outside
                                                                              temperature
                                                                              is
                                                                              very
                                                                              low.
                                                                        </p>
                                                                  </li>
                                                            </ul>
                                                      </p>
                                                </Col>
                                                <Col lg={6}>
                                                      <img
                                                            src="/images/img/category/categorydetail/category4.png"
                                                            alt={title}
                                                            className="img-fluid"
                                                      />
                                                </Col>
                                                <Col lg={6}>
                                                      <img
                                                            src="/images/img/category/categorydetail/category5.png"
                                                            alt={title}
                                                            className="img-fluid"
                                                      />
                                                </Col>
                                                <Col lg={6}>
                                                      <p>
                                                            <div className="highlight mb-3">
                                                                  Major
                                                                  Defects：
                                                            </div>
                                                            <ul className="ps-0">
                                                                  <li className="d-flex align-items-top">
                                                                        <IconCheckmark />
                                                                        <p>
                                                                              High
                                                                              energy
                                                                              consumption
                                                                              for
                                                                              secondhalf
                                                                              of
                                                                              dryingcircle
                                                                        </p>
                                                                  </li>
                                                                  <li className="d-flex align-items-top">
                                                                        <IconCheckmark />
                                                                        <p>
                                                                              Can
                                                                              not
                                                                              high
                                                                              temperature
                                                                              drying
                                                                        </p>
                                                                  </li>
                                                                  <li className="d-flex align-items-top">
                                                                        <li className="d-flex align-items-top">
                                                                              <IconCheckmark />
                                                                              <p>
                                                                                    Can
                                                                                    not
                                                                                    use
                                                                                    cool
                                                                                    dry
                                                                                    and
                                                                                    cool
                                                                                    dehumidify
                                                                              </p>
                                                                        </li>
                                                                  </li>
                                                            </ul>
                                                      </p>
                                                </Col>
                                          </Row>
                                    </CategoryDetailDescription>
                                    <ApplicableMaterial />
                              </Container>
                        </CategoryDetailSection>
                        <BlogDetailContainer>
                              <Row className="g-5">
                                    <Col lg={6}>
                                          <img
                                                src="/images/img/applicable/contact.jpg"
                                                className="img-fluid mb-4"
                                          />
                                          <ContactSlider
                                                items={3}
                                                margin={20}
                                                className="owl-theme"
                                          >
                                                <ContactItemBox
                                                      icon={<MessageIcon />}
                                                      title="Email Address"
                                                >
                                                      <p>info@innmotek.com</p>
                                                </ContactItemBox>
                                                <ContactItemBox
                                                      icon={<ContactIcon />}
                                                      title="Phone Number"
                                                >
                                                      <p>+91 98212 13350</p>
                                                </ContactItemBox>
                                                <ContactItemBox
                                                      icon={<MapIcon />}
                                                      title="Office Address"
                                                >
                                                      <p>
                                                            Uttar Pradesh,
                                                            India, 273001
                                                      </p>
                                                </ContactItemBox>
                                          </ContactSlider>
                                    </Col>
                                    <Col lg={6}>
                                          <p>
                                                Take advantage of our unrivaled
                                                knowledge and experience, we
                                                offer you the best customization
                                                service.
                                          </p>
                                          <ContactForm>
                                                <Form>
                                                      <Row className="g-4">
                                                            <Col lg={6}>
                                                                  <FormLabel>
                                                                        First
                                                                        Name
                                                                  </FormLabel>
                                                                  <FormControl type="text" />
                                                            </Col>
                                                            <Col lg={6}>
                                                                  <FormLabel>
                                                                        Last
                                                                        Name
                                                                  </FormLabel>
                                                                  <FormControl type="text" />
                                                            </Col>
                                                            <Col lg={6}>
                                                                  <FormLabel>
                                                                        Email
                                                                        Address
                                                                  </FormLabel>
                                                                  <FormControl type="text" />
                                                            </Col>
                                                            <Col lg={6}>
                                                                  <FormLabel>
                                                                        Phone
                                                                        No.
                                                                  </FormLabel>
                                                                  <FormControl type="text" />
                                                            </Col>
                                                            <Col lg={12}>
                                                                  <FormLabel>
                                                                        Message
                                                                  </FormLabel>
                                                                  <FormControl
                                                                        as="textarea"
                                                                        rows={4}
                                                                        type="text"
                                                                  />
                                                            </Col>
                                                            <Col lg={12}>
                                                                  <FormButton title="Send Your Inquiry" />
                                                            </Col>
                                                      </Row>
                                                </Form>
                                          </ContactForm>
                                    </Col>
                                    <Col></Col>
                              </Row>
                        </BlogDetailContainer>
                  </CategoryDetailContainer>
                  <RecommendedProduct
                        title="Recommended Products"
                        loading={loading}
                        products={products}
                  />
            </>
      );
};

export default CategoryDetail;
