export const radiatorcategories = [
      {
            id: 1,
            title: 'Designer Radiator',
            image: '/images/img/radiator/category1.jpg'
      },
      {
            id: 2,
            title: 'Designer Towel Radiator',
            image: '/images/img/radiator/category2.png'
      },
      {
            id: 3,
            title: 'Column Radiator',
            image: '/images/img/radiator/category3.png'
      },
      {
            id: 4,
            title: 'Steel Panel Radiator',
            image: '/images/img/radiator/category4.png'
      },
      {
            id: 5,
            title: 'Bimetal Radiator',
            image: '/images/img/radiator/category5.png'
      },
      {
            id: 6,
            title: 'Copper Aluminum Composite',
            image: '/images/img/radiator/category6.png'
      },

      {
            id: 7,
            title: 'Modern Radiator',
            image: '/images/img/radiator/category7.png'
      }
];
