import React from 'react';
import { SwiperSlide } from 'swiper/react';
import {
   BlogSection,
   BlogSlider,
   TestimonialHeading
} from '../../../styles/frontend/home';
import { Container } from 'react-bootstrap';
import BlogItemBox from '../../../components/common/BlogItemBox';
import { useState } from 'react';
import { useEffect } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import BlogSkeleton from '../../../components/skeletons/BlogSkeleton';

const BlogBox = () => {
   const [loading, setLoading] = useState([]);
   const [blogs, setBlogs] = useState(false);

   const loadData = async () => {
      setLoading(true);

      await axios
         .get(`${process.env.REACT_APP_SECRET_KEY}/api/blogs?limit=5`)
         .then((response) => {
            if (response.data.result === 'success') {
               setBlogs(response.data.blogs);
            }
         })
         .catch((error) => {
            toast.error(error.message);
         });

      setLoading(false);
   };
   useEffect(() => {
      loadData();
   }, []);

   return (
      <>
         <BlogSection>
            <Container>
               <TestimonialHeading>
                  <h2>
                     News & Blogs
                     <span class="ms-2"></span>
                  </h2>
                  <h3>Read Our Latest News & Blogs.</h3>
               </TestimonialHeading>
               {!loading ? (
                  blogs?.length > 0 && (
                     <BlogSlider
                        slidesPerView={1}
                        spaceBetween={10}
                        breakpoints={{
                           640: {
                              slidesPerView: 2,
                              spaceBetween: 20
                           },
                           768: {
                              slidesPerView: 3,
                              spaceBetween: 20
                           },
                           1200: {
                              slidesPerView: 3,
                              spaceBetween: 40
                           }
                        }}
                     >
                        {blogs.map((blog, index) => (
                           <SwiperSlide key={index}>
                              <BlogItemBox height="80px" blog={blog} />
                           </SwiperSlide>
                        ))}
                     </BlogSlider>
                  )
               ) : (
                  <>
                     <BlogSlider
                        slidesPerView={1}
                        spaceBetween={10}
                        breakpoints={{
                           640: {
                              slidesPerView: 2,
                              spaceBetween: 20
                           },
                           768: {
                              slidesPerView: 3,
                              spaceBetween: 20
                           },
                           1200: {
                              slidesPerView: 3,
                              spaceBetween: 40
                           }
                        }}
                     >
                        <SwiperSlide>
                           <BlogSkeleton />
                        </SwiperSlide>
                        <SwiperSlide>
                           <BlogSkeleton />
                        </SwiperSlide>
                        <SwiperSlide>
                           <BlogSkeleton />
                        </SwiperSlide>
                     </BlogSlider>
                  </>
               )}
            </Container>
         </BlogSection>
      </>
   );
};

export default BlogBox;
