import React from 'react';
import {
      FeaturedContent,
      FeaturedIcon,
      FeaturedTitle
} from '../../../../styles/frontend/home';

const FeaturedItem = ({ title, image, color }) => {
      return (
            <>
                  <FeaturedContent justifyContent={true}>
                        <FeaturedIcon>
                              <img
                                    src={image}
                                    alt={title}
                                    width="100%"
                                    height="100%"
                              />
                        </FeaturedIcon>
                        <FeaturedTitle color={color}>
                              <p class="w-75 m-auto">{title}</p>
                        </FeaturedTitle>
                  </FeaturedContent>
            </>
      );
};

export default FeaturedItem;
