import React from 'react';
import {
   AboutBenefit,
   AboutBody,
   AboutButton,
   AboutContent,
   AboutImage,
   AboutSection,
   TestimonialHeading
} from '../../../styles/frontend/home';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AboutBenefitItemBox from '../../../components/frontend/home/AboutBenefitItemBox';

const AboutBox = ({ more }) => {
   return (
      <>
         <AboutSection>
            <Container>
               <Row>
                  <Col lg={5} md={5}>
                     <AboutImage>
                        <img
                           src="/images/img/about/Group 3.png"
                           alt="Innmotek Pvt Ltd"
                           class="img-fluid"
                           width="100%"
                           height="100%"
                        />
                     </AboutImage>
                  </Col>
                  <Col lg={7} md={7}>
                     <AboutContent>
                        <TestimonialHeading margin="1rem">
                           <h2>
                              About Company <span class="ms-2"></span>
                           </h2>
                           <h3>Premier Heating Solution!</h3>
                        </TestimonialHeading>
                        <AboutBody>
                           <p class="mb-5">
                              Innmotek is a leading supplier of Hot Water Heat
                              Pumps, Swimming Pool Heat Pumps, and Accessories
                              in Residential, Commercial, and Industrial Area.
                              We have a team of experienced professionals who
                              can help you choose the right product for your
                              needs.
                           </p>
                           <AboutBenefit>
                              <AboutBenefitItemBox
                                 title="Our Mission"
                                 image="/images/svg/target.svg"
                                 style={true}
                                 description="We want to make sure you have the information and resources you need to make the best decision for your needs. "
                              />
                              <AboutBenefitItemBox
                                 title="Our Values"
                                 image="/images/svg/loyalty.svg"
                                 description="We believe that our customers are our top priority, and we are always working to improve our products and services to meet your needs."
                              />
                           </AboutBenefit>
                        </AboutBody>
                        {!more ? (
                           <AboutButton>
                              <Link
                                 to="/about-us"
                                 aria-label="Innmotek is a leading supplier of Hot Water Heat Pumps, Swimming Pool Heat Pumps, and Accessories"
                              >
                                 <span>Learn More</span>
                              </Link>
                           </AboutButton>
                        ) : null}
                     </AboutContent>
                  </Col>
               </Row>
            </Container>
         </AboutSection>
      </>
   );
};

export default AboutBox;
