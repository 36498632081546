import React from 'react';
import { SolarBannerContent, SolarBannerSection } from './styles';
import { Container } from 'react-bootstrap';

const SolarBanner = () => {
   return (
      <>
         <SolarBannerSection>
            <video
               playsinline="true"
               width="100%"
               style={{ objectFit: 'cover' }}
               autoplay="true"
               muted="true"
               loop="true"
               preload="auto"
            >
               <source
                  src="https://www.papaemmanouel.gr/wp-content/uploads/2023/10/20231010_094606.mp4"
                  type="video/mp4"
               />
            </video>
            <Container>
               <SolarBannerContent>
                  Superb <span>Efficiency</span> Solar flame collectors <br />{' '}
                  Superior <span>Quality</span> Solar flame collectors
                  <br />
                  <span>Open</span> or <span>Closed</span> loop For small- or
                  large-scale fields
               </SolarBannerContent>
            </Container>
         </SolarBannerSection>
      </>
   );
};

export default SolarBanner;
