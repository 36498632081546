import styled from 'styled-components';

export const SubscriberContainer = styled.div`
   width: 100%;
   padding: 50px 30px;
   margin-top: ${(props) => (props.margin ? props.margin : '3rem')};
   background-color: ${(props) => props.theme.primary};

   h2 {
      font-size: 24px;
      color: ${(props) => props.theme.white};
   }

   form {
      display: flex;

      .form-control {
         background-color: #f7f7fa;
         border-radius: 0;

         &:focus {
            box-shadow: none;
            border: none;
         }
      }

      button {
         background-color: ${(props) => props.theme.secondary};
         border: 0;
         color: ${(props) => props.theme.white};
         padding: 10px 20px;

         &:hover {
            background-color: #333;
         }
      }
   }
`;
