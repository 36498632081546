import styled from 'styled-components';

export const PoolSpaHeader = styled.div`
      position: relative;
      img {
            height: 200px;
            width: 100%;
      }
`;
export const PoolSpaHeading = styled.div`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(98deg, #00145e 0, #0c95b4);
      opacity: 0.9;
      overflow: hidden;
      h1 {
            position: relative;
            text-align: left;
            overflow: hidden;
            left: 7%;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
            z-index: 100;
            color: #fff;
      }
`;

export const PoolTopImage = styled.div`
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: -150px;
      position: relative;
      z-index: 1002;
      img {
            height: 280px;
            width: 280px;
            border-radius: 50%;
            border: 5px solid #fff;
      }
`;
export const PoolCoverContainer = styled.div``;
export const PoolCoverHeading = styled.div`
      h2 {
            font-size: 24px;
      }
`;
export const PoolCoverContent = styled.div`
      p {
            font-size: 16px;
            line-height: 1.6;
            letter-spacing: 0.3px;
      }
`;
export const PoolCoverTitle = styled.div`
      h3 {
            font-size: 20px;
            font-weight: 500;
            span {
                  color: #00145e;
            }
      }
`;

export const PoolCoverAdvantageIcon = styled.div`
      width: 70px;
      height: 70px;
      border-radius: 50%;
      border: 2px solid #00adee;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
            width: 3rem;
            height: 3rem;
            fill: none;
            stroke: #00adee;
      }
`;
export const PoolCoverAdvantage = styled.div``;

export const PoolCoverAccessoriesImage = styled.div`
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`;

export const PoolCoverSection = styled.div`
      background: #e2f4fd;
      ul li {
            list-style-type: none;
            span {
                  font-size: 16px;
                  font-weight: 500;
            }
      }
`;
