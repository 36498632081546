import React, { useEffect, useState } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import {
      PrivacyPolicyContainer,
      PrivacyPolicyContent,
      PrivacyPolicyNavigation
} from '../privacy-policy/styles';
import { Col, Row } from 'react-bootstrap';
import PrivacyPolicySlider from '../privacy-policy/PrivacyPolicySlider/PrivacyPolicySlider';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const CompanyPolicies = () => {
      const [loading, setLoading] = useState(false);
      const [policy, setPolicy] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/pages/company-policy`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setPolicy(response.data.page);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  {!loading ? (
                        <Helmet>
                              <title>
                                    {policy.seo_title
                                          ? policy.seo_title
                                          : policy.title}
                              </title>
                              {policy.seo_description && (
                                    <meta
                                          name="description"
                                          content={policy.seo_description}
                                    />
                              )}
                              {policy.seo_keyword && (
                                    <meta
                                          name="keyword"
                                          content={policy.seo_keyword}
                                    />
                              )}
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                        </Helmet>
                  ) : null}
                  <BreadcrumbBox title="Company Policy" />
                  <PrivacyPolicyContainer className="my-5">
                        <Row className="justify-content-center">
                              <Col lg={12}>
                                    <PrivacyPolicyContent className="py-5">
                                          <div>
                                                Welcome to Innmotek, where we
                                                prioritize transparency,
                                                integrity, and a positive
                                                working environment. Our company
                                                policy serves as a guiding
                                                framework for our operations,
                                                outlining the standards and
                                                expectations we uphold. By
                                                accessing and using our website,
                                                you agree to comply with the
                                                following policies:
                                          </div>
                                          <PrivacyPolicyNavigation className="mt-4">
                                                <PrivacyPolicySlider />
                                          </PrivacyPolicyNavigation>
                                    </PrivacyPolicyContent>
                              </Col>
                        </Row>
                  </PrivacyPolicyContainer>
            </>
      );
};

export default CompanyPolicies;
