import styled from 'styled-components';

export const ProductAdditionalContainer = styled.div`
      margin-top: 60px;
      background: ${(props) => props.theme.white};

      .nav-item {
            border: 0;

            button {
               border: 0;
            }
      }
      .product__additional ul li button {
            font-size: 18px;
            font-weight: 300;
            padding: 10px 20px;
      }
      .nav-tabs .nav-item.show .nav-link,
      .nav-tabs .nav-link.active {
      }

      .product__desc ul li::marker {
            color: var(--primary-color);
      }
      .product__desc {
            padding: 30px;

            table {
                  border: 1px solid;

                  tr > td:nth-child(odd) {
                        width: 200px !important;
                  }

                  tr > td:nth-child(even) {
                        width: auto !important;
                  }

                  tr:hover {
                        background-color: #00000030;
                  }
            }

            table > :not(caption) > * > * {
                  padding: 0.5rem 0.5rem;
                  border-width: 0 1px;
                  border-color: inherit;
                  border-style: solid;
                  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
            }

            table > :not(caption) > * {
                  border-width: 1px 0;
                  border-color: inherit;
                  border-style: solid;
            }
      }
      .product__desc {
            font-size: 16px;
            font-weight: 300;
      }
      .nav-link,
      .nav-link:hover {
            color: #333;
      }

      .nav-tabs .nav-link.active {
            position: relative;
            background: transparent;
            transition: all 0.3s linear;
            border: 0;
      }
      .nav-tabs .nav-link {
            position: relative;
      }
      .nav-tabs .nav-link::after {
            position: absolute;
            content: ' ';
            width: 0%;
            height: 2px;
            bottom: -1px;
            left: 0;
            background: ${(props) => props.theme.secondary};
            transition: all 0.3s linear;
      }
      .nav-tabs .nav-link:hover::after {
            border: 0;
            width: 100%;
            transition: all 0.3s linear;
      }
      .nav-tabs .nav-link.active::after {
            position: absolute;
            content: ' ';
            width: 100%;
            height: 2px;
            bottom: -1px;
            left: 0;
            background: ${(props) => props.theme.secondary};
            transition: all 0.3s linear;
      }
      /* product details page css */
      .product__toprating--icons {
            font-size: 19px;
            color: ${(props) => props.theme.secondary};
      }
      .product__toprating--info {
            color: var(--footer-paragraph-color);
      }
      .img-fit {
            display: block;
            height: 70px;
            width: 70px;
      }
      .product__suggest--content {
            padding: 15px 10px 15px 18px;
            background-color: var(--bs-white);
            border-color: transparent;
            -webkit-box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
                  0px 10px 20px -3px rgba(145, 158, 171, 0.05);
            box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2),
                  0px 10px 20px -3px rgba(145, 158, 171, 0.05);
            border-radius: 0.25rem;
            border: 1px solid rgba(52, 117, 84, 0.3) !important;
      }
      .product__suggest--info h5 {
            font-size: 18px;
            color: #555;
            font-weight: 400;
      }
      .product__sugest--review i {
            color: ${(props) => props.theme.secondary};
      }
      .product__suggest--info p {
            color: var(--secondary-color);
      }
      .button-2 {
            padding-left: 10px;
            padding-right: 10px;
            color: #fff;
            position: relative;
      }
      .button-2::before {
            transition: all 0.5s ease;
            position: absolute;
            content: '';
            bottom: 0;
            right: 0;
            height: 8px;
            width: 8px;
      }
      .button-2 button {
            color: #fff;
            z-index: 1;
            position: relative;
      }
      .button-2:hover::before {
            transition: all 0.5s ease;
            width: 100%;
            height: 100%;
      }
      .modal-content {
            border: 0;
            border-radius: 0;
      }
      .form-control:focus {
            box-shadow: none;
            outline: none;
      }

      .form-control:focus {
            border-color: #e0dede;
      }
`;
