import React from 'react';

const PhoneIcon = () => {
      return (
            <>
                  <span className="material-symbols-outlined">
                        phone_in_talk
                  </span>
            </>
      );
};

export default PhoneIcon;
