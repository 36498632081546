import styled from 'styled-components';

export const CategorySection = styled.div`
   padding: 70px 0px 80px;
   background-image: url(/images/img/category/category-bg.jpg);

   @media only screen and (max-width: 992px) {
      .ms-4 {
         margin-left: 0 !important;
      }
   }
   .show {
      display: block !important;
   }
   .category__tab--item {
      display: none;
   }
`;

export const CategoryTab = styled.div`
   .nav-item {
      transition: all 0.3s ease-out 0s;

      &:hover {
         img {
            transform: rotateY(180deg);
            -webkit-transition: all 0.3s ease-out 0s;
            -moz-transition: all 0.3s ease-out 0s;
            -ms-transition: all 0.3s ease-out 0s;
            -o-transition: all 0.3s ease-out 0s;
            transition: all 0.3s ease-out 0s;
         }
      }
   }

   .category__title {
      span {
         color: #3c3a3a;
         font-size: 13px;
      }

      h3 {
         color: #1d1d1b;
         font-size: 18px;
         margin-top: 10px;
      }
   }

   .category__icon {
      background-color: #f1f4f7;
      display: inline-block;
      padding: 20px;
      border-radius: 10px;

      img {
         width: 30px;
         height: 30px;
      }
   }
   .nav-item {
      width: 45%;
   }

   .nav-link {
      width: 100%;
      height: 100%;
      padding: 30px 30px;
      background-color: ${(props) => props.theme.white};
   }

   .nav-link.active {
      background-color: ${(props) => props.theme.primary} !important;
      position: relative;

      h3 {
         color: #f1f4f7;
      }

      &::after {
         bottom: -20px;
         left: 50%;
         transform: translateX(-50%);
         position: absolute;
         content: '';
         width: 0;
         height: 0;
         border-style: solid;
         border-width: 25px 17.5px 0 17.5px;
         border-color: ${(props) => props.theme.primary} transparent transparent;
         -webkit-transition: all 0.3s ease-out 0s;
         -moz-transition: all 0.3s ease-out 0s;
         -ms-transition: all 0.3s ease-out 0s;
         -o-transition: all 0.3s ease-out 0s;
         transition: all 0.3s ease-out 0s;
      }
   }

   @media only screen and (max-width: 1330px) {
      .gap-5 {
         gap: 2rem !important;
      }
      .nav-link {
         padding: 20px 30px;
      }
   }

   @media only screen and (max-width: 992px) {
      .gap-5 {
         grid-column-gap: 1rem !important;
         grid-row-gap: 2rem !important;
      }
      .nav-link {
         padding: 20px;
      }

      .category__icon {
         padding: 10px;
      }

      .category__title {
         span {
            display: none;
         }

         h3 {
            font-size: 16px;
            margin-bottom: 0;
         }
      }
   }

   @media only screen and (max-width: 568px) {
      margin-bottom: 20px;
   }
`;

export const NavItem = styled.div``;
export const NavLink = styled.div``;
export const TabContent = styled.div``;
