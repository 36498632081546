import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const PrivacyPolicyContainer = styled(Container)``;
export const PrivacyPolicyNavigation = styled.div``;
export const PrivacyPolicyNavigationItem = styled.div`
      background: ${(props) =>
            props.active ? props.theme.secondary : props.theme.primary};
      padding: 10px 30px;
      clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
      ${'' /* padding: 9px 0; */}
      font-size: 18px;
      min-width: calc(100% - 100px);
      color: white;
      text-align: center;
      display: block;
      transition: 0.3s ease-in-out;
      &:hover {
            background: ${(props) => props.theme.secondary};
            transition: 0.3s ease-in-out;
      }
      a {
            display: block;
            width: 100%;
            height: 100%;
            color: ${(props) => props.theme.white};
      }
`;
export const PrivacyPolicyContent = styled.div`
      width: 100%;
      background: #f2f1f8;
      padding: 30px 50px;
      margin-top: 40px;
      img {
            width: 100px;
            height: auto;
      }
`;
