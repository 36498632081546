import React from 'react';
import { RadiatorAdditionalContainer } from './styles';
import { Col, Container, Row } from 'react-bootstrap';
import { RadiatorInfoContent, RadiatorInfoImage } from '../RadiatorInfo/styles';

const RadiatorAdditional = () => {
      return (
            <>
                  <RadiatorAdditionalContainer>
                        <Container>
                              <Row className="">
                                    <Col>
                                          <RadiatorInfoContent>
                                                <h2 className="py-4 pb-2">
                                                      <span>Design </span>{' '}
                                                      Consideration
                                                </h2>
                                                <p className="text-justify">
                                                      Innmotek tailor all
                                                      radiator systems to ensure
                                                      they are efficient and
                                                      effective. We work with
                                                      you or your project
                                                      manager to ensure minimal
                                                      disruption to timelines,
                                                      physical property, and
                                                      budget.
                                                </p>
                                                <p className="text-justify">
                                                      In a warm-water central
                                                      heating system, pipework
                                                      runs from the boiler to
                                                      radiators situated
                                                      throughout the house. In a
                                                      wood-framed home, pipes
                                                      run through the framing
                                                      timber like the electrical
                                                      and plumbing services. In
                                                      a house constructed of
                                                      concrete, pipes can be run
                                                      between wall and ceiling
                                                      batons or chased into the
                                                      wall.
                                                </p>
                                                <p>
                                                      To ensure an even
                                                      temperature is achieved
                                                      throughout the home,
                                                      radiators should ideally
                                                      be placed in every room.
                                                      In multi-storey homes,
                                                      radiators should also be
                                                      placed in upstairs rooms
                                                      and on landings. Based on
                                                      the ISO standard,
                                                      allowance is made for 25%
                                                      of heat rising to the next
                                                      level in a well-insulated
                                                      home. We, therefore,
                                                      factor a reduced output
                                                      requirement in our
                                                      calculations for upper
                                                      levels.
                                                </p>
                                          </RadiatorInfoContent>
                                    </Col>
                                    <Col lg="5">
                                          <RadiatorInfoImage>
                                                <img
                                                      src="/images/img/radiator/radiator-design.webp"
                                                      alt=""
                                                      className="img-fluid"
                                                />
                                          </RadiatorInfoImage>
                                    </Col>
                              </Row>
                        </Container>
                  </RadiatorAdditionalContainer>
            </>
      );
};

export default RadiatorAdditional;
