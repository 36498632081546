import { Accordion } from 'react-bootstrap';
import styled from 'styled-components';

export const FaqAccordionContainer = styled.div``;

export const FaqAccordionTitle = styled.h3`
      margin-bottom: 20px;
`;
export const FaqAccordionContent = styled(Accordion)`
      .accordion-item {
            background-color: #eff3f6;
            margin-bottom: 20px;
            border: 0;

            &:last-child {
                  margin-bottom: 0;
            }
      }

      .accordion-button:not(.collapsed) {
            box-shadow: none;
      }

      .accordion-button {
            background-color: #eff3f6;
      }
      .accordion-button::after {
            flex-shrink: 0;
            width: 2px;
            height: 12px;
            margin-left: auto;
            content: '';
            background-color: #1d1d1d;
            background-repeat: no-repeat;
            background-size: var(--bs-accordion-btn-icon-width);
            transition: var(--bs-accordion-btn-icon-transition);
      }
      .accordion-button::before {
            position: absolute;
            top: 25px;
            right: 15px;
            flex-shrink: 0;
            width: 13px;
            height: 2px;
            margin-left: auto;
            content: '';
            background-color: #1d1d1d;
            background-repeat: no-repeat;
            background-size: var(--bs-accordion-btn-icon-width);
            transition: var(--bs-accordion-btn-icon-transition);
      }
      .accordion-button:not(.collapsed)::after {
            background-image: var(--bs-accordion-btn-active-icon);
            transform: rotate(90deg);
            height: 14px;
            background-color: ${(props) => props.theme.secondary};
      }
      .accordion-button:not(.collapsed)::before {
            background-color: ${(props) => props.theme.secondary};
      }
      .accordion-button:not(.collapsed) {
            box-shadow: none;
            color: ${(props) => props.theme.secondary};
      }
      .accordion-button:focus {
            z-index: 3;
            border-color: transparent;
            outline: 0;
            box-shadow: none;
      }
`;

export const FaqAccordionItem = styled.div`
      margin-bottom: 40px;
`;

export const WidgetContainer = styled.div`
      top: 90px;
`;
