import React from 'react';
import { CategoryContactContainer } from './styles';
import {
      Button,
      Col,
      Form,
      FormControl,
      FormLabel,
      Modal,
      Offcanvas,
      Row
} from 'react-bootstrap';
import { useState } from 'react';
import { ContactForm } from '../../contacts/styles';
import FormButton from '../../../components/Form/FormButton';

const CategoryContactBox = () => {
      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      return (
            <div>
                  <CategoryContactContainer color="linear-gradient(to left,#0e95b3 1%,#00145e)">
                        <h5 className="text-white mb-4">Have Any Query ? </h5>
                        <p className="text-white mb-4">
                              Take advantage of our unrivaled knowledge and
                              experience, we offer you the best customization
                              service.
                        </p>
                        <Button onClick={handleShow} className="rounded-0 ">
                              Send Your Inquiry{' '}
                              <i class="bx bx-paper-plane ms-2"></i>
                        </Button>
                        <Modal
                              show={show}
                              onHide={handleClose}
                              size="lg"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                        >
                              <Modal.Body>
                                    <Col>
                                          <ContactForm>
                                                <Modal.Title className="mb-3">
                                                      Send Us Your Query
                                                </Modal.Title>{' '}
                                                <Form>
                                                      <Row className="g-4">
                                                            <Col lg={6}>
                                                                  <FormLabel>
                                                                        First
                                                                        Name
                                                                  </FormLabel>
                                                                  <FormControl type="text" />
                                                            </Col>
                                                            <Col lg={6}>
                                                                  <FormLabel>
                                                                        Last
                                                                        Name
                                                                  </FormLabel>
                                                                  <FormControl type="text" />
                                                            </Col>
                                                            <Col lg={6}>
                                                                  <FormLabel>
                                                                        Email
                                                                        Address
                                                                  </FormLabel>
                                                                  <FormControl type="text" />
                                                            </Col>
                                                            <Col lg={6}>
                                                                  <FormLabel>
                                                                        Phone
                                                                        No.
                                                                  </FormLabel>
                                                                  <FormControl type="text" />
                                                            </Col>
                                                            <Col lg={12}>
                                                                  <FormLabel>
                                                                        Message
                                                                  </FormLabel>
                                                                  <FormControl
                                                                        as="textarea"
                                                                        rows={4}
                                                                        type="text"
                                                                  />
                                                            </Col>
                                                            <Col lg={12}>
                                                                  <FormButton title="Submit" />
                                                            </Col>
                                                      </Row>
                                                </Form>
                                          </ContactForm>
                                    </Col>
                              </Modal.Body>
                        </Modal>
                  </CategoryContactContainer>
            </div>
      );
};

export default CategoryContactBox;
