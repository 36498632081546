import React from 'react';

const Lighting = () => {
      return (
            <div>
                  <svg
                        id="fi_8904161"
                        height="512"
                        viewBox="0 0 128 128"
                        width="512"
                        xmlns="http://www.w3.org/2000/svg"
                  >
                        <path d="m94.96 48.782c-.332-.675-1.016-1.105-1.768-1.115l-24.07-.302 10.972-39.497c.261-.936-.189-1.924-1.067-2.341-.876-.419-1.927-.146-2.49.647l-43.334 61c-.433.61-.489 1.411-.146 2.075.344.665 1.029 1.083 1.777 1.083h18.631l-15.21 49.749c-.284.93.142 1.93 1.009 2.369.288.146.598.216.903.216.614 0 1.215-.283 1.604-.804l53-71c.449-.601.522-1.406.189-2.08z"></path>
                  </svg>
            </div>
      );
};

export default Lighting;
