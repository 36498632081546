import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

  @import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700&family=Rajdhani:wght@300;400;500;600;700&family=Saira+Semi+Condensed:wght@100;200;300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Electrolize&family=Michroma&family=Nosifer&family=Syncopate&family=Tulpen+One&display=swap');
* {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      font-family: 'Electrolize', sans-serif;
font-family: 'Michroma', sans-serif;
font-family: 'Nosifer', sans-serif;
font-family: 'Syncopate', sans-serif;
font-family: 'Tulpen One', sans-serif;
      font-family: 'Apex-Mk3-ExtraLight', sans-serif;
      font-family: "Nunito Sans", sans-serif;
      font-family: "Rajdhani", sans-serif;
      font-family: "Saira Semi Condensed", sans-serif;
}
:root {
      --logo__color: #1d1d1b;
      --secondary__color: #eaaa02;
      --product__font: 'Apex-Mk3-ExtraLight', sans-serif;
}
a {
    text-decoration: none !important;
}
`;
