import React, { useEffect, useState } from 'react';
import {
      ImageFloat,
      PoolCompareContainer,
      PoolCompareContent,
      PoolCompareImage,
      PoolCompareTitle,
      PoolEnergyContent,
      PoolEnergyFeature,
      PoolEnergyIcon,
      PoolEnergyImage,
      PoolNoiseContainer,
      PoolNoiseContent,
      PoolNoiseIcon,
      PoolPumpComparision,
      PoolPumpContainer,
      PoolPumpCover,
      PoolPumpFeatureContainer,
      PoolPumpFeatureContent,
      PoolPumpHeading,
      PoolPumpIntro,
      PoolPumpIntroImage,
      PoolPumpItem,
      PoolPumpItemContainer,
      PoolPumpItemContent,
      PoolPumpItemIcon,
      PoolPumpTitle
} from './styles';
import { Col, Container, Row, Table } from 'react-bootstrap';
import EnergyIcon from '../../icons/PoolPump/EnergyIcon';
import CostIcon from '../../icons/PoolPump/CostIcon';
import NoiseIcon from '../../icons/PoolPump/NoiseIcon';
import AlarmIcon from '../../icons/PoolPump/AlarmIcon';
import WindIcon from '../../icons/PoolPump/WindIcon';
import Lighting from '../../icons/PoolPump/Lighting';
import OneclickIcon from '../../icons/PoolPump/OneclickIcon';

import ControlIcon from '../../icons/PoolPump/ControlIcon';
import WifiIcon from '../../icons/PoolPump/WifiIcon';
import ShareIcon from '../../icons/PoolPump/ShareIcon';
import PoolSpaAccordion from '../PoolSpaCover/PoolSpaAccodion';

import axios from 'axios';
import RecommendedProduct from '../../components/frontend/products/RecommendedProduct';

const PoolPump = () => {
      const [loading, setLoading] = useState(false);
      const [products, setProducts] = useState([]);

      const loadData = async () => {
            setLoading(false);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/products/related/pool-pumps`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProducts(response.data.products);
                        }
                  });

            setLoading(true);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <PoolPumpHeading image="url('/images/img/poolpump/about-xl.png')">
                        <Container>
                              <h1 className="text-white">Pool Pump</h1>
                        </Container>
                  </PoolPumpHeading>
                  <PoolPumpContainer>
                        <Container>
                              <Row className="g-5">
                                    <Col>
                                          <PoolPumpIntro className="mt-5">
                                                <p>
                                                      <span>
                                                            Swimming pool pumps
                                                      </span>{' '}
                                                      work hard to keep your
                                                      pool water clean and
                                                      fresh. Not only does a
                                                      pool pump circulate
                                                      sanitizing chemicals
                                                      throughout the pool, it
                                                      helps remove dirt and
                                                      debris as it passes water
                                                      through the pool’s filter.
                                                      And if your pool has a
                                                      heater, a pool pump
                                                      circulates that warmed
                                                      water back into the pool.
                                                      At Lowe’s, choose from a
                                                      number of pool pump
                                                      options depending on the
                                                      preferences and the
                                                      requirements of your pool.
                                                      Whether you have a salt
                                                      pool, above-ground pool,
                                                      sand filter or cartridge
                                                      filter, find the pump that
                                                      meets your needs. Choose a
                                                      straightforward single
                                                      speed pump or a variable
                                                      speed pump that adjusts
                                                      automatically to your
                                                      pool’s current condition.
                                                      You’ll find a large
                                                      selection of Intex pool
                                                      pumps, as well as other
                                                      brands that offer a
                                                      variety of features such
                                                      as built-in timers and
                                                      programmability. A pool
                                                      filter pump conveniently
                                                      combines a pool pump and
                                                      filter. Shop Lowe’s for a
                                                      wide selection of pool
                                                      filter pumps.
                                                </p>
                                          </PoolPumpIntro>
                                    </Col>
                                    <Col lg={4}>
                                          <PoolPumpIntroImage>
                                                <img
                                                      src="/images/img/poolpump/heatpump.png"
                                                      alt=""
                                                      className="img-fluid"
                                                />
                                          </PoolPumpIntroImage>
                                    </Col>
                              </Row>
                              <Row className="mt-3 mb-5 g-5">
                                    <Col lg={5}>
                                          <PoolEnergyImage>
                                                <img
                                                      src="/images/img/poolpump/pump--tile--lastLonger--d-1.jpg"
                                                      alt=""
                                                      className="img-fluid rounded-4"
                                                />
                                          </PoolEnergyImage>
                                    </Col>
                                    <Col lg={7}>
                                          <PoolEnergyContent>
                                                <h3>ENERGY & COST EFFICIENT</h3>
                                                <p>
                                                      Thanks to InverSilence
                                                      technology, the motor
                                                      speed can be controlled
                                                      precisely by 1 round. When
                                                      users need a backwash, the
                                                      pump can run at 100%
                                                      capacity. For daily
                                                      filtration demand, which
                                                      needs small flow rate, the
                                                      pump can run at low
                                                      capacity, which is
                                                      extremely quiet and energy
                                                      efficient. The life span
                                                      is also longer due to
                                                      Aquagem InverSilence
                                                      technology
                                                </p>
                                                <PoolEnergyFeature className="d-flex gap-5 align-items-center">
                                                      <PoolEnergyIcon className="d-flex flex-column align-items-center">
                                                            <EnergyIcon></EnergyIcon>
                                                            <h5 className="mt-3">
                                                                  Save 90% on
                                                            </h5>
                                                            <p>
                                                                  Electricity
                                                                  Bill
                                                            </p>
                                                      </PoolEnergyIcon>
                                                      <PoolEnergyIcon className="d-flex flex-column align-items-center">
                                                            <CostIcon></CostIcon>
                                                            <h5 className="mt-3">
                                                                  Save 90% on
                                                            </h5>
                                                            <p>
                                                                  Electric
                                                                  Consumption
                                                            </p>
                                                      </PoolEnergyIcon>
                                                </PoolEnergyFeature>
                                          </PoolEnergyContent>
                                    </Col>
                              </Row>
                              {/* <PoolPumpCover>
                                    <img
                                          src="/images/img/poolpump/poolpumpcover.png"
                                          alt=""
                                          className="img-fluid"
                                    />
                              </PoolPumpCover> */}
                        </Container>
                        <PoolNoiseContainer className="">
                              <Container>
                                    <h2 className="text-white mb-5">
                                          Smart and Simple Interface
                                    </h2>
                                    <Row>
                                          <Col lg={8}>
                                                <Row className="g-5">
                                                      <Col lg={6}>
                                                            <PoolNoiseContent className="d-flex gap-4 justify-content-center">
                                                                  <PoolNoiseIcon>
                                                                        <AlarmIcon />
                                                                  </PoolNoiseIcon>
                                                                  <h4 className="text-white">
                                                                        4 Timers
                                                                        for
                                                                        daily
                                                                        operation
                                                                  </h4>
                                                            </PoolNoiseContent>
                                                      </Col>
                                                      <Col lg={6}>
                                                            <PoolNoiseContent className="d-flex gap-4 justify-content-center">
                                                                  <PoolNoiseIcon>
                                                                        <WindIcon />
                                                                  </PoolNoiseIcon>
                                                                  <h4 className="text-white">
                                                                        Running
                                                                        capacity
                                                                        from
                                                                        30%~100%
                                                                  </h4>
                                                            </PoolNoiseContent>
                                                      </Col>
                                                      <Col lg={6}>
                                                            <PoolNoiseContent className="d-flex gap-4 justify-content-center">
                                                                  <PoolNoiseIcon>
                                                                        <Lighting />
                                                                  </PoolNoiseIcon>
                                                                  <h4 className="text-white">
                                                                        Power
                                                                        consumption
                                                                        reading
                                                                  </h4>
                                                            </PoolNoiseContent>
                                                      </Col>
                                                      <Col lg={6}>
                                                            <PoolNoiseContent className="d-flex gap-4 justify-content-center">
                                                                  <PoolNoiseIcon>
                                                                        <OneclickIcon />
                                                                  </PoolNoiseIcon>
                                                                  <h4 className="text-white">
                                                                        One-click
                                                                        backwash
                                                                  </h4>
                                                            </PoolNoiseContent>
                                                      </Col>
                                                </Row>
                                          </Col>
                                          <Col lg={3}>
                                                <Col></Col>
                                          </Col>
                                    </Row>
                                    <ImageFloat>
                                          {' '}
                                          <img
                                                src="/images/img/poolpump/remote.png"
                                                alt=""
                                                className="img-fluid"
                                          />
                                    </ImageFloat>
                              </Container>
                        </PoolNoiseContainer>
                        <PoolPumpFeatureContainer className="py-5">
                              <Container>
                                    <Row className="g-5">
                                          <Col lg={6}>
                                                <img
                                                      src="/images/img/poolpump/features.png"
                                                      alt=""
                                                      className="img-fluid rounded-4"
                                                />
                                          </Col>
                                          <Col lg={6}>
                                                <PoolPumpFeatureContent className="mt-4">
                                                      <PoolPumpTitle className="">
                                                            <h3>
                                                                  Other Features
                                                            </h3>
                                                            <p>
                                                                  For daily
                                                                  filtration
                                                                  demand, which
                                                                  needs small
                                                                  flow rate, the
                                                                  pump can run
                                                                  at low
                                                                  capacity,
                                                                  which is
                                                                  extremely
                                                                  quiet and
                                                                  energy
                                                                  efficient. The
                                                                  life span is
                                                                  also longer
                                                                  due to Aquagem
                                                                  InverSilence
                                                                  technolog
                                                            </p>
                                                      </PoolPumpTitle>
                                                      <PoolPumpItemContainer>
                                                            <PoolPumpItem className="d-flex gap-3 align-items-center">
                                                                  <PoolPumpItemIcon>
                                                                        <ControlIcon />
                                                                  </PoolPumpItemIcon>
                                                                  <PoolPumpItemContent>
                                                                        <h5>
                                                                              External
                                                                              Control
                                                                        </h5>
                                                                        <p>
                                                                              It
                                                                              allows
                                                                              pump
                                                                              to
                                                                              be
                                                                              operated
                                                                              by
                                                                              other
                                                                              equipment
                                                                        </p>
                                                                  </PoolPumpItemContent>
                                                            </PoolPumpItem>
                                                            <PoolPumpItem className="d-flex gap-3 align-items-center">
                                                                  <PoolPumpItemIcon>
                                                                        <WifiIcon />
                                                                  </PoolPumpItemIcon>
                                                                  <PoolPumpItemContent>
                                                                        <h5>
                                                                              Wifi
                                                                              Option
                                                                        </h5>
                                                                        <p>
                                                                              The
                                                                              Pump
                                                                              can
                                                                              be
                                                                              controlled
                                                                              at
                                                                              anytime
                                                                              and
                                                                              anywhere.
                                                                        </p>
                                                                  </PoolPumpItemContent>
                                                            </PoolPumpItem>
                                                            <PoolPumpItem className="d-flex gap-3 align-items-center">
                                                                  <PoolPumpItemIcon>
                                                                        <ShareIcon />
                                                                  </PoolPumpItemIcon>
                                                                  <PoolPumpItemContent>
                                                                        <h5>
                                                                              Soft
                                                                              Start
                                                                              -
                                                                              Full
                                                                              Protection
                                                                        </h5>
                                                                        <p>
                                                                              It
                                                                              allows
                                                                              pump
                                                                              to
                                                                              be
                                                                              operated
                                                                              by
                                                                              other
                                                                              equipment
                                                                        </p>
                                                                  </PoolPumpItemContent>
                                                            </PoolPumpItem>
                                                      </PoolPumpItemContainer>
                                                </PoolPumpFeatureContent>
                                          </Col>
                                    </Row>
                              </Container>
                        </PoolPumpFeatureContainer>
                        <PoolPumpComparision className="my-3">
                              <Container>
                                    <Row className="g-5">
                                          <Col lg={6}>
                                                <PoolCompareContainer>
                                                      <PoolCompareImage>
                                                            <img
                                                                  src="images/img/poolpump/poolpump2.png"
                                                                  alt=""
                                                            />
                                                      </PoolCompareImage>
                                                      <PoolCompareContent>
                                                            <h3>
                                                                  InverPro Pool
                                                                  Pump
                                                            </h3>
                                                            <PoolCompareTitle>
                                                                  <h5>
                                                                        Technical
                                                                        Parameter
                                                                  </h5>
                                                            </PoolCompareTitle>
                                                            <Table
                                                                  striped
                                                                  bordered
                                                                  hover
                                                            >
                                                                  <thead>
                                                                        <tr>
                                                                              <th rowspan="2">
                                                                                    Model
                                                                              </th>
                                                                              <th rowspan="2">
                                                                                    Advised
                                                                                    Pool
                                                                                    Volume
                                                                                    (m){' '}
                                                                                    <sup>
                                                                                          3
                                                                                    </sup>
                                                                              </th>
                                                                              <th rowspan="2">
                                                                                    P1{' '}
                                                                                    <sub>
                                                                                          (KW)
                                                                                    </sub>
                                                                              </th>
                                                                              <th rowspan="2">
                                                                                    Voltage
                                                                              </th>
                                                                              <th rowspan="2">
                                                                                    Qmax
                                                                              </th>
                                                                              <th rowspan="2">
                                                                                    Hmax
                                                                              </th>
                                                                              <th colSpan="2">
                                                                                    Circulaction
                                                                                    Flow
                                                                                    (m
                                                                                    <sub>
                                                                                          3
                                                                                    </sub>{' '}
                                                                                    /
                                                                                    h)
                                                                              </th>
                                                                        </tr>
                                                                        <tr>
                                                                              <th>
                                                                                    8m
                                                                              </th>
                                                                              <th>
                                                                                    10m
                                                                              </th>
                                                                        </tr>
                                                                  </thead>

                                                                  <tbody>
                                                                        <tr>
                                                                              <td>
                                                                                    IP20
                                                                              </td>
                                                                              <td>
                                                                                    30-50
                                                                              </td>
                                                                              <td>
                                                                                    0.75
                                                                              </td>
                                                                              <td
                                                                                    rowSpan="4"
                                                                                    className="text-center pt-5"
                                                                              >
                                                                                    220~240
                                                                                    /
                                                                                    50
                                                                                    /
                                                                                    60
                                                                              </td>
                                                                              <td>
                                                                                    24.0
                                                                              </td>
                                                                              <td>
                                                                                    18.0
                                                                              </td>
                                                                              <td>
                                                                                    19.0
                                                                              </td>
                                                                              <td>
                                                                                    15.0
                                                                              </td>
                                                                        </tr>
                                                                        <tr>
                                                                              <td>
                                                                                    IP25
                                                                              </td>
                                                                              <td>
                                                                                    40-70
                                                                              </td>
                                                                              <td>
                                                                                    1.05
                                                                              </td>

                                                                              <td>
                                                                                    27.0
                                                                              </td>
                                                                              <td>
                                                                                    20.0
                                                                              </td>
                                                                              <td>
                                                                                    24.5
                                                                              </td>
                                                                              <td>
                                                                                    21.0
                                                                              </td>
                                                                        </tr>
                                                                        <tr>
                                                                              <td>
                                                                                    IP30
                                                                              </td>
                                                                              <td>
                                                                                    50-80
                                                                              </td>
                                                                              <td>
                                                                                    1.40
                                                                              </td>

                                                                              <td>
                                                                                    29.5
                                                                              </td>
                                                                              <td>
                                                                                    21.0
                                                                              </td>
                                                                              <td>
                                                                                    29.5
                                                                              </td>
                                                                              <td>
                                                                                    26.5
                                                                              </td>
                                                                        </tr>
                                                                        <tr>
                                                                              <td>
                                                                                    IP40
                                                                              </td>
                                                                              <td>
                                                                                    70-100
                                                                              </td>
                                                                              <td>
                                                                                    1.75
                                                                              </td>

                                                                              <td>
                                                                                    42.
                                                                              </td>
                                                                              <td>
                                                                                    21.0
                                                                              </td>
                                                                              <td>
                                                                                    38.0
                                                                              </td>
                                                                              <td>
                                                                                    34.0
                                                                              </td>
                                                                        </tr>
                                                                  </tbody>
                                                            </Table>
                                                      </PoolCompareContent>
                                                </PoolCompareContainer>
                                          </Col>
                                          <Col lg={6}>
                                                <PoolCompareContainer>
                                                      <PoolCompareImage>
                                                            <img
                                                                  src="images/img/poolpump/poolpump1.png"
                                                                  alt=""
                                                            />
                                                      </PoolCompareImage>
                                                      <PoolCompareContent>
                                                            <h3>
                                                                  InverEco Pool
                                                                  Pump
                                                            </h3>
                                                            <PoolCompareTitle>
                                                                  <h5>
                                                                        Technical
                                                                        Parameter
                                                                  </h5>
                                                            </PoolCompareTitle>
                                                            <Table
                                                                  striped
                                                                  bordered
                                                                  hover
                                                            >
                                                                  <thead>
                                                                        <tr>
                                                                              <th rowspan="2">
                                                                                    Model
                                                                              </th>
                                                                              <th rowspan="2">
                                                                                    Advised
                                                                                    Pool
                                                                                    Volume
                                                                                    (m){' '}
                                                                                    <sup>
                                                                                          3
                                                                                    </sup>
                                                                              </th>
                                                                              <th rowspan="2">
                                                                                    P1{' '}
                                                                                    <sub>
                                                                                          (KW)
                                                                                    </sub>
                                                                              </th>
                                                                              <th rowspan="2">
                                                                                    Voltage
                                                                              </th>
                                                                              <th rowspan="2">
                                                                                    Qmax
                                                                              </th>
                                                                              <th rowspan="2">
                                                                                    Hmax
                                                                              </th>
                                                                              <th colSpan="2">
                                                                                    Circulaction
                                                                                    Flow
                                                                                    (m
                                                                                    <sub>
                                                                                          3
                                                                                    </sub>{' '}
                                                                                    /
                                                                                    h)
                                                                              </th>
                                                                        </tr>
                                                                        <tr>
                                                                              <th>
                                                                                    8m
                                                                              </th>
                                                                              <th>
                                                                                    10m
                                                                              </th>
                                                                        </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                        <tr>
                                                                              <td>
                                                                                    DE14
                                                                              </td>
                                                                              <td>
                                                                                    20~40
                                                                              </td>
                                                                              <td>
                                                                                    0.60
                                                                              </td>
                                                                              <td
                                                                                    rowSpan="4"
                                                                                    className="text-center pt-4"
                                                                              >
                                                                                    220~240
                                                                                    50
                                                                                    /
                                                                                    60
                                                                              </td>
                                                                              <td>
                                                                                    18.0
                                                                              </td>
                                                                              <td>
                                                                                    14.0
                                                                              </td>
                                                                              <td>
                                                                                    14.5
                                                                              </td>
                                                                              <td>
                                                                                    9.0
                                                                              </td>
                                                                        </tr>
                                                                        <tr>
                                                                              <td>
                                                                                    DE18
                                                                              </td>
                                                                              <td>
                                                                                    30~50
                                                                              </td>
                                                                              <td>
                                                                                    0.75
                                                                              </td>

                                                                              <td>
                                                                                    21.0
                                                                              </td>
                                                                              <td>
                                                                                    16.0
                                                                              </td>
                                                                              <td>
                                                                                    18.0
                                                                              </td>
                                                                              <td>
                                                                                    14.0
                                                                              </td>
                                                                        </tr>
                                                                        <tr>
                                                                              <td>
                                                                                    DE22
                                                                              </td>
                                                                              <td>
                                                                                    0~70
                                                                              </td>
                                                                              <td>
                                                                                    1.00
                                                                              </td>

                                                                              <td>
                                                                                    25.5
                                                                              </td>
                                                                              <td>
                                                                                    18.0
                                                                              </td>
                                                                              <td>
                                                                                    22.0
                                                                              </td>
                                                                              <td>
                                                                                    18.0
                                                                              </td>
                                                                        </tr>
                                                                  </tbody>
                                                            </Table>
                                                      </PoolCompareContent>
                                                </PoolCompareContainer>
                                          </Col>
                                    </Row>
                              </Container>
                        </PoolPumpComparision>
                        <Container>
                              <Col lg={12}>
                                    {' '}
                                    <PoolSpaAccordion title="Pool Pump FAQs"></PoolSpaAccordion>
                              </Col>
                        </Container>
                  </PoolPumpContainer>
                  <RecommendedProduct loading={loading} products={products} />
            </>
      );
};

export default PoolPump;
