import React from 'react';
import { GlassLinedTankContainer } from './styles';
import {
      PoolEnergyContent,
      PoolEnergyFeature,
      PoolEnergyIcon,
      PoolEnergyImage,
      PoolPumpHeading,
      PoolPumpIntro,
      PoolPumpIntroImage
} from '../PoolPump/styles';
import { Col, Container, Row } from 'react-bootstrap';
import EnergyIcon from '../../icons/PoolPump/EnergyIcon';
import CostIcon from '../../icons/PoolPump/CostIcon';
import { InformationButton } from '../home/components/InformationBox/styles';
import { Link } from 'react-router-dom';
import { StainlessPanelTankFaq } from '../stainlesspaneltank/styles';
import PoolSpaAccordion from '../PoolSpaCover/PoolSpaAccodion';

const GlassLinedTank = () => {
      return (
            <>
                  <GlassLinedTankContainer>
                        <PoolPumpHeading image="url('/images/img/poolpump/about-xl.png')">
                              <Container>
                                    <h1 className="text-white">
                                          Glass Lined Hot Water Tank
                                    </h1>
                              </Container>
                        </PoolPumpHeading>
                        <Container>
                              <Row className="g-5">
                                    <Col>
                                          <PoolPumpIntro className="mt-5">
                                                <p>
                                                      <span>
                                                            A glass-lined hot
                                                            water{' '}
                                                      </span>
                                                      tank is a specialized
                                                      water storage unit with an
                                                      interior surface coated in
                                                      glass. This glass lining
                                                      serves to prevent
                                                      corrosion, ensuring the
                                                      longevity of the tank and
                                                      maintaining the purity of
                                                      the hot water stored
                                                      inside. The glass lining
                                                      also offers chemical
                                                      inertness, making it
                                                      suitable for applications
                                                      where the stored water may
                                                      have corrosive elements.
                                                      These tanks find
                                                      widespread use in
                                                      residential and industrial
                                                      settings, providing
                                                      reliable and durable
                                                      solutions for heating and
                                                      storing hot water.
                                                </p>
                                          </PoolPumpIntro>
                                          <InformationButton className="mt-3">
                                                <Link
                                                      to="/"
                                                      className="d-flex gap-2 align-items-center justify-content-cetner"
                                                >
                                                      Download Catalogue
                                                      <i class="bx bxs-download"></i>
                                                </Link>
                                          </InformationButton>
                                    </Col>
                                    <Col lg={4}>
                                          <PoolPumpIntroImage>
                                                <img
                                                      src="/images/img/radiator/glasslinedtank.png"
                                                      alt=""
                                                      className="img-fluid"
                                                />
                                          </PoolPumpIntroImage>
                                    </Col>
                              </Row>
                              <Row className="mt-3 mb-5 g-5">
                                    <Col lg={5}>
                                          <PoolEnergyImage>
                                                <img
                                                      src="/images/img/radiator/lined.png"
                                                      alt=""
                                                      className="img-fluid rounded-4"
                                                />
                                          </PoolEnergyImage>
                                    </Col>
                                    <Col lg={7}>
                                          <PoolEnergyContent className="pt-5">
                                                <h3 className="mb-3">
                                                      Benefits of{' '}
                                                      <span>
                                                            Glass lined Storage
                                                            Tanks
                                                      </span>
                                                </h3>
                                                <p>
                                                      <ul className="">
                                                            <li>
                                                                  Corrosion
                                                                  Resistance:
                                                                  The interior
                                                                  glass lining
                                                                  provides
                                                                  excellent
                                                                  protection
                                                                  against
                                                                  corrosion,
                                                                  ensuring the
                                                                  tank's
                                                                  durability
                                                                  even in harsh
                                                                  water
                                                                  conditions or
                                                                  when exposed
                                                                  to chemicals.
                                                            </li>
                                                            <li>
                                                                  Hygienic
                                                                  Storage: Glass
                                                                  lining
                                                                  inhibits the
                                                                  growth of
                                                                  bacteria,
                                                                  algae, and
                                                                  other
                                                                  microorganisms,
                                                                  ensuring that
                                                                  the stored
                                                                  water remains
                                                                  clean and safe
                                                                  for use.
                                                            </li>
                                                            <li>
                                                                  Insulation:
                                                                  Many
                                                                  glass-lined
                                                                  tanks are
                                                                  equipped with
                                                                  insulation
                                                                  layers, which
                                                                  help minimize
                                                                  heat loss,
                                                                  enhance energy
                                                                  efficiency,
                                                                  and reduce
                                                                  heating
                                                                  expenses.
                                                            </li>
                                                            <li>
                                                                  Temperature
                                                                  Resistance:
                                                                  Glass lining
                                                                  can withstand
                                                                  high
                                                                  temperatures
                                                                  without
                                                                  compromising
                                                                  its structural
                                                                  integrity,
                                                                  making it
                                                                  suitable for
                                                                  storing hot
                                                                  water
                                                                  generated from
                                                                  various
                                                                  heating
                                                                  sources.
                                                            </li>
                                                            <li>
                                                                  {' '}
                                                                  Easy
                                                                  Maintenance:
                                                                  The smooth,
                                                                  non-porous
                                                                  surface of the
                                                                  glass lining
                                                                  makes cleaning
                                                                  and
                                                                  maintenance
                                                                  hassle-free,
                                                                  reducing the
                                                                  risk of
                                                                  scaling or
                                                                  sediment
                                                                  accumulation.
                                                            </li>
                                                      </ul>
                                                </p>
                                          </PoolEnergyContent>
                                    </Col>
                              </Row>
                              {/* <PoolPumpCover>
                                    <img
                                          src="/images/img/poolpump/poolpumpcover.png"
                                          alt=""
                                          className="img-fluid"
                                    />
                              </PoolPumpCover> */}
                        </Container>
                  </GlassLinedTankContainer>
                  <StainlessPanelTankFaq className="pt-3">
                        <Container>
                              <Col lg={12}>
                                    {' '}
                                    <PoolSpaAccordion title="Glass Lined Hot Water Storage Tank FAQs"></PoolSpaAccordion>
                              </Col>
                        </Container>
                  </StainlessPanelTankFaq>
            </>
      );
};

export default GlassLinedTank;
