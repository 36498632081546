import React, { useEffect, useState } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { CategoryBackground } from '../category/styles';

import { SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { Col, Container, Row } from 'react-bootstrap';
import {
      ProjectDescription,
      ProjectDetailTitle,
      ProjectSwiper,
      ShareProject,
      ThumbnailSwiper
} from './styles';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import ProjectDetailSkeleton from '../../components/skeletons/ProjectDetailSkeleton';
import TitleSkeleton from '../../components/skeletons/TitleSkeleton';
import {
      BlogDetailContent,
      BlogDetailTitle,
      RealtedBlogItem,
      RealtedBlogItemTitle,
      RelatedBlogContent
} from '../blogs/BlogDetail/styles';
import RelatedBlogSkeleton from '../../components/skeletons/RelatedBlogSkeleton';

const ProjectDetail = () => {
      const [thumbsSwiper, setThumbsSwiper] = useState(null);
      const [project, setProject] = useState([]);
      const [loading, setLoading] = useState(false);
      const [projects, setProjects] = useState([]);
      const { projectId } = useParams();

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/projects/${projectId}`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProject(response.data.project);
                              setProjects(response.data.projects);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <BreadcrumbBox title={project.title} />

                  <CategoryBackground>
                        <Container>
                              <Row>
                                    <Col lg={8}>
                                          {!loading ? (
                                                project?.image && (
                                                      <ProjectSwiper
                                                            style={{
                                                                  '--swiper-navigation-color':
                                                                        '#fff',
                                                                  '--swiper-pagination-color':
                                                                        '#fff'
                                                            }}
                                                            spaceBetween={10}
                                                            navigation={false}
                                                            thumbs={{
                                                                  swiper: thumbsSwiper
                                                            }}
                                                            modules={[
                                                                  FreeMode,
                                                                  Navigation,
                                                                  Thumbs
                                                            ]}
                                                            className="mySwiper2"
                                                      >
                                                            {project?.image && (
                                                                  <SwiperSlide>
                                                                        <img
                                                                              src={
                                                                                    project?.image
                                                                              }
                                                                        />
                                                                  </SwiperSlide>
                                                            )}
                                                            {project?.images
                                                                  ?.length >
                                                                  0 &&
                                                                  project?.images?.map(
                                                                        (
                                                                              item,
                                                                              index
                                                                        ) => (
                                                                              <SwiperSlide
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <img
                                                                                          src={
                                                                                                item.image
                                                                                          }
                                                                                    />
                                                                              </SwiperSlide>
                                                                        )
                                                                  )}
                                                      </ProjectSwiper>
                                                )
                                          ) : (
                                                <>
                                                      <ProjectDetailSkeleton />
                                                </>
                                          )}

                                          <ProjectDetailTitle>
                                                <h2>
                                                      {!loading ? (
                                                            project.title
                                                      ) : (
                                                            <TitleSkeleton />
                                                      )}
                                                </h2>
                                                {/* <ShareProject>
                                                      <i className="bx bx-share-alt"></i>
                                                </ShareProject> */}
                                          </ProjectDetailTitle>
                                          {project?.image && (
                                                <ThumbnailSwiper
                                                      onSwiper={setThumbsSwiper}
                                                      spaceBetween={10}
                                                      slidesPerView={4}
                                                      freeMode={true}
                                                      watchSlidesProgress={true}
                                                      modules={[
                                                            FreeMode,
                                                            Navigation,
                                                            Thumbs
                                                      ]}
                                                      className="mySwiper"
                                                >
                                                      {project?.image && (
                                                            <SwiperSlide>
                                                                  <img
                                                                        src={
                                                                              project?.image
                                                                        }
                                                                  />
                                                            </SwiperSlide>
                                                      )}
                                                      {project?.images?.length >
                                                            0 &&
                                                            project?.images?.map(
                                                                  (
                                                                        item,
                                                                        index
                                                                  ) => (
                                                                        <SwiperSlide
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <img
                                                                                    src={
                                                                                          item.image
                                                                                    }
                                                                              />
                                                                        </SwiperSlide>
                                                                  )
                                                            )}
                                                </ThumbnailSwiper>
                                          )}

                                          <ProjectDescription>
                                                {!loading ? (
                                                      <div
                                                            dangerouslySetInnerHTML={{
                                                                  __html: project.description
                                                            }}
                                                      ></div>
                                                ) : (
                                                      <>
                                                            <TitleSkeleton />
                                                            <TitleSkeleton />
                                                            <TitleSkeleton />
                                                            <TitleSkeleton />
                                                      </>
                                                )}
                                          </ProjectDescription>
                                    </Col>
                                    <Col lg={4}>
                                          <BlogDetailContent background="#FFFFFF">
                                                <BlogDetailTitle>
                                                      Other Projects
                                                </BlogDetailTitle>
                                                <RelatedBlogContent
                                                      className={`${
                                                            projects?.length ===
                                                            0
                                                                  ? 'mt-0'
                                                                  : ''
                                                      }`}
                                                >
                                                      {!loading ? (
                                                            projects?.length >
                                                            0 ? (
                                                                  projects?.map(
                                                                        (
                                                                              item,
                                                                              index
                                                                        ) => (
                                                                              <>
                                                                                    <RealtedBlogItem
                                                                                          key={
                                                                                                index
                                                                                          }
                                                                                    >
                                                                                          <Link
                                                                                                to={`/projects/${item.slug}`}
                                                                                          >
                                                                                                <img
                                                                                                      src={
                                                                                                            item.image
                                                                                                      }
                                                                                                      alt={
                                                                                                            item.title
                                                                                                      }
                                                                                                />
                                                                                          </Link>
                                                                                          <RealtedBlogItemTitle
                                                                                                to={`/projects/${item.slug}`}
                                                                                          >
                                                                                                <div>
                                                                                                      {
                                                                                                            item.title
                                                                                                      }
                                                                                                </div>
                                                                                          </RealtedBlogItemTitle>
                                                                                    </RealtedBlogItem>
                                                                              </>
                                                                        )
                                                                  )
                                                            ) : null
                                                      ) : (
                                                            <>
                                                                  <RelatedBlogSkeleton />
                                                                  <RelatedBlogSkeleton />
                                                            </>
                                                      )}
                                                </RelatedBlogContent>
                                          </BlogDetailContent>
                                    </Col>
                              </Row>
                        </Container>
                  </CategoryBackground>
            </>
      );
};

export default ProjectDetail;
