import styled from 'styled-components';

export const RoboticCleanerHeading = styled.div`
      background: ${(props) => props.theme.primary};
`;
export const RoboticCleanerHeadingContent = styled.div`
      background: ${(props) => props.theme.primary};
      transform: skew(347deg);
      margin-left: 45px;
      padding: 80px 70px;

      h1 {
            font-size: 30px;
      }
`;
export const RoboticClenerHeadingContainer = styled.div``;
export const RoboticCleanerHeadingOverlay = styled.div`
      background: url('/images/img/robotic pool cleaner/madimack-gt-freedom-bg.webp');
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
`;
export const RoboticCleanerHeadingImage = styled.div`
      img {
            transform: translate(-90px, 70px);
      }
`;
export const RoboticCleanerContent = styled.div`
      h2 {
            font-size: 28px;
      }
      p {
            color: #666;
            line-height: 1.7;
            text-align: justify;
      }
`;
export const RoboticCleanerTitle = styled.div``;
export const RoboticCleanerContentImage = styled.div``;
export const RoboticCleanerContainer = styled.div``;

export const RoboticCleanerVideo = styled.div`
      width: 100%;
      height: 550px;
      video {
            height: 100%;
            object-fit: cover;
            position: relative;
            top: 0;
            left: 0;
      }
`;

export const ImageClip = styled.span`
      background-image: url('/images/img/robotic pool cleaner/image-1.jpg');
      font-size: 6vw;
      background-size: cover;
      background-postion: center;
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
`;
export const RoboticCleanerRemoteContent = styled.div`
      position: absolute;
      top: 90px;
      left: 50%;
      transform: translateX(-50%);
      h3 {
            font-size: 40px;
      }
`;

export const RoboticCleanerRemote = styled.div`
      position: relative;
`;

export const RoboticCleanerFaq = styled.div`
      background: #f0f4f7;
`;
export const RoboticCleanerFaqContent = styled.div`
      padding-left: 100px;
      button {
            display: inline-block;
            width: 200px;
            background: ${(props) => props.theme.secondary};
            border: 0;
            padding: 10px 10px;
            &:hover {
                  background: ${(props) => props.theme.primary};
            }
      }
`;
export const RoboticPoolCleanerContainer = styled.div`
      overflow: hidden;
`;
