import styled from 'styled-components';

export const SolarButtonContainer = styled.div`
   background: ${(props) => props.theme.primary};
   padding: 10px 30px;
   clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
   font-size: 18px;
   min-width: calc(250px);
   color: white;
   width: 250px;
   text-align: center;
   display: block;
   transition: 0.3s ease-in-out;
   cursor: pointer;
`;
