import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { PrivacyPolicyNavigationItem } from '../styles';
import { Link, useLocation } from 'react-router-dom';

import './style.css';

export default function PrivacyPolicySlider() {
      const { pathname } = useLocation();
      return (
            <>
                  <Swiper
                        slidesPerView={2}
                        spaceBetween={10}
                        breakpoints={{
                              640: {
                                    slidesPerView: 1,
                                    spaceBetween: 10
                              },
                              768: {
                                    slidesPerView: 2,
                                    spaceBetween: 10
                              },
                              1024: {
                                    slidesPerView: 4,
                                    spaceBetween: 0
                              }
                        }}
                        className="mySwiper"
                  >
                        <SwiperSlide>
                              <PrivacyPolicyNavigationItem
                                    active={
                                          pathname === '/privacy-policy'
                                                ? true
                                                : false
                                    }
                              >
                                    <Link
                                          to="/privacy-policy"
                                          className="text-decoration-none"
                                    >
                                          Privacy Policy
                                    </Link>
                              </PrivacyPolicyNavigationItem>
                        </SwiperSlide>
                        <SwiperSlide>
                              <PrivacyPolicyNavigationItem
                                    active={
                                          pathname === '/terms-and-condition'
                                                ? true
                                                : false
                                    }
                              >
                                    <Link
                                          to="/terms-and-condition"
                                          className="text-decoration-none"
                                    >
                                          Terms & Condition
                                    </Link>
                              </PrivacyPolicyNavigationItem>
                        </SwiperSlide>
                        <SwiperSlide>
                              <PrivacyPolicyNavigationItem
                                    active={
                                          pathname === '/dealer-policy'
                                                ? true
                                                : false
                                    }
                              >
                                    <Link
                                          to="/dealer-policy"
                                          className="text-decoration-none"
                                    >
                                          Dealer Policy
                                    </Link>
                              </PrivacyPolicyNavigationItem>
                        </SwiperSlide>
                        <SwiperSlide>
                              <PrivacyPolicyNavigationItem
                                    active={
                                          pathname === '/payment-policy'
                                                ? true
                                                : false
                                    }
                              >
                                    <Link
                                          to="/payment-policy"
                                          className="text-decoration-none"
                                    >
                                          Payment Policy
                                    </Link>
                              </PrivacyPolicyNavigationItem>
                        </SwiperSlide>
                  </Swiper>
            </>
      );
}
