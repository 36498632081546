import React, { memo } from 'react';
import { SubscriberContainer } from './styles';
import { FormControl } from 'react-bootstrap';

const SubscribeBox = memo(({ margin }) => {
   return (
      <>
         <SubscriberContainer margin={margin}>
            <h2>Get Newsletter</h2>
            <form>
               <FormControl type="email" required />
               <button type="submit">Submit</button>
            </form>
         </SubscriberContainer>
      </>
   );
});

export default SubscribeBox;
