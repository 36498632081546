import axios from 'axios';
import React, { memo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import BreadcrumbBox from '../../../components/common/BreadcrumbBox';
import { BlogDetailContainer } from '../BlogDetail/styles';
import { Col, Row, Spinner } from 'react-bootstrap';
import BlogItemBox from '../../../components/common/BlogItemBox';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const BlogCategoryPage = memo(() => {
      const { categoryId } = useParams();

      const [blogs, setBlogs] = useState([]);
      const [category, setCategory] = useState([]);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/blogs/categories/${categoryId}`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setBlogs(response.data.blogs);
                              setCategory(response.data.category);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Helmet>
                        <title>
                              {category.seo_title
                                    ? category.seo_title
                                    : 'Blog by ' + category.title}
                        </title>
                        {category.seo_description && (
                              <meta
                                    name="description"
                                    content={category.seo_description}
                              />
                        )}
                        {category.seo_keyword && (
                              <meta
                                    name="keyword"
                                    content={category.seo_keyword}
                              />
                        )}
                        <link rel="canonical" href={window.location.href} />
                  </Helmet>
                  <BreadcrumbBox
                        link="/blogs"
                        linkTitle="Blogs"
                        title={`Blog By: ${category.title}`}
                  />

                  <BlogDetailContainer>
                        <Row>
                              {!loading ? (
                                    blogs?.length > 0 ? (
                                          blogs?.map((blog, index) => (
                                                <Col lg={4} key={index}>
                                                      <BlogItemBox
                                                            blog={blog}
                                                      />
                                                </Col>
                                          ))
                                    ) : null
                              ) : (
                                    <div
                                          className="text-center d-flex justify-content-center align-items-center"
                                          style={{ height: '40vh' }}
                                    >
                                          <Spinner />
                                    </div>
                              )}
                        </Row>
                  </BlogDetailContainer>
            </>
      );
});

export default BlogCategoryPage;
