export const lightTheme = {
   body: '#212529',
   primary: '#1d1d1d',
   secondary: '#eaaa02',
   white: '#FFFFFF',
   blue: '#2557a7',
   background: '#F4F3F8'
};

export const DarkTheme = {
   body: '#000000',
   text: '#FCF6F4',
   borderolor: '#a5a5a5',
   fontFamily: "'Manrope', sans-serif",
   textRgba: '252, 246, 244',
   bodyRgba: '0,0,0'
};
