import styled from 'styled-components';
import { ContactForm } from '../contacts/styles';
import {
      ContactItemContainer,
      ContactItemDetail,
      ContactItemTitle
} from '../../components/common/ContactItemBox/styles';

export const CategoryDetailContainer = styled.div`
      ${ContactForm} {
            margin-top: 2rem;
      }
      ${ContactItemContainer} {
            padding-bottom: 10px;
            padding-top: 10px;
      }
      ${ContactItemTitle} {
            font-size: 18px;
            font-weight: 500;
      }
      ${ContactItemDetail} {
            margin-bottom: 10px;
      }
`;
export const CategoryDetailHeading = styled.div`
      h3 {
            font-size: 20px;
            font-weight: 500;
      }
`;
export const CategoryDetailDescription = styled.div`
      .highlight {
            color: ${(props) => props.theme.secondary};
      }
      p {
            line-height: 1.7;
            text-align: justify;
            span {
                  font-weight: 500;
            }
      }
      ul li {
            list-style-type: none;
            text-align: left;
            svg {
                  width: 17px;
                  height: 17px;
                  margin-right: 10px;
                  margin-top: 5px;
            }
      }
`;

export const CategoryDetailSection = styled.div`
      background: url('/images/img/category/category-bg.jpg');
`;
// Applicable Material Sector css
export const ApplicableMaterialSector = styled.section`
      h3 {
            font-size: 24px;
      }
`;
export const ApplicableMaterialContent = styled.div`
      a {
            display: block;
            transition: all 0.3s ease-in;
            color: ${(props) => props.theme.primary};

            &:hover {
                  color: ${(props) => props.theme.secondary};
            }
      }
`;
export const ApplicableMaterialImage = styled.img``;
export const ApplicableMaterialTitle = styled.h5`
      font-size: 17px;
      font-weight: 400;
      height: ${(props) => props.height};
`;
