import styled from 'styled-components';
import { ProductItemButton } from '../../../styles/frontend/home';

export const ContactUsContainer = styled.div`
      position: relative;
`;
export const ContactUsImage = styled.div`
      position: relative;
      &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background: #000;
            opacity: 0.8;
            width: 100%;
            height: 100%;
      }
      img {
            width: 100%;
            height: 258px;
            object-fit: cover;
      }
`;
export const ContactUsContent = styled.div`
      padding: 50px 30px;
      position: absolute;
      top: 0;
      left: 0;
      h2 {
            font-size: 24px;
            margin-bottom: 10px;
      }
      p {
            color: #ffffffa6;
            font-size: 14px;
            margin-bottom: 34px;
      }
      ${ProductItemButton} {
            a {
                  padding: 8px 21px;
                  padding-right: 25px;
                  background: #eaaa02;
                  color: #fff;
                  &:after {
                        background-color: ${(props) => props.theme.primary};
                  }
            }
      }
`;
