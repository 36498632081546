import React, { useContext } from 'react';
import TestimonialBox from '../home/components/TestimonialBox';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import {
   AboutBenefit,
   AboutBody,
   AboutContent,
   AboutImage,
   AboutSection,
   TestimonialHeading
} from '../../styles/frontend/home';
import { Col, Container, Row } from 'react-bootstrap';
import AboutBenefitItemBox from '../../components/frontend/home/AboutBenefitItemBox';
import MetaContext from '../../stores/MetaContext';

const AboutUs = () => {
   const metaCtx = useContext(MetaContext);
   metaCtx.handleSlug('about-us');

   return (
      <>
         <BreadcrumbBox title="About Us" />
         <AboutSection>
            <Container>
               <Row lg={5}>
                  <Col lg={5}>
                     <AboutImage>
                        <img
                           src="/images/img/about/Group 3.png"
                           alt="Innmotek Pvt Ltd"
                           class="img-fluid"
                           width="100%"
                           height="100%"
                        />
                     </AboutImage>
                  </Col>
                  <Col lg={7}>
                     <AboutContent>
                        <TestimonialHeading margin="1rem">
                           <h2>
                              About Company
                              <span class="ms-2"></span>
                           </h2>
                           <h3>Premier Heating Solution!</h3>
                        </TestimonialHeading>
                        <AboutBody>
                           <p>
                              Innmotek is a leading supplier of Hot Water Heat
                              Pumps, Swimming Pool Heat Pumps, and Accessories
                              in Residential, Commercial, and Industrial Area.
                              We have a team of experienced professionals who
                              can help you choose the right product for your
                              needs.
                           </p>
                           <p>
                              We are committed to providing our customers with
                              high-quality products and services at competitive
                              prices. We offer a wide variety of Heat pumps and
                              Swimming pool heat pumps to choose from, and we
                              are always happy to answer any questions you may
                              have.
                           </p>
                           <p>
                              We also offer a variety of accessories for your
                              heat pump and swimming pools, including
                              thermostats, controllers, pool covers, and robotic
                              cleaners, etc. We want to make sure you have
                              everything you need.
                           </p>
                           <p className="mb-4">
                              We are confident that you will be happy with our
                              products and services. We offer a satisfaction
                              guarantee on all of our products, and we are
                              always here to help you with any questions or
                              concerns you may have.
                           </p>
                           <AboutBenefit>
                              <AboutBenefitItemBox
                                 title="Our Mission"
                                 image="/images/svg/target.svg"
                                 style={true}
                                 description="Innmotek’s mission is to provide our customers with the best possible experience when it comes to Hot Water Heat Pumps and Swimming Pool Heat Pumps. We want to make sure you have the information and resources you need to make the best decision for your needs. "
                              />
                              <AboutBenefitItemBox
                                 title="Our Values"
                                 image="/images/svg/loyalty.svg"
                                 description="
                                                                  Innmotek is committed to providing our customers with: 
                                                                        <ul>
                                                                              <li>High-quality products </li>
                                                                              <li>Competitive prices </li>
                                                                              <li>Excellent customer service </li>
                                                                              <li>A satisfaction guarantee. </li>
                                                                        </ul>
                                                                  "
                              />
                              <p>
                                 We believe that our customers are our top
                                 priority, and we are always working to improve
                                 our products and services to meet your needs.
                              </p>
                           </AboutBenefit>
                        </AboutBody>
                     </AboutContent>
                  </Col>
               </Row>
            </Container>
         </AboutSection>
         {/* <TestimonialBox /> */}
      </>
   );
};

export default AboutUs;
