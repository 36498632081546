import {
      RadiatorCategoryContainer,
      RadiatorCategoryContent,
      RadiatorCategoryTitle
} from './styles';
import { Container } from 'react-bootstrap';

import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';
import { RadiatorSliderImage } from '../RadiatorSlider/styles';
import { radiatorcategories } from '../../../../data/radiatorCategoryList';

const RadiatorCategory = () => {
      return (
            <>
                  <RadiatorCategoryContainer>
                        <Container>
                              <h3 className="mb-4">
                                    our <span>radiator</span> ranges
                              </h3>
                              <Swiper
                                    slidesPerView={1}
                                    spaceBetween={10}
                                    breakpoints={{
                                          640: {
                                                slidesPerView: 2,
                                                spaceBetween: 20
                                          },
                                          768: {
                                                slidesPerView: 4,
                                                spaceBetween: 40
                                          },
                                          1024: {
                                                slidesPerView: 4,
                                                spaceBetween: 20
                                          }
                                    }}
                                    className="mySwiper"
                              >
                                    {radiatorcategories.map((item, index) => (
                                          <SwiperSlide>
                                                <RadiatorCategoryContent>
                                                      <RadiatorSliderImage>
                                                            <img
                                                                  src={
                                                                        item.image
                                                                  }
                                                                  alt={
                                                                        item.title
                                                                  }
                                                                  className="img-fluid"
                                                            />
                                                      </RadiatorSliderImage>
                                                      <RadiatorCategoryTitle className="d-flex align-items-center justify-content-center">
                                                            <h5 className="mb-0 ">
                                                                  {item.title}
                                                            </h5>
                                                      </RadiatorCategoryTitle>
                                                </RadiatorCategoryContent>
                                          </SwiperSlide>
                                    ))}
                              </Swiper>
                        </Container>
                  </RadiatorCategoryContainer>
            </>
      );
};

export default RadiatorCategory;
