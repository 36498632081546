import React, { Suspense } from 'react';
import './App.css';

import 'react-progress-2/main.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Progress from 'react-progress-2';
import { BrowserRouter as Router } from 'react-router-dom';

import { GlobalStyle } from '../styles/components/globalStyle';
import { ThemeProvider } from 'styled-components';
import { lightTheme } from '../styles/components/themes';
import ScrollToTop from '../components/ScrollToTop';
import { Toaster } from 'react-hot-toast';
import PublicRouter from '../router';
import Header from '../frontend/layouts/header';
import Footer from '../frontend/layouts/footer';
import { useEffect } from 'react';

import Aos from 'aos';
import 'aos/dist/aos.css';
import { MetaContextProvider } from '../stores/MetaContext';

const App = () => {
      useEffect(function () {
            Aos.init({ duration: 1000 });
      }, []);
      return (
            <>
                  <MetaContextProvider>
                        <GlobalStyle />
                        <Progress.Component
                              style={{ background: '#99999978', height: '5px' }}
                              thumbStyle={{
                                    background: 'rgb(232, 107, 25)',
                                    height: '5px'
                              }}
                        />
                        <ThemeProvider theme={lightTheme}>
                              <Router>
                                    <ScrollToTop />
                                    <Toaster position="bottom-center" />
                                    <Header />
                                    <Suspense fallback="Loading">
                                          <PublicRouter />
                                    </Suspense>
                                    <Footer />
                              </Router>
                        </ThemeProvider>
                  </MetaContextProvider>
            </>
      );
};

export default App;
