import React from 'react';
import { RealtedBlogItem } from '../../../frontend/blogs/BlogDetail/styles';
import { Skeleton } from '@mui/material';

const RelatedBlogSkeleton = () => {
      return (
            <>
                  <RealtedBlogItem className="p-0 m-0">
                        <Skeleton height={100} width={100} />
                        <div className="w-100">
                              <Skeleton
                                    variant="text"
                                    height={40}
                                    width="100%"
                              />
                              <Skeleton
                                    variant="text"
                                    height={20}
                                    width="100"
                              />
                        </div>
                  </RealtedBlogItem>
            </>
      );
};

export default RelatedBlogSkeleton;
