import { Container } from 'react-bootstrap';
import { styled } from 'styled-components';

export const HeaderMenu = styled.div`
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      @media only screen and (max-width: 992px) {
            nav {
                  display: none;
            }
      }
`;

export const NavigationContainer = styled(Container)`
      display: flex;
      align-items: center;
      justify-content: space-between;
`;

export const HeaderLogo = styled.div`
      a {
            display: block;

            img {
                  width: 120px;
                  padding: 11px 0px;
            }
      }

      @media only screen and (max-width: 992px) {
            a {
                  img {
                        width: 120px;
                        height: 70px;
                        object-fit: contain;
                  }
            }
      }
`;

export const NavigationMenu = styled.ul`
      margin-bottom: 0;
      list-style: none;
      padding: 0;
      position: relative;

      li {
            display: inline-block;
            margin-right: 1.5rem;

            &:last-child {
                  margin-right: 0rem !important;
            }

            a {
                  padding: 30px 10px;
                  color: ${(props) => props.theme.primary};
                  font-size: 17px;
            }
            .dropdown__content.define {
                  ul {
                        li {
                              &:first-child {
                                    width: 29%;
                              }
                        }
                  }
            }
            .dropdown__content {
                  ul {
                        li {
                              width: max-content;

                              .main__category {
                                    text-align: left;
                                    font-weight: 600;
                                    margin-bottom: 10px;

                                    &:hover {
                                          color: ${(props) =>
                                                props.theme
                                                      .secondary} !important;
                                    }
                              }

                              ul {
                                    display: grid;
                                    padding: 0;
                                    margin: 0;
                                    width: max-content;

                                    li {
                                          width: max-content;
                                          margin-left: -5px;

                                          a {
                                                font-weight: 400;
                                                width: max-content;

                                                &:hover {
                                                      transform: translate(10px, 0px);
                                                      color: ${(props) =>
                                                            props.theme
                                                                  .secondary} !important;
                                                }
                                          }
                                    }
                              }
                        }
                  }
            }
      }
`;
