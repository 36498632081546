import React from 'react';
import { HistorySection } from '../../../styles/frontend/home';
import { Col, Container, Row } from 'react-bootstrap';
import HistoryItemBox from '../../../components/common/HistoryItemBox';
import { InformationButton, InformationContent } from './InformationBox/styles';
import { Link } from 'react-router-dom';

const HistoryBox = () => {
      return (
            <>
                  <HistorySection>
                        <Container>
                              <h3>
                                    What is{' '}
                                    <span>
                                          Aerothermal or air-to-water heat pump?
                                    </span>
                              </h3>
                              <Row>
                                    <Col lg={5}>
                                          <InformationContent>
                                                <p className="mt-5">
                                                      The most efficient
                                                      aerothermal heat pumps are
                                                      the so-called air-to-water
                                                      heat pumps, so called
                                                      because they extract heat
                                                      from the air and transfer
                                                      it to the water, unlike an
                                                      air conditioner
                                                      (air-to-air heat pump)
                                                      that extracts heat from
                                                      the air and transfers it
                                                      to the interior through
                                                      the air, being unable to
                                                      heat domestic hot water
                                                      (DHW).
                                                </p>
                                          </InformationContent>
                                          {/* <InformationButton className="mt-3">
                                                <Link to="/">Learn More</Link>
                                          </InformationButton> */}
                                    </Col>
                                    <Col lg={3}>
                                          <img
                                                src="/images/img/about/heatpump.png"
                                                alt="innmotek information image"
                                                className="img-fluid"
                                                width="100%"
                                                height="100%"
                                          />
                                    </Col>
                                    <Col>
                                          <p className="mt-5">
                                                Thanks to the high performance
                                                of aerothermal heat pumps, we
                                                can multiply electricity
                                                consumption by 3, 4 and 5
                                                (depending on the external
                                                conditions and the
                                                installation), i.e., with 1 kW
                                                of electricity consumed we can
                                                generate 3 kW to 5 kW of thermal
                                                energy.
                                          </p>
                                          <p>
                                                At Innmotek we have focused our
                                                aerothermal range on air-water
                                                and monobloc style heat pumps.
                                          </p>
                                    </Col>

                                    {/* <HistoryItemBox
                                          title="Privated Houses Served"
                                          image="/images/svg/house.svg"
                                          count={184}
                                    />
                                    <HistoryItemBox
                                          title="Air Conditioners Installed"
                                          image="/images/svg/heater.svg"
                                          count={365}
                                    />
                                    <HistoryItemBox
                                          title="Heating Systems Mounted"
                                          image="/images/svg/heating.svg"
                                          count={250}
                                    />
                                    <HistoryItemBox
                                          title="Ventilation Systems "
                                          image="/images/svg/ventilation.svg"
                                          count={49}
                                    /> */}
                              </Row>
                        </Container>
                  </HistorySection>
            </>
      );
};

export default HistoryBox;
