import React from 'react';
import {
   SolarBenefitContent,
   SolarBenefitImage,
   SolarBenefitItem,
   SolarBenefitItemImage,
   SolarBenefitItems,
   SolarBenefitKeyFeature,
   SolarBenefitTitle,
   SolarBenefitsContainer
} from '../SolarBenefits/styles';
import { Col, Container, Row } from 'react-bootstrap';
import SolarBenefits from '../SolarBenefits';
import { Solarthermowrapper } from './styles';

const thermos = [
   {
      id: 1,
      title: 'Thermosyphon closed loop solar systems “Solar Flame MAX” with the following benefits:',
      benefits: [
         {
            id: 1,
            title: 'Large range of versions (49 systems), certified to SOLAR KEYMARK.'
         },
         {
            id: 2,
            title: 'Compact system setup, for easy transport and installation.'
         },
         {
            id: 3,
            title: 'Customized design, for exclusive OEM versions.'
         }
      ]
   }
];

const SolarThermoBenefits = () => {
   return (
      <>
         <SolarBenefitsContainer>
            <Container>
               <Row>
                  <Col lg={4}>
                     <SolarBenefitImage>
                        <img
                           src="images/img/solar/thermoimage.png"
                           className="img-fluid"
                           alt="innmotek solar"
                        />
                     </SolarBenefitImage>
                  </Col>
                  <Col>
                     <SolarBenefitContent>
                        <SolarBenefitTitle>Benefits of</SolarBenefitTitle>
                        {thermos.map((item, index) => (
                           <Solarthermowrapper>
                              <SolarBenefitKeyFeature>
                                 {item.title}
                              </SolarBenefitKeyFeature>
                              <SolarBenefitItems className="ps-0">
                                 {item.benefits.map((item, index) => (
                                    <SolarBenefitItem
                                       className="d-flex align-items-center"
                                       key={index}
                                    >
                                       <i class="bx bxs-check-square me-2"></i>
                                       {item.title}
                                    </SolarBenefitItem>
                                 ))}
                              </SolarBenefitItems>
                           </Solarthermowrapper>
                        ))}
                     </SolarBenefitContent>
                  </Col>
               </Row>
            </Container>
         </SolarBenefitsContainer>
      </>
   );
};

export default SolarThermoBenefits;
