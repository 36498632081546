import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import { ImageClip } from '../RoboticPoolCleaner/styles';

export const PageNotFoundContainer = styled(Container)``;
export const PageNotFoundContent = styled.div`
      padding: 100px 0px;
      h1 {
            ${ImageClip} {
                  font-size: ${(props) =>
                        props.fontSize ? props.fontSize : '10vw'};
            }
      }
      P {
            color: #555;
      }
`;

export const PageNotFoundButton = styled.div`
      margin-top: 30px;
      a {
            padding: 13px 30px;
            padding-right: 40px;
            background: ${(props) => props.theme.secondary};
            color: ${(props) => props.theme.white};
            position: relative;
            overflow: hidden;
            &::before {
                  content: '';
                  position: absolute;
                  right: -11px;
                  top: 0;
                  width: 21px;
                  height: 99%;
                  background: ${(props) => props.theme.primary};
                  transform: skewX(-10deg);
                  transition: all 0.5s ease;
            }

            &:hover {
                  cursor: pointer;

                  &::after {
                        opacity: 1;
                        right: 0;
                        width: 100%;
                        transform: skewX(0deg);
                        height: 100%;
                  }
            }

            span {
                  position: relative;
                  z-index: 1000;
            }
      }
`;
