import { Container } from 'react-bootstrap';
import { styled } from 'styled-components';
import { Swiper } from 'swiper/react';

// Banner
export const BannerSection = styled.section`
   overflow: hidden;
`;

export const BannerSlider = styled(Swiper)`
   width: 100%;
   height: 100%;

   .swiper-slide {
      background-position: center;
      background-size: cover;

      img {
         display: block;
         width: 100%;
      }
   }

   .swiper-pagination {
      right: -605px;
      top: 52% !important;
      transform: rotate(90deg) !important;
      left: 560px !important;
   }

   .swiper-pagination-bullet {
      clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
      width: 15px;
      height: 15px;
      border-radius: 0;
      background: ${(props) => props.theme.primary};
   }
`;

export const BannerShape = styled.div`
   margin-top: -80px;
   position: relative;
   z-index: 1000;

   @media only screen and (max-width: 568px) {
      margin-top: 0;
      display: none;
   }
`;

// About
export const AboutSection = styled.section`
   padding: 70px 0px 80px;
`;

export const AboutImage = styled.div`
   position: relative;

   &::after {
      position: absolute;
      top: 40px;
      left: 0px;
      content: '';
      height: 300px;
      width: 300px;
      border: 7px solid #e7e7e7;
      z-index: -1;
      border-radius: 10px;
   }

   img {
      width: 90%;
      float: right;
   }

   @media only screen and (max-width: 992px) {
      &::after {
         height: 240px;
         width: 240px;
      }
   }
`;

export const AboutContent = styled.div`
   height: 100%;
   display: flex;
   justify-content: center;
   flex-direction: column;

   p {
      color: #666;
   }
`;

export const AboutBenefit = styled.div``;

export const AboutBenefitItem = styled.div`
   display: flex;
   margin-bottom: ${(props) => props.marginBottom && '1rem'};
   gap: 1.5rem;
`;

export const AboutBenefitIcon = styled.div`
   img {
      width: 50px;
      height: 50px;
   }
`;

export const AboutBenefitContent = styled.div`
   h4 {
      font-size: 20px;
   }
`;

export const AboutButton = styled.div`
   margin-top: 30px;

   a {
      padding: 13px 30px;
      padding-right: 40px;
      background: ${(props) => props.theme.primary};
      color: ${(props) => props.theme.white};
      position: relative;
      z-index: 1;
      span {
         position: relative;
         z-index: 100;
      }
      &::after {
         content: '';
         position: absolute;
         right: -11px;
         top: 0;
         width: 21px;
         height: 100%;
         background: ${(props) => props.theme.secondary};
         transform: skewX(-10deg);
         transition: all 0.5s ease;
      }

      &:hover {
         cursor: pointer;

         &::after {
            opacity: 1;
            right: 0;
            width: 100%;
            transform: skewX(0deg);
            height: 100%;
         }
      }

      span {
         position: relative;
         z-index: 100000;
      }
   }
`;

export const AboutBody = styled.div``;

// History
export const HistorySection = styled.section`
   ${'' /* background-image: url(images/img/about/history.jpg); */}
   background: #171b2a;
   background-size: cover;
   background-repeat: no-repeat;
   padding: 50px 0px;
   background-position: center center;
   background-attachment: fixed;
   h3 {
      color: ${(props) => props.theme.white};
      text-align: center;
      margin-bottom: 10px;
      span {
         color: ${(props) => props.theme.secondary};
      }
   }
   p {
      line-height: 1.6;
      text-align: justify;
      font-size: 16px;
      font-weight: 300;
      color: ${(props) => props.theme.white};
   }
`;

export const HistoryContainer = styled.div`
   height: 100%;
   display: flex;
   justify-content: center;
   flex-direction: column;

   span {
      font-size: 40px;
      color: ${(props) => props.theme.white};
   }
`;

export const HistoryIcon = styled.div`
   height: 100%;
   display: flex;
   align-items: center;
   gap: 1rem;

   img {
      width: 50px;
      height: 50px;
   }
`;

export const HistoryContent = styled.div`
   margin-top: 1rem;

   h4 {
      color: ${(props) => props.theme.white};
      font-family: 'Rajdhani', sans-serif;
      font-size: 20px;
   }
`;

// Featured
export const FeaturedSection = styled.section`
   background-image: url(images/img/sitebanner/sitebanner.png);
   background-position: center center;
   padding: 20px 0px 20px;

   @media only screen and (max-width: 568px) {
      background-position: unset;
   }
`;

export const FeaturedContainer = styled.section`
   display: flex;
   align-items: center;
   justify-content: space-between;

   @media only screen and (max-width: 568px) {
      flex-direction: column;
   }
`;

export const FeaturedTitle = styled.div`
   text-align: center;

   p {
      font-size: 24px;
      font-family: 'Rajdhani', sans-serif;
      color: ${(props) => props.color && props.color};
   }

   @media only screen and (max-width: 992px) {
      p {
         font-size: 18px;
      }
   }

   @media only screen and (max-width: 568px) {
      p {
         color: ${(props) => props.theme.primary};
      }
   }
`;

export const FeaturedImage = styled.div`
   img {
      width: 350px;
      height: 250px;
      object-fit: contain;
   }

   @media only screen and (max-width: 992px) {
      img {
         width: 250px;
         height: 180px;
      }
   }
`;

export const FeaturedIcon = styled.div`
   margin-bottom: 1.5rem;

   img {
      width: 50px;
      height: 50px;
   }

   @media only screen and (max-width: 992px) {
      img {
         width: 30px;
         height: 30px;
      }
   }
`;

export const FeaturedContent = styled.div`
   display: flex;
   align-items: center;
   flex-direction: column;
   justify-content: ${(props) => props.jusitfyContent && 'space-between'};
`;

// Product
export const ProductSection = styled.section`
   padding: 90px 0px;
   position: relative;

   &:after {
      content: url('images/img/products/background.png');
      position: absolute;
      right: 0;
      top: 0;
      opacity: 0.2;
   }
`;

export const ProductContent = styled.div`
   margin-top: 3rem;
`;

export const ProductItemImage = styled.div`
   overflow: hidden;
   transition: transform 0.3s ease-in-out;
   border-radius: 0.375rem;

   a {
      display: block;

      img {
         height: 270px;
         width: 100%;
         border-radius: 0.375rem;
         object-fit: contain;
      }
   }
`;

export const ProductItem = styled.div`
   ${
      '' /* box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
            rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
   }

   &:hover > ${ProductItemImage} {
      img {
         transform: scale(1.1);
         transition: transform 0.3s ease-in-out;
      }
   }
`;

export const ProductItemDetail = styled.div`
   display: flex;
   align-items: center;
   flex-direction: column;
   margin-top: 1.5rem;
`;

export const ProductItemTitle = styled.div`
   margin-top: 1rem;
   height: 60px;
   overflow: hidden;
   width: 100%;

   h4 {
      font-size: 21px;
      font-weight: 400;
   }
`;

export const ProductItemButton = styled.div`
   margin-top: 1.5rem;

   a {
      padding: 13px 30px;
      padding-right: 40px;
      background: ${(props) =>
         props.customBackground ? props.customBackground : props.theme.primary};
      color: white;
      position: relative;
      transition: all 0.1s linear;

      &:after {
         content: '';
         width: 100%;
         height: 0;
         background-color: ${(props) => props.theme.secondary};
         position: absolute;
         bottom: 0px;
         left: 0px;
         transition: all 0.1s linear;
      }

      span {
         position: relative;
         z-index: 1000;
      }

      &:hover {
         &:after {
            height: 100%;
            transition: all 0.1s linear;
         }
      }
   }
`;

// Testimonial
export const TestimonialSection = styled.section`
   padding: 90px 0px;
   background-color: #f2f1f8;

   @media only screen and (max-width: 1200px) {
      padding: 30px 0;
      padding-top: 60px;
   }
`;

export const TestimonialSlider = styled(Swiper)`
   /* height: 100%; */
   width: 100%;
   position: relative;

   .swiper-button-next,
   .swiper-button-prev {
      background-color: #ffffff00;
      color: #22242980;
      border-style: solid;
      border-width: 2px 2px 2px 2px;
      border-color: #22242980;
      border-radius: 50%;
      height: 40px;
      width: 40px;

      &::after {
         font-size: 14px;
         color: #1d1d1b;
      }
   }

   .swiper-button-next {
      left: 80px;
   }

   .swiper-button-next,
   .swiper-button-prev {
      top: auto;
      bottom: 0;
   }
`;

export const TestimonialThumb = styled(Swiper)`
   height: 100%;
   box-sizing: border-box;
   padding: 10px 0;

   .swiper-wrapper {
      padding: 30px;
   }

   .swiper-slide {
      width: 50%;
      height: 100%;
      opacity: 0.4;

      img {
         display: block;
         width: 100%;
         height: 100%;
         object-fit: cover;
      }
   }

   .swiper-slide-thumb-active {
      opacity: 1;
   }
`;

export const TestimonialHeading = styled.div`
   margin-bottom: ${(props) => (props.margin ? props.margin : '2rem')};

   h2 {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 300;

      span {
         display: inline-block;
         width: 50px;
         height: 2px;
         background-color: ${(props) => props.theme.secondary};
      }
   }

   h3 {
      font-size: 32px;
   }
`;

export const TestimonialImage = styled.div`
   position: relative;

   img {
      border-radius: 10px;
   }

   &::after {
      position: absolute;
      top: -20px;
      bottom: -20px;
      left: -30px;
      right: 100px;
      border: 2px solid #afaead;
      opacity: 0.4;
      border-radius: 10px;
      content: '';
      transform: skew(10deg);
      transform-origin: left top;
      transition: all 300ms ease;
      z-index: -1;
   }
`;

export const TestimonialContent = styled.div`
   padding-bottom: 60px;
   h4 {
      font-size: 18px;
   }
`;

export const TestimonialTitle = styled.div`
   margin-bottom: 1.5rem;

   p {
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      color: #666;
      font-family: 'Rajdhani', sans-serif;
   }
`;

// Blog Box
export const BlogSection = styled.div`
   background: #f2f1f8;
   padding: 70px 0px;
`;

export const BlogSlider = styled(Swiper)`
   padding: 20px !important;
   margin: -20px !important;

   @media only screen and (max-width: 568px) {
      padding: 0 !important;
      margin: 0 !important;
   }
`;

// Brand Box
export const BrandSection = styled.section`
   margin-bottom: 3rem;
`;

export const BrandImage = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;

   img {
      width: 100px;
      aspect-ratio: 3/2;
      object-fit: contain;
      opacity: 0.75;
      transition: opacity 0.2s linear;

      &:hover {
         opacity: 1;
         transition: opacity 0.2s linear;
         cursor: pointer;
      }
   }
`;

// Subscriber Box
export const Subscription = styled.div`
   position: relative;
   margin-top: 3rem;
   background: #f2f1f8;
`;

export const SubscriptionContainer = styled(Container)`
   display: flex;
`;

export const SubscriptionContent = styled.div`
   background-color: #f7f2ed;
   width: 75%;
   display: flex;

   @media only screen and (max-width: 568px) {
      width: 100%;
   }
`;

export const SubscriptionImage = styled.div`
   width: 25%;

   @media only screen and (max-width: 568px) {
      display: none;
   }
`;

export const PaperPlane = styled.div`
   margin-top: 30px;
   background-color: ${(props) => props.theme.white};
   border-radius: 50%;
   padding: 15px;
   height: 70px;
   width: 70px;
   position: relative;

   img {
      width: 40px;
      height: 40px;
   }

   &::after {
      content: '';
      background-color: ${(props) => props.theme.white};
      width: 100px;
      height: 100px;
      top: 50%;
      left: 50%;
      position: absolute;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-clip: border-box;
      z-index: 1;
      zoom: 1;
      filter: alpha(opacity=25);
      -webkit-opacity: 0.25;
      -moz-opacity: 0.25;
      opacity: 0.25;
      -webkit-transform: translate(-50%, -50%) scale(0.8);
      -moz-transform: translate(-50%, -50%) scale(0.8);
      -ms-transform: translate(-50%, -50%) scale(0.8);
      -o-transform: translate(-50%, -50%) scale(0.8);
      transform: translate(-50%, -50%) scale(0.8);
      -webkit-animation: lte-glowing 1250ms infinite alternate;
      -moz-animation: lte-glowing 1250ms infinite alternate;
      -o-animation: lte-glowing 1250ms infinite alternate;
      animation: lte-glowing 1250ms infinite alternate;
   }

   @keyframes lte-glowing {
      0% {
         -webkit-transform: translate(-50%, -50%) scale(0.95);
         -moz-transform: translate(-50%, -50%) scale(0.95);
         -ms-transform: translate(-50%, -50%) scale(0.95);
         -o-transform: translate(-50%, -50%) scale(0.95);
         transform: translate(-50%, -50%) scale(0.95);
      }
      100% {
         -webkit-transform: translate(-50%, -50%) scale(1.1);
         -moz-transform: translate(-50%, -50%) scale(1.1);
         -ms-transform: translate(-50%, -50%) scale(1.1);
         -o-transform: translate(-50%, -50%) scale(1.1);
         transform: translate(-50%, -50%) scale(1.1);
      }
   }
`;

export const SubscriptionForm = styled.div`
   display: flex;
   align-items: center;
   height: 100%;
   width: 100%;

   form {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 0px 120px;

      .form-control:focus {
         color: ${(props) => props.theme.body} !important;
         background-color: transparent !important;
         border-color: ${(props) => props.theme.primary} !important;
         box-shadow: none !important;
      }

      input {
         border: none;
         background-color: transparent;
         padding: 14px 20px;
         border-bottom: 1px solid ${(props) => props.theme.primary};
      }

      button {
         background-color: ${(props) => props.theme.primary};
         padding: 14px 44px;
         width: 200px;
         position: relative;

         span {
            position: relative;
            z-index: 9999;
         }

         &:after {
            content: '';
            width: 100%;
            height: 0;
            background-color: ${(props) => props.theme.secondary};
            position: absolute;
            bottom: 0px;
            left: 0px;
            transition: all 0.1s linear;
         }

         &:hover:after {
            height: 100%;
            transition: all 0.1s linear;
         }
      }
   }

   @media only screen and (max-width: 568px) {
      form {
         padding: 0 20px;
      }
   }
`;
