import React, { useEffect, useState } from 'react';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { Col, Container, Row } from 'react-bootstrap';
import {
      ProductTitle,
      ProjectContent,
      ProjectIcon,
      ProjectImage
} from './styles';
import { CategoryBackground } from '../category/styles';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ProjectSkeleton from '../../components/skeletons/ProjectSkeleton';
import PageNotFound from '../page-not-found';

const Project = () => {
      const [loading, setLoading] = useState(false);
      const [projects, setProjects] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/projects`)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProjects(response.data.projects);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  {projects?.length > 0 ? (
                        <BreadcrumbBox title="Project" />
                  ) : (
                        ''
                  )}
                  <CategoryBackground>
                        <Container>
                              <Row>
                                    {!loading ? (
                                          projects?.length > 0 ? (
                                                projects?.map(
                                                      (project, index) => (
                                                            <Col lg={4}>
                                                                  <ProjectImage>
                                                                        <Link
                                                                              to={`/projects/${project.slug}`}
                                                                              className="d-inline-block"
                                                                        >
                                                                              <img
                                                                                    src={
                                                                                          project.image
                                                                                    }
                                                                                    alt={
                                                                                          project.title
                                                                                    }
                                                                              />
                                                                        </Link>
                                                                  </ProjectImage>
                                                                  <ProjectContent>
                                                                        <ProductTitle>
                                                                              <h2>
                                                                                    <Link
                                                                                          to={`/projects/${project.slug}`}
                                                                                    >
                                                                                          {
                                                                                                project.title
                                                                                          }
                                                                                    </Link>
                                                                              </h2>
                                                                        </ProductTitle>
                                                                        <ProjectIcon>
                                                                              <Link
                                                                                    to={`/projects/${project.slug}`}
                                                                              >
                                                                                    <i className="bx bx-chevron-right"></i>
                                                                              </Link>
                                                                        </ProjectIcon>
                                                                  </ProjectContent>
                                                            </Col>
                                                      )
                                                )
                                          ) : (
                                                <PageNotFound
                                                      fontSize="5 vw"
                                                      title="Coming Soon"
                                                      subTitle="Our Projects are coming soon."
                                                      desc="We are working on it."
                                                />
                                          )
                                    ) : (
                                          <>
                                                <Col lg={4}>
                                                      <ProjectSkeleton />
                                                </Col>
                                                <Col lg={4}>
                                                      <ProjectSkeleton />
                                                </Col>
                                                <Col lg={4}>
                                                      <ProjectSkeleton />
                                                </Col>
                                          </>
                                    )}
                              </Row>
                        </Container>
                  </CategoryBackground>
            </>
      );
};

export default Project;
