import React from 'react';
import {
      AboutBenefitContent,
      AboutBenefitIcon,
      AboutBenefitItem
} from '../../../../styles/frontend/home';

const AboutBenefitItemBox = ({ image, title, description, style }) => {
      return (
            <>
                  <AboutBenefitItem marginBottom={style}>
                        <AboutBenefitIcon>
                              <img
                                    src={image}
                                    alt={title}
                                    width="100%"
                                    height="100%"
                              />
                        </AboutBenefitIcon>
                        <AboutBenefitContent>
                              <h4>{title}</h4>
                              <div
                                    dangerouslySetInnerHTML={{
                                          __html: description
                                    }}
                              ></div>
                        </AboutBenefitContent>
                  </AboutBenefitItem>
            </>
      );
};

export default AboutBenefitItemBox;
