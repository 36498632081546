import React, { useEffect, useState } from 'react';
import {
      CategoryBackground,
      CategoryContent,
      CategoryItem,
      CategoryItemImage
} from '../category/styles';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import ParentSkeleton from '../../components/skeletons/ParentSkeleton';
import BreadcrumbBox from '../../components/common/BreadcrumbBox';
import { Helmet } from 'react-helmet';

const ChildCategory = () => {
      const { childCategory } = useParams();

      const [loading, setLoading] = useState(false);
      const [category, setCategory] = useState([]);
      const [categories, setCategories] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/category/${childCategory}`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCategory(response.data.category);
                              setCategories(response.data.categories);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, [useLocation().pathname]);

      return (
            <>
                  {!loading ? (
                        <Helmet>
                              <title>
                                    {category.seo_title
                                          ? category.seo_title
                                          : category.title}
                              </title>
                              {category.seo_description && (
                                    <meta
                                          name="description"
                                          content={category.seo_description}
                                    />
                              )}
                              {category.seo_keyword && (
                                    <meta
                                          name="keyword"
                                          content={category.seo_keyword}
                                    />
                              )}
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                        </Helmet>
                  ) : null}
                  <BreadcrumbBox
                        linkTitle={category.parentTitle}
                        link={`/category/${category.parentLink}`}
                        title={category.title}
                  />
                  <CategoryBackground>
                        <Container>
                              <Row className="g-4">
                                    {!loading ? (
                                          categories?.length > 0 &&
                                          categories?.map((item, index) => (
                                                <Col lg={4} key={index}>
                                                      <CategoryItem>
                                                            <CategoryItemImage>
                                                                  {item.image && (
                                                                        <img
                                                                              src={
                                                                                    item.image
                                                                              }
                                                                              alt={
                                                                                    item.title
                                                                              }
                                                                              width="100%"
                                                                              height="100%"
                                                                        />
                                                                  )}
                                                            </CategoryItemImage>
                                                            <CategoryContent>
                                                                  <h2>
                                                                        {
                                                                              item.title
                                                                        }
                                                                  </h2>
                                                                  <div
                                                                        dangerouslySetInnerHTML={{
                                                                              __html: item.description
                                                                        }}
                                                                  ></div>
                                                                  <div className="mt-auto">
                                                                        {item.slug ===
                                                                        'heat-pump-air-dryer' ? (
                                                                              <Link
                                                                                    className="w-100 text-center"
                                                                                    to="/heat-pump-air-dryer"
                                                                              >
                                                                                    Show
                                                                                    More
                                                                              </Link>
                                                                        ) : item.slug ===
                                                                          'commericial-split-hot-water-heat-pump' ? (
                                                                              <Link
                                                                                    className="w-100 text-center"
                                                                                    to="/products/split-hot-water-heat-pumps/category"
                                                                              >
                                                                                    Find
                                                                                    Out
                                                                                    More
                                                                              </Link>
                                                                        ) : (
                                                                              <Link
                                                                                    className="w-100 text-center"
                                                                                    to={`${
                                                                                          item.categories ===
                                                                                          0
                                                                                                ? '/products/'
                                                                                                : '/category/'
                                                                                    }${
                                                                                          item.slug
                                                                                    }${
                                                                                          item.categories ===
                                                                                          0
                                                                                                ? '/category'
                                                                                                : ''
                                                                                    }`}
                                                                              >
                                                                                    Show
                                                                                    More
                                                                              </Link>
                                                                        )}
                                                                  </div>
                                                            </CategoryContent>
                                                      </CategoryItem>
                                                </Col>
                                          ))
                                    ) : (
                                          <>
                                                <Col lg={4}>
                                                      <ParentSkeleton />
                                                </Col>
                                                <Col lg={4}>
                                                      <ParentSkeleton />
                                                </Col>
                                                <Col lg={4}>
                                                      <ParentSkeleton />
                                                </Col>
                                          </>
                                    )}
                              </Row>
                        </Container>
                  </CategoryBackground>
            </>
      );
};

export default ChildCategory;
