import React from 'react';
import {
   SolarIntroductionContainer,
   SolarIntroductionContent,
   SolarIntroductionDescription,
   SolarIntroductionHeading
} from './styles';

const SolarIntroduction = () => {
   return (
      <>
         <SolarIntroductionContainer>
            {/* <img src="/images/background.png" className="w-100" /> */}
            <SolarIntroductionContent>
               <SolarIntroductionHeading>
                  Solar Collectors
               </SolarIntroductionHeading>
               <SolarIntroductionDescription>
                  Innmotek offers cutting-edge solar collectors featuring a high
                  selective PVD absorber coating for fast and high-temperature
                  production, a copper water frame ensuring maximum heat
                  transfer, tempered hail-resistant solar glass, optimized laser
                  welding technology for enhanced efficiency, and a high
                  incident angle modifier for maximum radiation absorption
                  throughout the day. Crafted from high-quality, recyclable
                  materials, our collectors promise a long service life and high
                  environmental compatibility. Experience the future of solar
                  energy with Innmotek and embrace a greener, more sustainable
                  tomorrow.
               </SolarIntroductionDescription>
            </SolarIntroductionContent>
         </SolarIntroductionContainer>
      </>
   );
};

export default SolarIntroduction;
