import styled from 'styled-components';

export const ButtonBox = styled.button`
      padding: 13px 30px;
      padding-right: 40px;
      background: ${(props) => props.theme.primary};
      color: white;
      position: relative;
      transition: all 0.1s linear;
      border: 0;

      span {
            position: relative;
            z-index: 1000;
      }

      &:after {
            content: '';
            width: 100%;
            height: 0;
            background-color: ${(props) => props.theme.secondary};
            position: absolute;
            bottom: 0px;
            left: 0px;
            transition: all 0.1s linear;
      }

      &:hover {
            &:after {
                  height: 100%;
                  transition: all 0.1s linear;
            }
      }
`;
