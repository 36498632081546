import styled from 'styled-components';
import { Swiper } from 'swiper/react';

export const ProductSlider = styled(Swiper)`
      padding: 1rem;
      margin: -1rem;

      @media only screen and (max-width: 568px) {
            padding: 0;
            margin: 0;
      }
`;
