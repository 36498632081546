import { Col } from 'react-bootstrap';
import styled from 'styled-components';

export const SolarOverviewContainer = styled.div`
   padding: 60px 0px;
`;
export const SolarOverviewImage = styled.div`
   width: 43%;
   overflow: hidden;
   transition: all 0.5s ease-in-out 0s;
   float: left;
   margin: 0 15px 0 0;
   display: block;
`;
export const SolarOverviewContent = styled.div`
   width: 100%;
   float: left;
   ul {
      display: block;
      list-style-type: none;
      li {
      }
   }
`;
export const SolarOverviewTitle = styled.h4`
   color: ${(props) => props.theme.blue};
   ${'' /* margin-bottom: 15px; */}
`;
export const SolarButtonContainer = styled.div`
`;
