import React from 'react';
import {
   PaperPlane,
   Subscription,
   SubscriptionContainer,
   SubscriptionContent,
   SubscriptionForm,
   SubscriptionImage
} from '../../../styles/frontend/home';
import { FormControl } from 'react-bootstrap';

const SubscriberBox = () => {
   return (
      <>
         <Subscription className="m-0">
            <SubscriptionContainer>
               <SubscriptionImage>
                  <img
                     src="/images/img/subscription/subscribe.jpg"
                     alt="Innmotek Subscription Image"
                     class="img-fluid h-100"
                     width="100%"
                     height="100%"
                  />
               </SubscriptionImage>
               <SubscriptionContent>
                  <PaperPlane>
                     <img
                        src="/images/svg/paperplane.svg"
                        alt="Innmotek Subscription"
                        width="100%"
                        height="100%"
                     />
                  </PaperPlane>
                  <SubscriptionForm>
                     <form>
                        <FormControl
                           type="text"
                           className="rounded-0"
                           placeholder="Your Email"
                        />
                        <button
                           type="submit"
                           class="btn text-white rounded-0 button2"
                        >
                           <span>Submit</span>
                        </button>
                     </form>
                  </SubscriptionForm>
               </SubscriptionContent>
            </SubscriptionContainer>
         </Subscription>
      </>
   );
};

export default SubscriberBox;
