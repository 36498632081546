import React from 'react';

const CategoryIcon = () => {
      return (
            <>
                  <i className="bx bxs-purchase-tag"></i>
            </>
      );
};

export default CategoryIcon;
