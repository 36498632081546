import React, { useContext } from 'react';
import SubscriberBox from './components/SubscriberBox';
import BrandBox from './components/BrandBox';
import BlogBox from './components/BlogBox';
import TestimonialBox from './components/TestimonialBox';
import BannerBox from './components/BannerBox';
import AboutBox from './components/AboutBox';
import HistoryBox from './components/HistoryBox';
import FeaturedBox from './components/FeaturedBox';
import ProductBox from './components/ProductBox';
import CategoryBox from './components/CategoryBox';
import MetaContext from '../../stores/MetaContext';

const Home = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('home');

      return (
            <>
                  <h1 className="d-none">
                        Innmotek | High-Efficiency Air Source & Swimming Pool
                        Heat Pumps
                  </h1>
                  <BannerBox />
                  <AboutBox />
                  <HistoryBox />
                  {/* <InformationBox />  */}
                  <CategoryBox />
                  <FeaturedBox />
                  <ProductBox />
                  {/* <TestimonialBox /> */}
                  <BlogBox />
                  {/* <BrandBox /> */}
                  <SubscriberBox />
            </>
      );
};

export default Home;
