import styled from 'styled-components';

export const ProjectImage = styled.div`
      img {
            height: 100%;
            width: 100%;
      }
`;

export const ProjectContent = styled.div`
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
      align-items: center;
      background-color: ${(props) => props.theme.white};
`;

export const ProductTitle = styled.div`
      h2 {
            font-size: 24px;
            font-weight: 300;

            a {
                  color: #1d1d1d;
            }
      }
`;

export const ProjectIcon = styled.div`
      a {
            color: #1d1d1d;

            i {
                  font-size: 24px;
            }
      }
`;
