import React from 'react';

const IconCheckmark = () => {
      return (
            <>
                  <svg
                        clip-rule="evenodd"
                        fill-rule="evenodd"
                        height="512"
                        stroke-linejoin="round"
                        stroke-miterlimit="2"
                        viewBox="0 0 24 24"
                        width="512"
                        xmlns="http://www.w3.org/2000/svg"
                        id="fi_7276364"
                  >
                        <g id="Icon">
                              <path d="m7.154 8.645 4.667 5.111c.138.151.332.239.537.244.205.004.402-.075.547-.22l9.25-9.25c.293-.292.293-.768 0-1.06-.292-.293-.768-.293-1.06 0l-8.695 8.695s-4.138-4.532-4.138-4.532c-.279-.305-.754-.327-1.059-.048-.306.279-.328.754-.049 1.06z"></path>
                              <path d="m12.375 2h-7c-2.071 0-3.75 1.679-3.75 3.75v12.5c0 2.071 1.679 3.75 3.75 3.75h12.5c2.071 0 3.75-1.679 3.75-3.75 0-2.946 0-7 0-7 0-.414-.336-.75-.75-.75s-.75.336-.75.75v7c0 1.243-1.007 2.25-2.25 2.25h-12.5c-1.243 0-2.25-1.007-2.25-2.25v-12.5c0-1.243 1.007-2.25 2.25-2.25h7c.414 0 .75-.336.75-.75s-.336-.75-.75-.75z"></path>
                        </g>
                  </svg>
            </>
      );
};

export default IconCheckmark;
