import styled from 'styled-components';

export const RecommendedItem = styled.div`
      background-color: ${(props) => props.theme.white};
      border-radius: 10px;

      a {
            display: block;
            transition: all 0.3s ease-in;
            color: ${(props) => props.theme.primary};

            &:hover {
                  color: ${(props) => props.theme.secondary};
            }
      }

      img {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            padding: 10px;
            object-fit: contain;
      }
`;
