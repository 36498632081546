import React from 'react';
import { SolarCollectorContainer } from './styles';
import SolarIntroduction from '../../components/common/SolarCommonLayout/SolarIntroduction';
import SolarBenefits from '../../components/common/SolarCommonLayout/SolarBenefits';
import SolarOverview from '../../components/common/SolarCommonLayout/SolarOverview';
import SolarBanner from '../../components/common/SolarCommonLayout/SolarBanner';
import SolarCollectorTable from '../../components/common/SolarCommonLayout/SolarTableLayout/SolarCollectorTable';
import SolarCertification from '../../components/common/SolarCommonLayout/SolarCertification';
import SolarProject from '../../components/common/SolarCommonLayout/SolarProject';

const SolarCollector = () => {
   return (
      <>
         <SolarCollectorContainer>
            <SolarIntroduction />
            <SolarBenefits image={'images/img/solar/solarimage.png'} />
            <SolarBanner />
            <SolarOverview />
            <SolarProject />
         </SolarCollectorContainer>
      </>
   );
};

export default SolarCollector;
