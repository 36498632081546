import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { BreadcrumbContainer } from './styles';
import { Link } from 'react-router-dom';

const BreadcrumbBox = ({ title, link, linkTitle, children }) => {
   return (
      <>
         <BreadcrumbContainer
            style={{
               backgroundImage:
                  'url("https://themexriver.com/wp/spero/wp-content/themes/spero/assets/images/bg/14.jpg")'
            }}
         >
            <Container>
               <Row>
                  <div className="col-lg-12">
                     <div className="ltn__breadcrumb-inner">
                        <h1 className="page-title">{title}</h1>
                        <div className="ltn__breadcrumb-list">
                           <ol className="breadcrumb">
                              <li className="breadcrumb-item">
                                 <i className="bx bx-home"></i>{' '}
                                 <Link to="/">Home</Link>
                              </li>
                              {children && children}
                              {linkTitle && (
                                 <li className="breadcrumb-item">
                                    <Link to={link}>{linkTitle}</Link>
                                 </li>
                              )}
                              <li
                                 className="breadcrumb-item active"
                                 aria-current="page"
                              >
                                 {title}
                              </li>
                           </ol>
                        </div>
                     </div>
                  </div>
               </Row>
            </Container>
         </BreadcrumbContainer>
      </>
   );
};

export default BreadcrumbBox;
